import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  JSON: any;
  RansackValue: any;
};

export type Activity = {
  __typename?: 'Activity';
  /** Action */
  action: Maybe<Scalars['String']>;
  /** complete */
  complete: Maybe<Scalars['Boolean']>;
  /** context_attributes */
  contextAttributes: Maybe<Scalars['String']>;
  /** Context ID */
  contextId: Maybe<Scalars['Int']>;
  /** Context Type */
  contextType: Maybe<Scalars['String']>;
  /** controller */
  controller: Maybe<Scalars['String']>;
  /** Created At */
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  /** ID */
  id: Scalars['Int'];
  objectPermissions: ObjectPermission;
  /** organisation */
  organisation: Maybe<Organisation>;
  /** organisation_id */
  organisationId: Maybe<Scalars['Int']>;
  /** outer_context_id */
  outerContextId: Maybe<Scalars['Int']>;
  /** outer_context_type */
  outerContextType: Maybe<Scalars['String']>;
  /** state */
  state: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** User */
  user: Maybe<User>;
  /** User ID */
  userId: Maybe<Scalars['Int']>;
};


export type ActivityOrganisationArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type ActivityUserArgs = {
  id: InputMaybe<Scalars['Int']>;
};

/** The connection type for Activity. */
export type ActivityConnection = {
  __typename?: 'ActivityConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ActivityEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Activity>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ActivityEdge = {
  __typename?: 'ActivityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Activity>;
};

export type ApiKey = {
  __typename?: 'ApiKey';
  /** activities */
  activities: Maybe<ActivityConnection>;
  /** Created At */
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  /** expires */
  expires: Maybe<Scalars['ISO8601DateTime']>;
  /** ID */
  id: Scalars['Int'];
  /** is_punchout */
  isPunchout: Maybe<Scalars['Boolean']>;
  objectPermissions: ObjectPermission;
  /** token */
  token: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** User */
  user: Maybe<User>;
  /** User ID */
  userId: Maybe<Scalars['Int']>;
};


export type ApiKeyActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type ApiKeyUserArgs = {
  id: InputMaybe<Scalars['Int']>;
};

/** The connection type for ApiKey. */
export type ApiKeyConnection = {
  __typename?: 'ApiKeyConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ApiKeyEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ApiKey>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ApiKeyEdge = {
  __typename?: 'ApiKeyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<ApiKey>;
};

/** A user-readable error */
export type BaseError = {
  __typename?: 'BaseError';
  /** Any additional custom information */
  extensions: Maybe<BaseErrorExtensions>;
  /** A description of the error */
  message: Array<Scalars['String']>;
  /** Which input value this error came from */
  path: Maybe<Array<Scalars['String']>>;
};

/** Any additional information to provide with errors */
export type BaseErrorExtensions = {
  __typename?: 'BaseErrorExtensions';
  /** HTTP status code */
  code: Maybe<Scalars['Int']>;
  /** HTTP status message */
  status: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ChangeOrganisationMutation */
export type ChangeOrganisationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of ChangeOrganisationMutation */
export type ChangeOrganisationMutationPayload = {
  __typename?: 'ChangeOrganisationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  currentOrganisation: Maybe<Organisation>;
  errors: Maybe<Array<BaseError>>;
};

/** Autogenerated input type of ClearCacheMutation */
export type ClearCacheMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ClearCacheMutation */
export type ClearCacheMutationPayload = {
  __typename?: 'ClearCacheMutationPayload';
  clearAccessCache: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
};

/** Autogenerated input type of ConfirmUserMutation */
export type ConfirmUserMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  confirmationToken: Scalars['String'];
};

/** Autogenerated return type of ConfirmUserMutation */
export type ConfirmUserMutationPayload = {
  __typename?: 'ConfirmUserMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  fullName: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};

/** Autogenerated input type of CreateGroupMutation */
export type CreateGroupMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Name */
  name?: InputMaybe<Scalars['String']>;
  /** Parent ID */
  parentId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateGroupMutation */
export type CreateGroupMutationPayload = {
  __typename?: 'CreateGroupMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  group: Maybe<Group>;
};

/** Autogenerated input type of CreatePermissionMutation */
export type CreatePermissionMutationInput = {
  /** Attribute Protection */
  attributeProtection?: InputMaybe<Scalars['JSON']>;
  /** Blacklisted Attributes */
  blacklistedAttributes?: InputMaybe<Scalars['String']>;
  /** Can Also */
  canAlso?: InputMaybe<Scalars['JSON']>;
  /** Can Create */
  canCreate?: InputMaybe<Scalars['Boolean']>;
  /** Can Delete */
  canDelete?: InputMaybe<Scalars['Boolean']>;
  /** Can Read */
  canRead?: InputMaybe<Scalars['Boolean']>;
  /** Can Update */
  canUpdate?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Pesource Type ID */
  resourceTypeId?: InputMaybe<Scalars['Int']>;
  /** Role ID */
  roleId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CreatePermissionMutation */
export type CreatePermissionMutationPayload = {
  __typename?: 'CreatePermissionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  permission: Maybe<Permission>;
};

/** Autogenerated input type of CreatePersonaGroupMutation */
export type CreatePersonaGroupMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Group ID */
  groupId?: InputMaybe<Scalars['Int']>;
  /** Persona ID */
  personaId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CreatePersonaGroupMutation */
export type CreatePersonaGroupMutationPayload = {
  __typename?: 'CreatePersonaGroupMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  personaGroup: Maybe<PersonaGroup>;
};

/** Autogenerated input type of CreatePersonaMutation */
export type CreatePersonaMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** description */
  description?: InputMaybe<Scalars['String']>;
  /** Name */
  name?: InputMaybe<Scalars['String']>;
  /** whatfix_enabled */
  whatfixEnabled?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreatePersonaMutation */
export type CreatePersonaMutationPayload = {
  __typename?: 'CreatePersonaMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  persona: Maybe<Persona>;
};

/** Autogenerated input type of CreatePersonaRoleMutation */
export type CreatePersonaRoleMutationInput = {
  /** Base Scope ID */
  baseScopeId?: InputMaybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Persona ID */
  personaId?: InputMaybe<Scalars['Int']>;
  /** Role ID */
  roleId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CreatePersonaRoleMutation */
export type CreatePersonaRoleMutationPayload = {
  __typename?: 'CreatePersonaRoleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  personaRole: Maybe<PersonaRole>;
};

/** Autogenerated input type of CreatePersonaUserMutation */
export type CreatePersonaUserMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Persona ID */
  personaId?: InputMaybe<Scalars['Int']>;
  /** User ID */
  userId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CreatePersonaUserMutation */
export type CreatePersonaUserMutationPayload = {
  __typename?: 'CreatePersonaUserMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  personaUser: Maybe<PersonaUser>;
};

/** Autogenerated input type of CreatePreferenceMutation */
export type CreatePreferenceMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Context ID */
  contextId?: InputMaybe<Scalars['Int']>;
  /** Context Type */
  contextType?: InputMaybe<Scalars['String']>;
  /** Key */
  key?: InputMaybe<Scalars['String']>;
  /** Value */
  value?: InputMaybe<Scalars['JSON']>;
};

/** Autogenerated return type of CreatePreferenceMutation */
export type CreatePreferenceMutationPayload = {
  __typename?: 'CreatePreferenceMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  preference: Maybe<Preference>;
};

/** Autogenerated input type of CreateRoleMutation */
export type CreateRoleMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Is For Workflow */
  isForWorkflow?: InputMaybe<Scalars['Boolean']>;
  /** Name */
  name?: InputMaybe<Scalars['String']>;
  /** Owner ID */
  ownerId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateRoleMutation */
export type CreateRoleMutationPayload = {
  __typename?: 'CreateRoleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  role: Maybe<Role>;
};

/** Autogenerated input type of CreateRoleUserMutation */
export type CreateRoleUserMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Role ID */
  roleId?: InputMaybe<Scalars['Int']>;
  /** User ID */
  userId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateRoleUserMutation */
export type CreateRoleUserMutationPayload = {
  __typename?: 'CreateRoleUserMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  roleUser: Maybe<RoleUser>;
};

/** Autogenerated input type of CreateUserMutation */
export type CreateUserMutationInput = {
  /** allow_password_change */
  allowPasswordChange?: InputMaybe<Scalars['Boolean']>;
  /** Auto Send Purchase Order */
  autoSendPurchaseOrder?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Current Sign In At */
  currentSignInAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Current Sign In IP */
  currentSignInIp?: InputMaybe<Scalars['String']>;
  /** Default Organisation ID */
  defaultOrganisationId?: InputMaybe<Scalars['Int']>;
  /** Email */
  email?: InputMaybe<Scalars['String']>;
  /** enforce_buy_list */
  enforceBuyList?: InputMaybe<Scalars['Boolean']>;
  /** Full Name */
  fullName?: InputMaybe<Scalars['String']>;
  /** invitation_accepted_at */
  invitationAcceptedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** invitation_created_at */
  invitationCreatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** invitation_limit */
  invitationLimit?: InputMaybe<Scalars['Int']>;
  /** invitation_sent_at */
  invitationSentAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** invitation_token */
  invitationToken?: InputMaybe<Scalars['String']>;
  /** Is Deleted */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Last Sign In At */
  lastSignInAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Last Sign In IP */
  lastSignInIp?: InputMaybe<Scalars['String']>;
  /** Locked At */
  lockedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Mobile Phone */
  mobilePhone?: InputMaybe<Scalars['String']>;
  /** Password Changed At */
  passwordChangedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Position */
  position?: InputMaybe<Scalars['String']>;
  /** Provider */
  provider?: InputMaybe<Scalars['String']>;
  /** save_sort_preferences */
  saveSortPreferences?: InputMaybe<Scalars['Boolean']>;
  /** Sign In Count */
  signInCount?: InputMaybe<Scalars['Int']>;
  /** Staff Code */
  staffCode?: InputMaybe<Scalars['String']>;
  /** Telephone */
  telephone?: InputMaybe<Scalars['String']>;
  /** UID */
  uid?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateUserMutation */
export type CreateUserMutationPayload = {
  __typename?: 'CreateUserMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  user: Maybe<User>;
};

/** Autogenerated input type of CreateUserOrganisationMutation */
export type CreateUserOrganisationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** notification */
  notification?: InputMaybe<Scalars['Boolean']>;
  /** organisation_id */
  organisationId?: InputMaybe<Scalars['Int']>;
  /** User ID */
  userId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateUserOrganisationMutation */
export type CreateUserOrganisationMutationPayload = {
  __typename?: 'CreateUserOrganisationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  userOrganisation: Maybe<UserOrganisation>;
};

/** Autogenerated input type of CreateWorkflowMutation */
export type CreateWorkflowMutationInput = {
  /** Approval In Ranked Order */
  approvalInRankedOrder?: InputMaybe<Scalars['Boolean']>;
  /** Assignee Type */
  assigneeType?: InputMaybe<Scalars['String']>;
  /** auto_approve */
  autoApprove?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Event */
  event?: InputMaybe<Scalars['String']>;
  /** Is Deleted */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Owner ID */
  ownerId?: InputMaybe<Scalars['Int']>;
  /** Pesource Type ID */
  resourceTypeId?: InputMaybe<Scalars['Int']>;
  /** skip_approval_if_over_max_value */
  skipApprovalIfOverMaxValue?: InputMaybe<Scalars['Boolean']>;
  /** Title */
  title?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateWorkflowMutation */
export type CreateWorkflowMutationPayload = {
  __typename?: 'CreateWorkflowMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  workflow: Maybe<Workflow>;
};

/** Autogenerated input type of CreateWorkflowRankMembershipMutation */
export type CreateWorkflowRankMembershipMutationInput = {
  /** approval_workflow_id */
  approvalWorkflowId?: InputMaybe<Scalars['Int']>;
  /** approval_workflow_rank_id */
  approvalWorkflowRankId?: InputMaybe<Scalars['Int']>;
  /** assignee_id */
  assigneeId?: InputMaybe<Scalars['Int']>;
  /** Assignee Type */
  assigneeType?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** is_compulsory */
  isCompulsory?: InputMaybe<Scalars['Boolean']>;
  /** Role ID */
  roleId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateWorkflowRankMembershipMutation */
export type CreateWorkflowRankMembershipMutationPayload = {
  __typename?: 'CreateWorkflowRankMembershipMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  workflowRankMembership: Maybe<WorkflowRankMembership>;
};

/** Autogenerated input type of CreateWorkflowRankMutation */
export type CreateWorkflowRankMutationInput = {
  /** approval_workflow_id */
  approvalWorkflowId?: InputMaybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** is_unlimited */
  isUnlimited?: InputMaybe<Scalars['Boolean']>;
  /** maximum_value */
  maximumValue?: InputMaybe<Scalars['Float']>;
  /** minimum_approvers */
  minimumApprovers?: InputMaybe<Scalars['Int']>;
  /** rank */
  rank?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateWorkflowRankMutation */
export type CreateWorkflowRankMutationPayload = {
  __typename?: 'CreateWorkflowRankMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  workflowRank: Maybe<WorkflowRank>;
};

/** Autogenerated input type of CreateWorkflowRoleMutation */
export type CreateWorkflowRoleMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Is For Workflow */
  isForWorkflow?: InputMaybe<Scalars['Boolean']>;
  /** Name */
  name?: InputMaybe<Scalars['String']>;
  /** Owner ID */
  ownerId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateWorkflowRoleMutation */
export type CreateWorkflowRoleMutationPayload = {
  __typename?: 'CreateWorkflowRoleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  workflowRole: Maybe<WorkflowRole>;
};

/** Autogenerated input type of DeleteApiKeyMutation */
export type DeleteApiKeyMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteApiKeyMutation */
export type DeleteApiKeyMutationPayload = {
  __typename?: 'DeleteApiKeyMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  success: Scalars['String'];
};

/** Autogenerated input type of DeleteGroupMutation */
export type DeleteGroupMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteGroupMutation */
export type DeleteGroupMutationPayload = {
  __typename?: 'DeleteGroupMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  success: Scalars['String'];
};

/** Autogenerated input type of DeletePermissionMutation */
export type DeletePermissionMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeletePermissionMutation */
export type DeletePermissionMutationPayload = {
  __typename?: 'DeletePermissionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  success: Scalars['String'];
};

/** Autogenerated input type of DeletePersonaGroupMutation */
export type DeletePersonaGroupMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeletePersonaGroupMutation */
export type DeletePersonaGroupMutationPayload = {
  __typename?: 'DeletePersonaGroupMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  success: Scalars['String'];
};

/** Autogenerated input type of DeletePersonaMutation */
export type DeletePersonaMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeletePersonaMutation */
export type DeletePersonaMutationPayload = {
  __typename?: 'DeletePersonaMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  success: Scalars['String'];
};

/** Autogenerated input type of DeletePersonaRoleMutation */
export type DeletePersonaRoleMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeletePersonaRoleMutation */
export type DeletePersonaRoleMutationPayload = {
  __typename?: 'DeletePersonaRoleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  success: Scalars['String'];
};

/** Autogenerated input type of DeletePersonaUserMutation */
export type DeletePersonaUserMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeletePersonaUserMutation */
export type DeletePersonaUserMutationPayload = {
  __typename?: 'DeletePersonaUserMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  success: Scalars['String'];
};

/** Autogenerated input type of DeleteRoleMutation */
export type DeleteRoleMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteRoleMutation */
export type DeleteRoleMutationPayload = {
  __typename?: 'DeleteRoleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  success: Scalars['String'];
};

/** Autogenerated input type of DeleteRoleUserMutation */
export type DeleteRoleUserMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteRoleUserMutation */
export type DeleteRoleUserMutationPayload = {
  __typename?: 'DeleteRoleUserMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  success: Scalars['String'];
};

/** Autogenerated input type of DeleteUserMutation */
export type DeleteUserMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteUserMutation */
export type DeleteUserMutationPayload = {
  __typename?: 'DeleteUserMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  success: Scalars['String'];
};

/** Autogenerated input type of DeleteUserOrganisationMutation */
export type DeleteUserOrganisationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteUserOrganisationMutation */
export type DeleteUserOrganisationMutationPayload = {
  __typename?: 'DeleteUserOrganisationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  success: Scalars['String'];
};

/** Autogenerated input type of DeleteWorkflowMutation */
export type DeleteWorkflowMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteWorkflowMutation */
export type DeleteWorkflowMutationPayload = {
  __typename?: 'DeleteWorkflowMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  success: Scalars['String'];
};

/** Autogenerated input type of DeleteWorkflowRankMembershipMutation */
export type DeleteWorkflowRankMembershipMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteWorkflowRankMembershipMutation */
export type DeleteWorkflowRankMembershipMutationPayload = {
  __typename?: 'DeleteWorkflowRankMembershipMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  success: Scalars['String'];
};

/** Autogenerated input type of DeleteWorkflowRankMutation */
export type DeleteWorkflowRankMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteWorkflowRankMutation */
export type DeleteWorkflowRankMutationPayload = {
  __typename?: 'DeleteWorkflowRankMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  success: Scalars['String'];
};

/** Autogenerated input type of DeleteWorkflowRoleMutation */
export type DeleteWorkflowRoleMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteWorkflowRoleMutation */
export type DeleteWorkflowRoleMutationPayload = {
  __typename?: 'DeleteWorkflowRoleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  success: Scalars['String'];
};

export type Feature = {
  __typename?: 'Feature';
  /** Action */
  action: Maybe<Scalars['String']>;
  /** activities */
  activities: Maybe<ActivityConnection>;
  branches: Maybe<Array<Feature>>;
  /** Created At */
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  engine: Maybe<Scalars['String']>;
  /** Engine Name */
  engineName: Maybe<Scalars['String']>;
  /** Icon Class */
  iconClass: Maybe<Scalars['String']>;
  /** ID */
  id: Scalars['Int'];
  /** Legacy Path */
  legacyPath: Maybe<Scalars['String']>;
  /** Locale */
  locale: Maybe<Scalars['String']>;
  /** Name */
  name: Maybe<Scalars['String']>;
  objectPermissions: ObjectPermission;
  /** Organisation Type */
  organisationType: Maybe<Scalars['Int']>;
  /** Parent */
  parent: Maybe<Feature>;
  /** Parent ID */
  parentId: Maybe<Scalars['Int']>;
  path: Maybe<Scalars['String']>;
  /** Permissions */
  permissions: Maybe<PermissionConnection>;
  /** Position */
  position: Maybe<Scalars['Int']>;
  /** Root Resource Type */
  rootResourceType: Maybe<ResourceType>;
  /** Root Resource Type ID */
  rootResourceTypeId: Maybe<Scalars['Int']>;
  /** Sub Resource Type ID */
  subResourceTypeId: Maybe<Scalars['Int']>;
  /** Subpath */
  subpath: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
};


export type FeatureActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type FeatureParentArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type FeaturePermissionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type FeatureRootResourceTypeArgs = {
  id: InputMaybe<Scalars['Int']>;
};

/** The connection type for Feature. */
export type FeatureConnection = {
  __typename?: 'FeatureConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<FeatureEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Feature>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type FeatureEdge = {
  __typename?: 'FeatureEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Feature>;
};

/** Autogenerated input type of GenerateApiKeyMutation */
export type GenerateApiKeyMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of GenerateApiKeyMutation */
export type GenerateApiKeyMutationPayload = {
  __typename?: 'GenerateApiKeyMutationPayload';
  apiKey: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
};

export type Group = {
  __typename?: 'Group';
  /** activities */
  activities: Maybe<ActivityConnection>;
  /** Created At */
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  /** ID */
  id: Scalars['Int'];
  /** Name */
  name: Maybe<Scalars['String']>;
  objectPermissions: ObjectPermission;
  /** Organisation Role */
  organisationRole: Maybe<Scalars['String']>;
  /** Organisations */
  organisations: Maybe<OrganisationConnection>;
  /** Parent */
  parent: Maybe<Group>;
  /** Parent ID */
  parentId: Maybe<Scalars['Int']>;
  /** Path */
  path: Maybe<Scalars['String']>;
  /** Persona Group */
  personaGroup: Maybe<PersonaGroup>;
  /** Persona Groups */
  personaGroups: Maybe<PersonaGroupConnection>;
  /** Personas */
  personas: Maybe<PersonaConnection>;
  /** Roles */
  roles: Maybe<RoleConnection>;
  /** Updated At */
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** user_organisations */
  userOrganisations: Maybe<UserOrganisationConnection>;
  /** Users */
  users: Maybe<UserConnection>;
};


export type GroupActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type GroupOrganisationsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type GroupParentArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type GroupPersonaGroupArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type GroupPersonaGroupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type GroupPersonasArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type GroupRolesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type GroupUserOrganisationsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type GroupUsersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};

/** The connection type for Group. */
export type GroupConnection = {
  __typename?: 'GroupConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<GroupEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Group>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type GroupEdge = {
  __typename?: 'GroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Group>;
};

/** Autogenerated input type of ImpersonateUserMutation */
export type ImpersonateUserMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of ImpersonateUserMutation */
export type ImpersonateUserMutationPayload = {
  __typename?: 'ImpersonateUserMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  impersonatedOrganisation: Maybe<Organisation>;
  impersonatedUser: Maybe<User>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changeOrganisation: ChangeOrganisationMutationPayload;
  clearCache: Maybe<ClearCacheMutationPayload>;
  confirmUser: Maybe<ConfirmUserMutationPayload>;
  createGroup: CreateGroupMutationPayload;
  createPermission: CreatePermissionMutationPayload;
  createPersona: CreatePersonaMutationPayload;
  createPersonaGroup: CreatePersonaGroupMutationPayload;
  createPersonaRole: CreatePersonaRoleMutationPayload;
  createPersonaUser: CreatePersonaUserMutationPayload;
  createPreference: CreatePreferenceMutationPayload;
  createRole: CreateRoleMutationPayload;
  createRoleUser: CreateRoleUserMutationPayload;
  createUser: CreateUserMutationPayload;
  createUserOrganisation: CreateUserOrganisationMutationPayload;
  createWorkflow: CreateWorkflowMutationPayload;
  createWorkflowRank: CreateWorkflowRankMutationPayload;
  createWorkflowRankMembership: CreateWorkflowRankMembershipMutationPayload;
  createWorkflowRole: CreateWorkflowRoleMutationPayload;
  deleteApiKey: DeleteApiKeyMutationPayload;
  deleteGroup: DeleteGroupMutationPayload;
  deletePermission: DeletePermissionMutationPayload;
  deletePersona: DeletePersonaMutationPayload;
  deletePersonaGroup: DeletePersonaGroupMutationPayload;
  deletePersonaRole: DeletePersonaRoleMutationPayload;
  deletePersonaUser: DeletePersonaUserMutationPayload;
  deleteRole: DeleteRoleMutationPayload;
  deleteRoleUser: DeleteRoleUserMutationPayload;
  deleteUser: DeleteUserMutationPayload;
  deleteUserOrganisation: DeleteUserOrganisationMutationPayload;
  deleteWorkflow: DeleteWorkflowMutationPayload;
  deleteWorkflowRank: DeleteWorkflowRankMutationPayload;
  deleteWorkflowRankMembership: DeleteWorkflowRankMembershipMutationPayload;
  deleteWorkflowRole: DeleteWorkflowRoleMutationPayload;
  generateApiKey: Maybe<GenerateApiKeyMutationPayload>;
  impersonateUser: ImpersonateUserMutationPayload;
  resetPassword: Maybe<ResetPasswordMutationPayload>;
  signIn: Maybe<SignInMutationPayload>;
  signOut: Maybe<SignOutMutationPayload>;
  updateGroup: UpdateGroupMutationPayload;
  updatePermission: UpdatePermissionMutationPayload;
  updatePersona: UpdatePersonaMutationPayload;
  updatePreference: UpdatePreferenceMutationPayload;
  updateResourceType: UpdateResourceTypeMutationPayload;
  updateRole: UpdateRoleMutationPayload;
  updateUser: UpdateUserMutationPayload;
  updateUserOrganisation: UpdateUserOrganisationMutationPayload;
  updateWorkflow: UpdateWorkflowMutationPayload;
  updateWorkflowRank: UpdateWorkflowRankMutationPayload;
  updateWorkflowRankMembership: UpdateWorkflowRankMembershipMutationPayload;
  updateWorkflowRole: UpdateWorkflowRoleMutationPayload;
  validateResetPasswordToken: Maybe<ValidateResetPasswordTokenMutationPayload>;
};


export type MutationChangeOrganisationArgs = {
  input: ChangeOrganisationMutationInput;
};


export type MutationClearCacheArgs = {
  input: ClearCacheMutationInput;
};


export type MutationConfirmUserArgs = {
  input: ConfirmUserMutationInput;
};


export type MutationCreateGroupArgs = {
  input: CreateGroupMutationInput;
};


export type MutationCreatePermissionArgs = {
  input: CreatePermissionMutationInput;
};


export type MutationCreatePersonaArgs = {
  input: CreatePersonaMutationInput;
};


export type MutationCreatePersonaGroupArgs = {
  input: CreatePersonaGroupMutationInput;
};


export type MutationCreatePersonaRoleArgs = {
  input: CreatePersonaRoleMutationInput;
};


export type MutationCreatePersonaUserArgs = {
  input: CreatePersonaUserMutationInput;
};


export type MutationCreatePreferenceArgs = {
  input: CreatePreferenceMutationInput;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleMutationInput;
};


export type MutationCreateRoleUserArgs = {
  input: CreateRoleUserMutationInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserMutationInput;
};


export type MutationCreateUserOrganisationArgs = {
  input: CreateUserOrganisationMutationInput;
};


export type MutationCreateWorkflowArgs = {
  input: CreateWorkflowMutationInput;
};


export type MutationCreateWorkflowRankArgs = {
  input: CreateWorkflowRankMutationInput;
};


export type MutationCreateWorkflowRankMembershipArgs = {
  input: CreateWorkflowRankMembershipMutationInput;
};


export type MutationCreateWorkflowRoleArgs = {
  input: CreateWorkflowRoleMutationInput;
};


export type MutationDeleteApiKeyArgs = {
  input: DeleteApiKeyMutationInput;
};


export type MutationDeleteGroupArgs = {
  input: DeleteGroupMutationInput;
};


export type MutationDeletePermissionArgs = {
  input: DeletePermissionMutationInput;
};


export type MutationDeletePersonaArgs = {
  input: DeletePersonaMutationInput;
};


export type MutationDeletePersonaGroupArgs = {
  input: DeletePersonaGroupMutationInput;
};


export type MutationDeletePersonaRoleArgs = {
  input: DeletePersonaRoleMutationInput;
};


export type MutationDeletePersonaUserArgs = {
  input: DeletePersonaUserMutationInput;
};


export type MutationDeleteRoleArgs = {
  input: DeleteRoleMutationInput;
};


export type MutationDeleteRoleUserArgs = {
  input: DeleteRoleUserMutationInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserMutationInput;
};


export type MutationDeleteUserOrganisationArgs = {
  input: DeleteUserOrganisationMutationInput;
};


export type MutationDeleteWorkflowArgs = {
  input: DeleteWorkflowMutationInput;
};


export type MutationDeleteWorkflowRankArgs = {
  input: DeleteWorkflowRankMutationInput;
};


export type MutationDeleteWorkflowRankMembershipArgs = {
  input: DeleteWorkflowRankMembershipMutationInput;
};


export type MutationDeleteWorkflowRoleArgs = {
  input: DeleteWorkflowRoleMutationInput;
};


export type MutationGenerateApiKeyArgs = {
  input: GenerateApiKeyMutationInput;
};


export type MutationImpersonateUserArgs = {
  input: ImpersonateUserMutationInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordMutationInput;
};


export type MutationSignInArgs = {
  input: SignInMutationInput;
};


export type MutationSignOutArgs = {
  input: SignOutMutationInput;
};


export type MutationUpdateGroupArgs = {
  input: UpdateGroupMutationInput;
};


export type MutationUpdatePermissionArgs = {
  input: UpdatePermissionMutationInput;
};


export type MutationUpdatePersonaArgs = {
  input: UpdatePersonaMutationInput;
};


export type MutationUpdatePreferenceArgs = {
  input: UpdatePreferenceMutationInput;
};


export type MutationUpdateResourceTypeArgs = {
  input: UpdateResourceTypeMutationInput;
};


export type MutationUpdateRoleArgs = {
  input: UpdateRoleMutationInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserMutationInput;
};


export type MutationUpdateUserOrganisationArgs = {
  input: UpdateUserOrganisationMutationInput;
};


export type MutationUpdateWorkflowArgs = {
  input: UpdateWorkflowMutationInput;
};


export type MutationUpdateWorkflowRankArgs = {
  input: UpdateWorkflowRankMutationInput;
};


export type MutationUpdateWorkflowRankMembershipArgs = {
  input: UpdateWorkflowRankMembershipMutationInput;
};


export type MutationUpdateWorkflowRoleArgs = {
  input: UpdateWorkflowRoleMutationInput;
};


export type MutationValidateResetPasswordTokenArgs = {
  input: ValidateResetPasswordTokenMutationInput;
};

export type ObjectPermission = {
  __typename?: 'ObjectPermission';
  create: Maybe<Scalars['Boolean']>;
  delete: Maybe<Scalars['Boolean']>;
  update: Maybe<Scalars['Boolean']>;
};

export type Organisation = {
  __typename?: 'Organisation';
  /** Accepted Invoicing Terms */
  acceptedInvoicingTerms: Maybe<Scalars['Boolean']>;
  /** Accepts Electronic Invoices */
  acceptsElectronicInvoices: Maybe<Scalars['Boolean']>;
  /** Access Group */
  accessGroup: Maybe<Group>;
  /** Access Group ID */
  accessGroupId: Maybe<Scalars['Int']>;
  /** Accounts Email */
  accountsEmail: Maybe<Scalars['String']>;
  /** Accounts Payable System ID */
  accountsPayableSystemId: Maybe<Scalars['Int']>;
  /** activities */
  activities: Maybe<ActivityConnection>;
  /** Allow Negative Stock Level */
  allowNegativeStockLevel: Maybe<Scalars['Boolean']>;
  /** api_invoice_polling_interval_minutes */
  apiInvoicePollingIntervalMinutes: Maybe<Scalars['Int']>;
  /** api_last_polled_at */
  apiLastPolledAt: Maybe<Scalars['ISO8601DateTime']>;
  /** api_password */
  apiPassword: Maybe<Scalars['String']>;
  /** api_provider */
  apiProvider: Maybe<Scalars['String']>;
  /** api_token */
  apiToken: Maybe<Scalars['String']>;
  /** api_url */
  apiUrl: Maybe<Scalars['String']>;
  /** api_username */
  apiUsername: Maybe<Scalars['String']>;
  /** Auto Close Order */
  autoCloseOrder: Maybe<Scalars['Boolean']>;
  /** auto_recalculate_recipe_cost */
  autoRecalculateRecipeCost: Maybe<Scalars['Boolean']>;
  /** autopopulate_par_level */
  autopopulateParLevel: Maybe<Scalars['Boolean']>;
  /** Billing Address City */
  billingAddressCity: Maybe<Scalars['String']>;
  /** Billing Address Country */
  billingAddressCountry: Maybe<Scalars['String']>;
  /** Billing Address Is Postal Address */
  billingAddressIsPostalAddress: Maybe<Scalars['Boolean']>;
  /** Billing Address Line1 */
  billingAddressLine1: Maybe<Scalars['String']>;
  /** Billing Address Line2 */
  billingAddressLine2: Maybe<Scalars['String']>;
  /** Billing Address Postal Code */
  billingAddressPostalCode: Maybe<Scalars['String']>;
  /** Billing Address State Province */
  billingAddressStateProvince: Maybe<Scalars['String']>;
  /** Billing Agreement Content Type */
  billingAgreementContentType: Maybe<Scalars['String']>;
  /** Billing Agreement Customised */
  billingAgreementCustomised: Maybe<Scalars['Boolean']>;
  /** Billing Agreement File Name */
  billingAgreementFileName: Maybe<Scalars['String']>;
  /** Billing Agreement File Size */
  billingAgreementFileSize: Maybe<Scalars['Int']>;
  /** billing_currency */
  billingCurrency: Maybe<Scalars['String']>;
  /** Billing Email */
  billingEmail: Maybe<Scalars['String']>;
  /** business_name */
  businessName: Maybe<Scalars['String']>;
  /** Business Number */
  businessNumber: Maybe<Scalars['String']>;
  /** Business Unit */
  businessUnit: Maybe<Scalars['String']>;
  /** Canonical ID */
  canonicalId: Maybe<Scalars['Int']>;
  children: Maybe<Array<Organisation>>;
  /** Company Brand */
  companyBrand: Maybe<Scalars['String']>;
  /** Concatenate Product And Pack Codes */
  concatenateProductAndPackCodes: Maybe<Scalars['Boolean']>;
  /** Confirmation Instructions */
  confirmationInstructions: Maybe<Scalars['String']>;
  /** Continuous Approving */
  continuousApproving: Maybe<Scalars['Boolean']>;
  /** Corporate Code */
  corporateCode: Maybe<Scalars['String']>;
  /** Created At */
  createdAt: Scalars['ISO8601DateTime'];
  /** credit_application_url */
  creditApplicationUrl: Maybe<Scalars['String']>;
  /** Default Account ID */
  defaultAccountId: Maybe<Scalars['Int']>;
  /** default_app */
  defaultApp: Maybe<Scalars['String']>;
  /** Default Delivery Address ID */
  defaultDeliveryAddressId: Maybe<Scalars['Int']>;
  /** Default Language */
  defaultLanguage: Maybe<Scalars['String']>;
  /** Delivery Instructions */
  deliveryInstructions: Maybe<Scalars['String']>;
  /** Department Code */
  departmentCode: Maybe<Scalars['String']>;
  /** Description */
  description: Maybe<Scalars['String']>;
  /** destination_company_code */
  destinationCompanyCode: Maybe<Scalars['String']>;
  /** Discount Rate */
  discountRate: Maybe<Scalars['Float']>;
  /** display_xtracta_detail */
  displayXtractaDetail: Maybe<Scalars['Boolean']>;
  /** document_line_validation_threshold */
  documentLineValidationThreshold: Maybe<Scalars['Float']>;
  /** document_validation_threshold */
  documentValidationThreshold: Maybe<Scalars['Float']>;
  /** Email */
  email: Maybe<Scalars['String']>;
  /** Email API Invoice Copy */
  emailApiInvoiceCopy: Maybe<Scalars['Boolean']>;
  /** Enforce Password Change */
  enforcePasswordChange: Maybe<Scalars['Boolean']>;
  /** FAX */
  fax: Maybe<Scalars['String']>;
  /** IANA Time Zone */
  ianaTimeZone: Maybe<Scalars['String']>;
  /** ID */
  id: Scalars['Int'];
  /** input_tax */
  inputTax: Maybe<Scalars['Boolean']>;
  /** Invoice Days Threshold */
  invoiceDaysThreshold: Maybe<Scalars['Int']>;
  /** Invoice Method */
  invoiceMethod: Maybe<Scalars['String']>;
  /** Invoice Template ID */
  invoiceTemplateId: Maybe<Scalars['Int']>;
  /** Is A Administrator */
  isAAdministrator: Maybe<Scalars['Boolean']>;
  /** Is A Purchaser */
  isAPurchaser: Maybe<Scalars['Boolean']>;
  /** Is A Supplier */
  isASupplier: Maybe<Scalars['Boolean']>;
  /** Is Billable */
  isBillable: Maybe<Scalars['Boolean']>;
  /** is_capex */
  isCapex: Maybe<Scalars['Boolean']>;
  /** Is Deleted */
  isDeleted: Maybe<Scalars['Boolean']>;
  /** Is Inventory Enabled */
  isInventoryEnabled: Maybe<Scalars['Boolean']>;
  /** Is Verified */
  isVerified: Maybe<Scalars['Boolean']>;
  /** Legal Entity */
  legalEntity: Maybe<Scalars['Boolean']>;
  /** live_chat_enabled */
  liveChatEnabled: Maybe<Scalars['Boolean']>;
  /** Locale */
  locale: Maybe<Scalars['String']>;
  /** Location ID */
  locationId: Maybe<Scalars['Int']>;
  /** Logo Content Type */
  logoContentType: Maybe<Scalars['String']>;
  /** Logo File Name */
  logoFileName: Maybe<Scalars['String']>;
  /** Logo File Size */
  logoFileSize: Maybe<Scalars['Int']>;
  /** mandatory_informal_product_tax */
  mandatoryInformalProductTax: Maybe<Scalars['Boolean']>;
  /** mandatory_informal_product_tax_percentage */
  mandatoryInformalProductTaxPercentage: Maybe<Scalars['Float']>;
  /** minimum_order_value */
  minimumOrderValue: Maybe<Scalars['Float']>;
  /** minimum_order_value_message */
  minimumOrderValueMessage: Maybe<Scalars['String']>;
  /** Name */
  name: Maybe<Scalars['String']>;
  objectPermissions: ObjectPermission;
  /** origin_company_code */
  originCompanyCode: Maybe<Scalars['String']>;
  /** Outlet Code */
  outletCode: Maybe<Scalars['String']>;
  /** Owner ID */
  ownerId: Maybe<Scalars['Int']>;
  /** Page Size Limit */
  pageSizeLimit: Maybe<Scalars['Int']>;
  /** Parent */
  parent: Maybe<Organisation>;
  /** Parent ID */
  parentId: Maybe<Scalars['Int']>;
  /** Path */
  path: Maybe<Scalars['String']>;
  /** Point Of Sale Import Format ID */
  pointOfSaleImportFormatId: Maybe<Scalars['Int']>;
  /** Postal Address City */
  postalAddressCity: Maybe<Scalars['String']>;
  /** Postal Address Country */
  postalAddressCountry: Maybe<Scalars['String']>;
  /** Postal Address Line1 */
  postalAddressLine1: Maybe<Scalars['String']>;
  /** Postal Address Line2 */
  postalAddressLine2: Maybe<Scalars['String']>;
  /** Postal Address Postal Code */
  postalAddressPostalCode: Maybe<Scalars['String']>;
  /** Postal Address State Province */
  postalAddressStateProvince: Maybe<Scalars['String']>;
  /** ppi_access */
  ppiAccess: Maybe<Scalars['Boolean']>;
  /** pplus_access */
  pplusAccess: Maybe<Scalars['Boolean']>;
  /** ppnew_access */
  ppnewAccess: Maybe<Scalars['Boolean']>;
  /** ppx_access */
  ppxAccess: Maybe<Scalars['Boolean']>;
  /** Preferred Supplier Only */
  preferredSupplierOnly: Maybe<Scalars['Boolean']>;
  /** Print Format Content Type */
  printFormatContentType: Maybe<Scalars['String']>;
  /** Print Format File Name */
  printFormatFileName: Maybe<Scalars['String']>;
  /** Print Format File Size */
  printFormatFileSize: Maybe<Scalars['Int']>;
  /** Punchout Line Transformer Type */
  punchoutLineTransformerType: Maybe<Scalars['String']>;
  /** purchase_order_copy_email */
  purchaseOrderCopyEmail: Maybe<Scalars['String']>;
  /** Purchase Order Reply To Email */
  purchaseOrderReplyToEmail: Maybe<Scalars['String']>;
  /** Receive Only From Master Catalogue */
  receiveOnlyFromMasterCatalogue: Maybe<Scalars['Boolean']>;
  /** Region ID */
  regionId: Maybe<Scalars['Int']>;
  /** requires_budget_approval */
  requiresBudgetApproval: Maybe<Scalars['Boolean']>;
  /** Requisition Approval Workflow ID */
  requisitionApprovalWorkflowId: Maybe<Scalars['Int']>;
  /** Room Count */
  roomCount: Maybe<Scalars['Int']>;
  /** Short Description */
  shortDescription: Maybe<Scalars['String']>;
  /** Show Members */
  showMembership: Maybe<Scalars['Boolean']>;
  /** Signature Image Content Type */
  signatureImageContentType: Maybe<Scalars['String']>;
  /** Signature Image File Name */
  signatureImageFileName: Maybe<Scalars['String']>;
  /** Signature Image File Size */
  signatureImageFileSize: Maybe<Scalars['Int']>;
  /** Signature Image Updated At */
  signatureImageUpdatedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** supplier_agreement_signed_at */
  supplierAgreementSignedAt: Maybe<Scalars['Date']>;
  /** supplier_subscription_tier */
  supplierSubscriptionTier: Maybe<Scalars['String']>;
  /** Telephone */
  telephone: Maybe<Scalars['String']>;
  /** Terms And Conditions */
  termsAndConditions: Maybe<Scalars['String']>;
  /** Three Way Match Enabled */
  threeWayMatchEnabled: Maybe<Scalars['Boolean']>;
  /** Time Zone */
  timeZone: Maybe<Scalars['String']>;
  /** Title */
  title: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt: Scalars['ISO8601DateTime'];
  /** User */
  user: Maybe<User>;
  /** user_organisation */
  userOrganisation: Maybe<UserOrganisation>;
  /** user_organisations */
  userOrganisations: Maybe<UserOrganisationConnection>;
  /** Users */
  users: Maybe<UserConnection>;
  /** Website URL */
  websiteUrl: Maybe<Scalars['String']>;
  /** Weekly Payment */
  weeklyPayment: Maybe<Scalars['Boolean']>;
  /** whatfix_enabled */
  whatfixEnabled: Maybe<Scalars['Boolean']>;
  /** Workflow Roles */
  workflowRoles: Maybe<WorkflowRoleConnection>;
  /** Workflows */
  workflows: Maybe<WorkflowConnection>;
};


export type OrganisationAccessGroupArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type OrganisationActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type OrganisationParentArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type OrganisationUserArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type OrganisationUserOrganisationArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type OrganisationUserOrganisationsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type OrganisationUsersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type OrganisationWorkflowRolesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type OrganisationWorkflowsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};

/** The connection type for Organisation. */
export type OrganisationConnection = {
  __typename?: 'OrganisationConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<OrganisationEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Organisation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrganisationEdge = {
  __typename?: 'OrganisationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Organisation>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']>;
};

export type Permission = {
  __typename?: 'Permission';
  /** activities */
  activities: Maybe<ActivityConnection>;
  /** Attribute Protection */
  attributeProtection: Maybe<Scalars['JSON']>;
  /** Blacklisted Attributes */
  blacklistedAttributes: Maybe<Scalars['String']>;
  /** Can Also */
  canAlso: Maybe<Scalars['JSON']>;
  /** Can Create */
  canCreate: Maybe<Scalars['Boolean']>;
  /** Can Delete */
  canDelete: Maybe<Scalars['Boolean']>;
  /** Can Read */
  canRead: Maybe<Scalars['Boolean']>;
  /** Can Update */
  canUpdate: Maybe<Scalars['Boolean']>;
  /** Created At */
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  /** ID */
  id: Scalars['Int'];
  objectPermissions: ObjectPermission;
  /** Resource Type */
  resourceType: Maybe<ResourceType>;
  /** Resource Type ID */
  resourceTypeId: Maybe<Scalars['Int']>;
  /** Role */
  role: Maybe<Role>;
  /** Role ID */
  roleId: Maybe<Scalars['Int']>;
  /** Updated At */
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
};


export type PermissionActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type PermissionResourceTypeArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type PermissionRoleArgs = {
  id: InputMaybe<Scalars['Int']>;
};

/** The connection type for Permission. */
export type PermissionConnection = {
  __typename?: 'PermissionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PermissionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Permission>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PermissionEdge = {
  __typename?: 'PermissionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Permission>;
};

export type Persona = {
  __typename?: 'Persona';
  /** activities */
  activities: Maybe<ActivityConnection>;
  /** Created At */
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  /** Description */
  description: Maybe<Scalars['String']>;
  /** Groups */
  groups: Maybe<GroupConnection>;
  /** ID */
  id: Scalars['Int'];
  /** Name */
  name: Maybe<Scalars['String']>;
  objectPermissions: ObjectPermission;
  /** Permissions */
  permissions: Maybe<PermissionConnection>;
  /** Persona Group */
  personaGroup: Maybe<PersonaGroup>;
  /** Persona Groups */
  personaGroups: Maybe<PersonaGroupConnection>;
  /** Persona Role */
  personaRole: Maybe<PersonaRole>;
  /** Persona Roles */
  personaRoles: Maybe<PersonaRoleConnection>;
  /** Persona User */
  personaUser: Maybe<PersonaUser>;
  /** Persona Users */
  personaUsers: Maybe<PersonaUserConnection>;
  /** Roles */
  roles: Maybe<RoleConnection>;
  /** Updated At */
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** Users */
  users: Maybe<UserConnection>;
  /** whatfix_enabled */
  whatfixEnabled: Maybe<Scalars['Boolean']>;
};


export type PersonaActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type PersonaGroupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type PersonaPermissionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type PersonaPersonaGroupArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type PersonaPersonaGroupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type PersonaPersonaRoleArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type PersonaPersonaRolesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type PersonaPersonaUserArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type PersonaPersonaUsersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type PersonaRolesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type PersonaUsersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};

/** The connection type for Persona. */
export type PersonaConnection = {
  __typename?: 'PersonaConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PersonaEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Persona>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PersonaEdge = {
  __typename?: 'PersonaEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Persona>;
};

export type PersonaGroup = {
  __typename?: 'PersonaGroup';
  /** activities */
  activities: Maybe<ActivityConnection>;
  /** Created At */
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  /** Group */
  group: Maybe<Group>;
  /** Group ID */
  groupId: Maybe<Scalars['Int']>;
  /** ID */
  id: Scalars['Int'];
  objectPermissions: ObjectPermission;
  /** Persona */
  persona: Maybe<Persona>;
  /** Persona ID */
  personaId: Maybe<Scalars['Int']>;
  /** Updated At */
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
};


export type PersonaGroupActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type PersonaGroupGroupArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type PersonaGroupPersonaArgs = {
  id: InputMaybe<Scalars['Int']>;
};

/** The connection type for PersonaGroup. */
export type PersonaGroupConnection = {
  __typename?: 'PersonaGroupConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PersonaGroupEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<PersonaGroup>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PersonaGroupEdge = {
  __typename?: 'PersonaGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<PersonaGroup>;
};

export type PersonaRole = {
  __typename?: 'PersonaRole';
  accessScope: Maybe<Scalars['String']>;
  /** activities */
  activities: Maybe<ActivityConnection>;
  /** Base Scope ID */
  baseScopeId: Maybe<Scalars['Int']>;
  /** Created At */
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  /** ID */
  id: Scalars['Int'];
  objectPermissions: ObjectPermission;
  /** Persona */
  persona: Maybe<Persona>;
  /** Persona ID */
  personaId: Maybe<Scalars['Int']>;
  /** Role */
  role: Maybe<Role>;
  /** Role ID */
  roleId: Maybe<Scalars['Int']>;
  /** Updated At */
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
};


export type PersonaRoleActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type PersonaRolePersonaArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type PersonaRoleRoleArgs = {
  id: InputMaybe<Scalars['Int']>;
};

/** The connection type for PersonaRole. */
export type PersonaRoleConnection = {
  __typename?: 'PersonaRoleConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PersonaRoleEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<PersonaRole>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PersonaRoleEdge = {
  __typename?: 'PersonaRoleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<PersonaRole>;
};

export type PersonaUser = {
  __typename?: 'PersonaUser';
  /** activities */
  activities: Maybe<ActivityConnection>;
  /** Created At */
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  /** ID */
  id: Scalars['Int'];
  objectPermissions: ObjectPermission;
  /** Persona */
  persona: Maybe<Persona>;
  /** Persona ID */
  personaId: Maybe<Scalars['Int']>;
  /** Updated At */
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** User */
  user: Maybe<User>;
  /** User ID */
  userId: Maybe<Scalars['Int']>;
};


export type PersonaUserActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type PersonaUserPersonaArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type PersonaUserUserArgs = {
  id: InputMaybe<Scalars['Int']>;
};

/** The connection type for PersonaUser. */
export type PersonaUserConnection = {
  __typename?: 'PersonaUserConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PersonaUserEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<PersonaUser>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PersonaUserEdge = {
  __typename?: 'PersonaUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<PersonaUser>;
};

export type Preference = {
  __typename?: 'Preference';
  /** Context ID */
  contextId: Maybe<Scalars['Int']>;
  /** Context Type */
  contextType: Maybe<Scalars['String']>;
  /** Created At */
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  /** ID */
  id: Scalars['Int'];
  /** Key */
  key: Maybe<Scalars['String']>;
  objectPermissions: ObjectPermission;
  /** Updated At */
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** Value */
  value: Maybe<Scalars['JSON']>;
};

/** The connection type for Preference. */
export type PreferenceConnection = {
  __typename?: 'PreferenceConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PreferenceEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Preference>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PreferenceEdge = {
  __typename?: 'PreferenceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Preference>;
};

export type Query = {
  __typename?: 'Query';
  currentUser: Maybe<User>;
  /** feature */
  feature: Maybe<Feature>;
  features: Array<Feature>;
  /** Group */
  group: Maybe<Group>;
  /** Groups */
  groups: Maybe<GroupConnection>;
  /** organisation */
  organisation: Maybe<Organisation>;
  /** Organisations */
  organisations: Maybe<OrganisationConnection>;
  /** Persona */
  persona: Maybe<Persona>;
  /** Personas */
  personas: Maybe<PersonaConnection>;
  /** Preference */
  preference: Maybe<Preference>;
  /** Resource Type */
  resourceType: Maybe<ResourceType>;
  /** Resource Types */
  resourceTypes: Maybe<ResourceTypeConnection>;
  /** Role */
  role: Maybe<Role>;
  /** Roles */
  roles: Maybe<RoleConnection>;
  /** User */
  user: Maybe<User>;
  /** Users */
  users: Maybe<UserConnection>;
  /** Workflow */
  workflow: Maybe<Workflow>;
  /** workflow_rank */
  workflowRank: Maybe<WorkflowRank>;
  /** Workflow Role */
  workflowRole: Maybe<WorkflowRole>;
  /** Workflow Roles */
  workflowRoles: Maybe<WorkflowRoleConnection>;
  /** Workflows */
  workflows: Maybe<WorkflowConnection>;
};


export type QueryFeatureArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type QueryGroupArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type QueryGroupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type QueryOrganisationArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type QueryOrganisationsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type QueryPersonaArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type QueryPersonasArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type QueryPreferenceArgs = {
  id: InputMaybe<Scalars['Int']>;
  key: Scalars['String'];
};


export type QueryResourceTypeArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type QueryResourceTypesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type QueryRoleArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type QueryRolesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type QueryUserArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type QueryUsersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type QueryWorkflowArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type QueryWorkflowRankArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type QueryWorkflowRoleArgs = {
  id: InputMaybe<Scalars['Int']>;
  ownerId: InputMaybe<Scalars['Int']>;
};


export type QueryWorkflowRolesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  ownerId: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type QueryWorkflowsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};

export enum RansackDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type RansackFilterType = {
  /** Grouping */
  m?: InputMaybe<RansackGrouping>;
  /** Filters */
  q?: InputMaybe<Array<RansackMatcher>>;
};

export enum RansackGrouping {
  And = 'AND',
  Or = 'OR'
}

export type RansackMatcher = {
  /** Filter matcher */
  matcher?: InputMaybe<RansackPredicate>;
  /** Field to filter */
  property: Scalars['String'];
  /** Property description */
  text?: InputMaybe<Scalars['String']>;
  /** Property type */
  type?: InputMaybe<Scalars['String']>;
  /** Value to be matched */
  value: Scalars['RansackValue'];
};

export enum RansackPredicate {
  Blank = 'BLANK',
  Cont = 'CONT',
  ContAll = 'CONT_ALL',
  ContAny = 'CONT_ANY',
  DoesNotMatch = 'DOES_NOT_MATCH',
  DoesNotMatchAll = 'DOES_NOT_MATCH_ALL',
  DoesNotMatchAny = 'DOES_NOT_MATCH_ANY',
  End = 'END',
  EndAll = 'END_ALL',
  EndAny = 'END_ANY',
  Eq = 'EQ',
  EqAll = 'EQ_ALL',
  EqAny = 'EQ_ANY',
  False = 'FALSE',
  Fuzzy = 'FUZZY',
  Gt = 'GT',
  Gteq = 'GTEQ',
  GteqAll = 'GTEQ_ALL',
  GteqAny = 'GTEQ_ANY',
  GtAll = 'GT_ALL',
  GtAny = 'GT_ANY',
  In = 'IN',
  InAll = 'IN_ALL',
  InAny = 'IN_ANY',
  Lt = 'LT',
  Lteq = 'LTEQ',
  LteqAll = 'LTEQ_ALL',
  LteqAny = 'LTEQ_ANY',
  LtAll = 'LT_ALL',
  LtAny = 'LT_ANY',
  Matches = 'MATCHES',
  MatchesAll = 'MATCHES_ALL',
  MatchesAny = 'MATCHES_ANY',
  NotCont = 'NOT_CONT',
  NotContAll = 'NOT_CONT_ALL',
  NotContAny = 'NOT_CONT_ANY',
  NotEnd = 'NOT_END',
  NotEndAll = 'NOT_END_ALL',
  NotEndAny = 'NOT_END_ANY',
  NotEq = 'NOT_EQ',
  NotEqAll = 'NOT_EQ_ALL',
  NotEqAny = 'NOT_EQ_ANY',
  NotFalse = 'NOT_FALSE',
  NotIn = 'NOT_IN',
  NotInAll = 'NOT_IN_ALL',
  NotInAny = 'NOT_IN_ANY',
  NotNull = 'NOT_NULL',
  NotStart = 'NOT_START',
  NotStartAll = 'NOT_START_ALL',
  NotStartAny = 'NOT_START_ANY',
  NotTrue = 'NOT_TRUE',
  Null = 'NULL',
  Present = 'PRESENT',
  QuarterEquals = 'QUARTER_EQUALS',
  Start = 'START',
  StartAll = 'START_ALL',
  StartAny = 'START_ANY',
  TimeFrom = 'TIME_FROM',
  TimeTo = 'TIME_TO',
  True = 'TRUE'
}

export type RansackSortType = {
  /** Sort direction */
  direction: RansackDirection;
  /** Field to sort */
  property: Scalars['String'];
  /** Property description */
  text?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of ResetPasswordMutation */
export type ResetPasswordMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};

/** Autogenerated return type of ResetPasswordMutation */
export type ResetPasswordMutationPayload = {
  __typename?: 'ResetPasswordMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  user: User;
};

export type ResourceType = {
  __typename?: 'ResourceType';
  accessScope: Maybe<Scope>;
  /** Created At */
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  /** Features */
  features: Maybe<FeatureConnection>;
  /** Groups */
  groups: Maybe<GroupConnection>;
  /** ID */
  id: Scalars['Int'];
  /** Name */
  name: Maybe<Scalars['String']>;
  objectPermissions: ObjectPermission;
  /** Permissions */
  permissions: Maybe<PermissionConnection>;
  /** Persona Roles */
  personaRoles: Maybe<PersonaRoleConnection>;
  /** Personas */
  personas: Maybe<PersonaConnection>;
  resourceScope: Maybe<Scalars['String']>;
  /** Roles */
  roles: Maybe<RoleConnection>;
  /** Type */
  type: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** Users */
  users: Maybe<UserConnection>;
};


export type ResourceTypeFeaturesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type ResourceTypeGroupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type ResourceTypePermissionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type ResourceTypePersonaRolesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type ResourceTypePersonasArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type ResourceTypeRolesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type ResourceTypeUsersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};

/** The connection type for ResourceType. */
export type ResourceTypeConnection = {
  __typename?: 'ResourceTypeConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ResourceTypeEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ResourceType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ResourceTypeEdge = {
  __typename?: 'ResourceTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<ResourceType>;
};

export type Role = {
  __typename?: 'Role';
  /** activities */
  activities: Maybe<ActivityConnection>;
  /** Created At */
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  /** ID */
  id: Scalars['Int'];
  /** Is For Workflow */
  isForWorkflow: Maybe<Scalars['Boolean']>;
  /** Name */
  name: Maybe<Scalars['String']>;
  objectPermissions: ObjectPermission;
  /** Owner */
  owner: Maybe<Organisation>;
  /** Owner ID */
  ownerId: Maybe<Scalars['Int']>;
  /** Permission */
  permission: Maybe<Permission>;
  /** Permissions */
  permissions: Maybe<PermissionConnection>;
  /** Persona Role */
  personaRole: Maybe<PersonaRole>;
  /** Persona Roles */
  personaRoles: Maybe<PersonaRoleConnection>;
  /** Persona Users */
  personaUsers: Maybe<PersonaUserConnection>;
  /** Personas */
  personas: Maybe<PersonaConnection>;
  /** Resource Types */
  resourceTypes: Maybe<ResourceTypeConnection>;
  /** Role User */
  roleUser: Maybe<RoleUser>;
  /** Role Users */
  roleUsers: Maybe<RoleUserConnection>;
  /** Updated At */
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** Users */
  users: Maybe<UserConnection>;
};


export type RoleActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type RoleOwnerArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type RolePermissionArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type RolePermissionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type RolePersonaRoleArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type RolePersonaRolesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type RolePersonaUsersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type RolePersonasArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type RoleResourceTypesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type RoleRoleUserArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type RoleRoleUsersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type RoleUsersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};

/** The connection type for Role. */
export type RoleConnection = {
  __typename?: 'RoleConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<RoleEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Role>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RoleEdge = {
  __typename?: 'RoleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Role>;
};

export type RoleUser = {
  __typename?: 'RoleUser';
  /** activities */
  activities: Maybe<ActivityConnection>;
  /** Created At */
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  /** ID */
  id: Scalars['Int'];
  objectPermissions: ObjectPermission;
  /** Role */
  role: Maybe<Role>;
  /** Role ID */
  roleId: Maybe<Scalars['Int']>;
  /** Updated At */
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** User */
  user: Maybe<User>;
  /** User ID */
  userId: Maybe<Scalars['Int']>;
};


export type RoleUserActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type RoleUserRoleArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type RoleUserUserArgs = {
  id: InputMaybe<Scalars['Int']>;
};

/** The connection type for RoleUser. */
export type RoleUserConnection = {
  __typename?: 'RoleUserConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<RoleUserEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<RoleUser>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RoleUserEdge = {
  __typename?: 'RoleUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<RoleUser>;
};

export type Scope = {
  __typename?: 'Scope';
  name: Maybe<Scalars['String']>;
  region: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
};

/** Autogenerated input type of SignInMutation */
export type SignInMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SignInMutation */
export type SignInMutationPayload = {
  __typename?: 'SignInMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  token: Scalars['String'];
};

/** Autogenerated input type of SignOutMutation */
export type SignOutMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SignOutMutation */
export type SignOutMutationPayload = {
  __typename?: 'SignOutMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  user: Maybe<User>;
};

/** Autogenerated input type of UpdateGroupMutation */
export type UpdateGroupMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Name */
  name?: InputMaybe<Scalars['String']>;
  /** Parent ID */
  parentId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateGroupMutation */
export type UpdateGroupMutationPayload = {
  __typename?: 'UpdateGroupMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  group: Maybe<Group>;
};

/** Autogenerated input type of UpdatePermissionMutation */
export type UpdatePermissionMutationInput = {
  /** Attribute Protection */
  attributeProtection?: InputMaybe<Scalars['JSON']>;
  /** Blacklisted Attributes */
  blacklistedAttributes?: InputMaybe<Scalars['String']>;
  /** Can Also */
  canAlso?: InputMaybe<Scalars['JSON']>;
  /** Can Create */
  canCreate?: InputMaybe<Scalars['Boolean']>;
  /** Can Delete */
  canDelete?: InputMaybe<Scalars['Boolean']>;
  /** Can Read */
  canRead?: InputMaybe<Scalars['Boolean']>;
  /** Can Update */
  canUpdate?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Pesource Type ID */
  resourceTypeId?: InputMaybe<Scalars['Int']>;
  /** Role ID */
  roleId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdatePermissionMutation */
export type UpdatePermissionMutationPayload = {
  __typename?: 'UpdatePermissionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  permission: Maybe<Permission>;
};

/** Autogenerated input type of UpdatePersonaMutation */
export type UpdatePersonaMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** description */
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Name */
  name?: InputMaybe<Scalars['String']>;
  /** whatfix_enabled */
  whatfixEnabled?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdatePersonaMutation */
export type UpdatePersonaMutationPayload = {
  __typename?: 'UpdatePersonaMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  persona: Maybe<Persona>;
};

/** Autogenerated input type of UpdatePreferenceMutation */
export type UpdatePreferenceMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Context ID */
  contextId?: InputMaybe<Scalars['Int']>;
  /** Context Type */
  contextType?: InputMaybe<Scalars['String']>;
  /** Key */
  key?: InputMaybe<Scalars['String']>;
  /** Value */
  value?: InputMaybe<Scalars['JSON']>;
};

/** Autogenerated return type of UpdatePreferenceMutation */
export type UpdatePreferenceMutationPayload = {
  __typename?: 'UpdatePreferenceMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  preference: Maybe<Preference>;
};

/** Autogenerated input type of UpdateResourceTypeMutation */
export type UpdateResourceTypeMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Name */
  name?: InputMaybe<Scalars['String']>;
  /** Type */
  type?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateResourceTypeMutation */
export type UpdateResourceTypeMutationPayload = {
  __typename?: 'UpdateResourceTypeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  resourceType: Maybe<ResourceType>;
};

/** Autogenerated input type of UpdateRoleMutation */
export type UpdateRoleMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Is For Workflow */
  isForWorkflow?: InputMaybe<Scalars['Boolean']>;
  /** Name */
  name?: InputMaybe<Scalars['String']>;
  /** Owner ID */
  ownerId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateRoleMutation */
export type UpdateRoleMutationPayload = {
  __typename?: 'UpdateRoleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  role: Maybe<Role>;
};

/** Autogenerated input type of UpdateUserMutation */
export type UpdateUserMutationInput = {
  /** allow_password_change */
  allowPasswordChange?: InputMaybe<Scalars['Boolean']>;
  /** Auto Send Purchase Order */
  autoSendPurchaseOrder?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Current Sign In At */
  currentSignInAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Current Sign In IP */
  currentSignInIp?: InputMaybe<Scalars['String']>;
  /** Default Organisation ID */
  defaultOrganisationId?: InputMaybe<Scalars['Int']>;
  /** Email */
  email?: InputMaybe<Scalars['String']>;
  /** enforce_buy_list */
  enforceBuyList?: InputMaybe<Scalars['Boolean']>;
  /** Full Name */
  fullName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** invitation_accepted_at */
  invitationAcceptedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** invitation_created_at */
  invitationCreatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** invitation_limit */
  invitationLimit?: InputMaybe<Scalars['Int']>;
  /** invitation_sent_at */
  invitationSentAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** invitation_token */
  invitationToken?: InputMaybe<Scalars['String']>;
  /** Is Deleted */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Last Sign In At */
  lastSignInAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Last Sign In IP */
  lastSignInIp?: InputMaybe<Scalars['String']>;
  /** Locked At */
  lockedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Mobile Phone */
  mobilePhone?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  /** Password Changed At */
  passwordChangedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Position */
  position?: InputMaybe<Scalars['String']>;
  /** Provider */
  provider?: InputMaybe<Scalars['String']>;
  /** save_sort_preferences */
  saveSortPreferences?: InputMaybe<Scalars['Boolean']>;
  /** Sign In Count */
  signInCount?: InputMaybe<Scalars['Int']>;
  /** Staff Code */
  staffCode?: InputMaybe<Scalars['String']>;
  /** Telephone */
  telephone?: InputMaybe<Scalars['String']>;
  /** UID */
  uid?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateUserMutation */
export type UpdateUserMutationPayload = {
  __typename?: 'UpdateUserMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  user: Maybe<User>;
};

/** Autogenerated input type of UpdateUserOrganisationMutation */
export type UpdateUserOrganisationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** notification */
  notification?: InputMaybe<Scalars['Boolean']>;
  /** organisation_id */
  organisationId?: InputMaybe<Scalars['Int']>;
  /** User ID */
  userId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateUserOrganisationMutation */
export type UpdateUserOrganisationMutationPayload = {
  __typename?: 'UpdateUserOrganisationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  userOrganisation: Maybe<UserOrganisation>;
};

/** Autogenerated input type of UpdateWorkflowMutation */
export type UpdateWorkflowMutationInput = {
  /** Approval In Ranked Order */
  approvalInRankedOrder?: InputMaybe<Scalars['Boolean']>;
  /** Assignee Type */
  assigneeType?: InputMaybe<Scalars['String']>;
  /** auto_approve */
  autoApprove?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Event */
  event?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Is Deleted */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Owner ID */
  ownerId?: InputMaybe<Scalars['Int']>;
  /** Pesource Type ID */
  resourceTypeId?: InputMaybe<Scalars['Int']>;
  /** skip_approval_if_over_max_value */
  skipApprovalIfOverMaxValue?: InputMaybe<Scalars['Boolean']>;
  /** Title */
  title?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateWorkflowMutation */
export type UpdateWorkflowMutationPayload = {
  __typename?: 'UpdateWorkflowMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  workflow: Maybe<Workflow>;
};

/** Autogenerated input type of UpdateWorkflowRankMembershipMutation */
export type UpdateWorkflowRankMembershipMutationInput = {
  /** approval_workflow_id */
  approvalWorkflowId?: InputMaybe<Scalars['Int']>;
  /** approval_workflow_rank_id */
  approvalWorkflowRankId?: InputMaybe<Scalars['Int']>;
  /** assignee_id */
  assigneeId?: InputMaybe<Scalars['Int']>;
  /** Assignee Type */
  assigneeType?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** is_compulsory */
  isCompulsory?: InputMaybe<Scalars['Boolean']>;
  /** Role ID */
  roleId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateWorkflowRankMembershipMutation */
export type UpdateWorkflowRankMembershipMutationPayload = {
  __typename?: 'UpdateWorkflowRankMembershipMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  workflowRankMembership: Maybe<WorkflowRankMembership>;
};

/** Autogenerated input type of UpdateWorkflowRankMutation */
export type UpdateWorkflowRankMutationInput = {
  /** approval_workflow_id */
  approvalWorkflowId?: InputMaybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** is_unlimited */
  isUnlimited?: InputMaybe<Scalars['Boolean']>;
  /** maximum_value */
  maximumValue?: InputMaybe<Scalars['Float']>;
  /** minimum_approvers */
  minimumApprovers?: InputMaybe<Scalars['Int']>;
  /** rank */
  rank?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateWorkflowRankMutation */
export type UpdateWorkflowRankMutationPayload = {
  __typename?: 'UpdateWorkflowRankMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  workflowRank: Maybe<WorkflowRank>;
};

/** Autogenerated input type of UpdateWorkflowRoleMutation */
export type UpdateWorkflowRoleMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Is For Workflow */
  isForWorkflow?: InputMaybe<Scalars['Boolean']>;
  /** Name */
  name?: InputMaybe<Scalars['String']>;
  /** Owner ID */
  ownerId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateWorkflowRoleMutation */
export type UpdateWorkflowRoleMutationPayload = {
  __typename?: 'UpdateWorkflowRoleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  workflowRole: Maybe<WorkflowRole>;
};

export type User = {
  __typename?: 'User';
  /** access_api_keys */
  accessApiKeys: Maybe<ApiKeyConnection>;
  /** activities */
  activities: Maybe<ActivityConnection>;
  /** allow_password_change */
  allowPasswordChange: Maybe<Scalars['Boolean']>;
  /** api_key */
  apiKey: Maybe<ApiKey>;
  /** api_keys */
  apiKeys: Maybe<ApiKeyConnection>;
  /** Auto Send Purchase Order */
  autoSendPurchaseOrder: Maybe<Scalars['Boolean']>;
  /** Created At */
  createdAt: Scalars['ISO8601DateTime'];
  /** Current Sign In At */
  currentSignInAt: Maybe<Scalars['ISO8601DateTime']>;
  /** Current Sign In IP */
  currentSignInIp: Maybe<Scalars['String']>;
  /** Default Organisation */
  defaultOrganisation: Maybe<Organisation>;
  /** Default Organisation ID */
  defaultOrganisationId: Maybe<Scalars['Int']>;
  /** Email */
  email: Scalars['String'];
  /** enforce_buy_list */
  enforceBuyList: Maybe<Scalars['Boolean']>;
  /** Full Name */
  fullName: Scalars['String'];
  /** Groups */
  groups: Maybe<GroupConnection>;
  /** ID */
  id: Scalars['Int'];
  /** invitation_accepted_at */
  invitationAcceptedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** invitation_created_at */
  invitationCreatedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** invitation_limit */
  invitationLimit: Maybe<Scalars['Int']>;
  /** invitation_sent_at */
  invitationSentAt: Maybe<Scalars['ISO8601DateTime']>;
  /** invitation_token */
  invitationToken: Maybe<Scalars['String']>;
  isAdmin: Maybe<Scalars['Boolean']>;
  /** Is Deleted */
  isDeleted: Maybe<Scalars['Boolean']>;
  /** Last Sign In At */
  lastSignInAt: Maybe<Scalars['ISO8601DateTime']>;
  /** Last Sign In IP */
  lastSignInIp: Maybe<Scalars['String']>;
  /** Locked At */
  lockedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** Mobile Phone */
  mobilePhone: Maybe<Scalars['String']>;
  objectPermissions: ObjectPermission;
  /** Organisations */
  organisations: Maybe<OrganisationConnection>;
  /** Password Changed At */
  passwordChangedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** Permissions */
  permissions: Maybe<PermissionConnection>;
  /** Persona Groups */
  personaGroups: Maybe<PersonaGroupConnection>;
  /** Persona Roles */
  personaRoles: Maybe<PersonaRoleConnection>;
  /** Persona Users */
  personaUsers: Maybe<PersonaUserConnection>;
  /** Personas */
  personas: Maybe<PersonaConnection>;
  /** Position */
  position: Maybe<Scalars['String']>;
  /** Preferences */
  preferences: Maybe<PreferenceConnection>;
  /** Provider */
  provider: Scalars['String'];
  /** Resource Types */
  resourceTypes: Maybe<ResourceTypeConnection>;
  /** Role Users */
  roleUsers: Maybe<RoleUserConnection>;
  /** Roles */
  roles: Maybe<RoleConnection>;
  /** save_sort_preferences */
  saveSortPreferences: Maybe<Scalars['Boolean']>;
  /** Sign In Count */
  signInCount: Scalars['Int'];
  /** Staff Code */
  staffCode: Maybe<Scalars['String']>;
  /** Telephone */
  telephone: Maybe<Scalars['String']>;
  /** UID */
  uid: Scalars['String'];
  /** Updated At */
  updatedAt: Scalars['ISO8601DateTime'];
  /** user_organisation */
  userOrganisation: Maybe<UserOrganisation>;
  /** user_organisations */
  userOrganisations: Maybe<UserOrganisationConnection>;
  /** whatfix_personas */
  whatfixPersonas: Maybe<PersonaConnection>;
};


export type UserAccessApiKeysArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type UserActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type UserApiKeyArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type UserApiKeysArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type UserDefaultOrganisationArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type UserGroupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type UserOrganisationsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type UserPermissionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type UserPersonaGroupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type UserPersonaRolesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type UserPersonaUsersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type UserPersonasArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type UserPreferencesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type UserResourceTypesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type UserRoleUsersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type UserRolesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type UserUserOrganisationArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type UserUserOrganisationsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type UserWhatfixPersonasArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<User>;
};

export type UserOrganisation = {
  __typename?: 'UserOrganisation';
  /** activities */
  activities: Maybe<ActivityConnection>;
  /** Created At */
  createdAt: Scalars['ISO8601DateTime'];
  /** ID */
  id: Scalars['Int'];
  /** notification */
  notification: Maybe<Scalars['Boolean']>;
  objectPermissions: ObjectPermission;
  /** organisation */
  organisation: Maybe<Organisation>;
  /** organisation_id */
  organisationId: Scalars['Int'];
  /** Updated At */
  updatedAt: Scalars['ISO8601DateTime'];
  /** User */
  user: Maybe<User>;
  /** User ID */
  userId: Scalars['Int'];
};


export type UserOrganisationActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type UserOrganisationOrganisationArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type UserOrganisationUserArgs = {
  id: InputMaybe<Scalars['Int']>;
};

/** The connection type for UserOrganisation. */
export type UserOrganisationConnection = {
  __typename?: 'UserOrganisationConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UserOrganisationEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<UserOrganisation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserOrganisationEdge = {
  __typename?: 'UserOrganisationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<UserOrganisation>;
};

/** Autogenerated input type of ValidateResetPasswordTokenMutation */
export type ValidateResetPasswordTokenMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  resetPasswordToken: Scalars['String'];
};

/** Autogenerated return type of ValidateResetPasswordTokenMutation */
export type ValidateResetPasswordTokenMutationPayload = {
  __typename?: 'ValidateResetPasswordTokenMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Maybe<Array<BaseError>>;
  fullName: Scalars['String'];
};

export type Workflow = {
  __typename?: 'Workflow';
  /** activities */
  activities: Maybe<ActivityConnection>;
  /** Approval In Ranked Order */
  approvalInRankedOrder: Maybe<Scalars['Boolean']>;
  /** Assignee Type */
  assigneeType: Maybe<Scalars['String']>;
  /** auto_approve */
  autoApprove: Maybe<Scalars['Boolean']>;
  /** Created At */
  createdAt: Scalars['ISO8601DateTime'];
  /** Event */
  event: Maybe<Scalars['String']>;
  /** ID */
  id: Scalars['Int'];
  /** Is Deleted */
  isDeleted: Maybe<Scalars['Boolean']>;
  /** next_approvers */
  nextApprovers: Maybe<WorkflowNextApproverConnection>;
  objectPermissions: ObjectPermission;
  /** Owner */
  owner: Maybe<Organisation>;
  /** Owner ID */
  ownerId: Scalars['Int'];
  /** ranks */
  ranks: Maybe<WorkflowRankConnection>;
  /** Resource Type */
  resourceType: Maybe<ResourceType>;
  /** Resource Type ID */
  resourceTypeId: Maybe<Scalars['Int']>;
  /** skip_approval_if_over_max_value */
  skipApprovalIfOverMaxValue: Maybe<Scalars['Boolean']>;
  /** Title */
  title: Scalars['String'];
  /** Updated At */
  updatedAt: Scalars['ISO8601DateTime'];
  /** workflow_log */
  workflowLog: Maybe<WorkflowLog>;
  /** workflow_logs */
  workflowLogs: Maybe<WorkflowLogConnection>;
  /** workflow_next_approver */
  workflowNextApprover: Maybe<WorkflowNextApprover>;
  /** workflow_next_approvers */
  workflowNextApprovers: Maybe<WorkflowNextApproverConnection>;
  /** workflow_rank */
  workflowRank: Maybe<WorkflowRank>;
  /** workflow_ranks */
  workflowRanks: Maybe<WorkflowRankConnection>;
};


export type WorkflowActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowNextApproversArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowOwnerArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type WorkflowRanksArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowResourceTypeArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type WorkflowWorkflowLogArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type WorkflowWorkflowLogsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowWorkflowNextApproverArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type WorkflowWorkflowNextApproversArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowWorkflowRankArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type WorkflowWorkflowRanksArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};

/** The connection type for Workflow. */
export type WorkflowConnection = {
  __typename?: 'WorkflowConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<WorkflowEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Workflow>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type WorkflowEdge = {
  __typename?: 'WorkflowEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Workflow>;
};

export type WorkflowLog = {
  __typename?: 'WorkflowLog';
  /** activities */
  activities: Maybe<ActivityConnection>;
  /** agent */
  agent: Maybe<User>;
  /** agent_id */
  agentId: Scalars['Int'];
  /** Created At */
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  /** ID */
  id: Scalars['Int'];
  /** membership */
  membership: Maybe<WorkflowRankMembership>;
  /** membership_id */
  membershipId: Scalars['Int'];
  objectPermissions: ObjectPermission;
  /** target_id */
  targetId: Scalars['Int'];
  /** target_type */
  targetType: Scalars['String'];
  /** Updated At */
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
};


export type WorkflowLogActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowLogAgentArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type WorkflowLogMembershipArgs = {
  id: InputMaybe<Scalars['Int']>;
};

/** The connection type for WorkflowLog. */
export type WorkflowLogConnection = {
  __typename?: 'WorkflowLogConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<WorkflowLogEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<WorkflowLog>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type WorkflowLogEdge = {
  __typename?: 'WorkflowLogEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<WorkflowLog>;
};

export type WorkflowNextApprover = {
  __typename?: 'WorkflowNextApprover';
  /** activities */
  activities: Maybe<ActivityConnection>;
  /** agent */
  agent: Maybe<User>;
  /** agent_id */
  agentId: Maybe<Scalars['Int']>;
  /** Created At */
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  /** ID */
  id: Scalars['Int'];
  objectPermissions: ObjectPermission;
  /** target_id */
  targetId: Maybe<Scalars['Int']>;
  /** target_type */
  targetType: Maybe<Scalars['String']>;
  /** Updated At */
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** Workflow */
  workflow: Maybe<Workflow>;
  /** workflow_id */
  workflowId: Maybe<Scalars['Int']>;
};


export type WorkflowNextApproverActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowNextApproverAgentArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type WorkflowNextApproverWorkflowArgs = {
  id: InputMaybe<Scalars['Int']>;
};

/** The connection type for WorkflowNextApprover. */
export type WorkflowNextApproverConnection = {
  __typename?: 'WorkflowNextApproverConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<WorkflowNextApproverEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<WorkflowNextApprover>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type WorkflowNextApproverEdge = {
  __typename?: 'WorkflowNextApproverEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<WorkflowNextApprover>;
};

export type WorkflowRank = {
  __typename?: 'WorkflowRank';
  /** activities */
  activities: Maybe<ActivityConnection>;
  /** approval_workflow_id */
  approvalWorkflowId: Scalars['Int'];
  /** Created At */
  createdAt: Scalars['ISO8601DateTime'];
  /** ID */
  id: Scalars['Int'];
  /** is_unlimited */
  isUnlimited: Maybe<Scalars['Boolean']>;
  /** maximum_value */
  maximumValue: Maybe<Scalars['Float']>;
  /** memberships */
  memberships: Maybe<WorkflowRankMembershipConnection>;
  /** minimum_approvers */
  minimumApprovers: Maybe<Scalars['Int']>;
  objectPermissions: ObjectPermission;
  /** rank */
  rank: Scalars['Int'];
  /** Roles */
  roles: Maybe<RoleConnection>;
  /** Updated At */
  updatedAt: Scalars['ISO8601DateTime'];
  /** Workflow */
  workflow: Maybe<Workflow>;
  /** workflow_rank_membership */
  workflowRankMembership: Maybe<WorkflowRankMembership>;
  /** workflow_rank_memberships */
  workflowRankMemberships: Maybe<WorkflowRankMembershipConnection>;
};


export type WorkflowRankActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowRankMembershipsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowRankRolesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowRankWorkflowArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type WorkflowRankWorkflowRankMembershipArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type WorkflowRankWorkflowRankMembershipsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};

/** The connection type for WorkflowRank. */
export type WorkflowRankConnection = {
  __typename?: 'WorkflowRankConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<WorkflowRankEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<WorkflowRank>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type WorkflowRankEdge = {
  __typename?: 'WorkflowRankEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<WorkflowRank>;
};

export type WorkflowRankMembership = {
  __typename?: 'WorkflowRankMembership';
  /** activities */
  activities: Maybe<ActivityConnection>;
  /** approval_workflow_id */
  approvalWorkflowId: Maybe<Scalars['Int']>;
  /** approval_workflow_rank_id */
  approvalWorkflowRankId: Scalars['Int'];
  /** assignee_id */
  assigneeId: Maybe<Scalars['Int']>;
  /** Assignee Type */
  assigneeType: Maybe<Scalars['String']>;
  /** Created At */
  createdAt: Scalars['ISO8601DateTime'];
  /** ID */
  id: Scalars['Int'];
  /** is_compulsory */
  isCompulsory: Maybe<Scalars['Boolean']>;
  /** logs */
  logs: Maybe<WorkflowLogConnection>;
  objectPermissions: ObjectPermission;
  /** rank */
  rank: Maybe<WorkflowRank>;
  /** Role */
  role: Maybe<WorkflowRole>;
  /** Role ID */
  roleId: Maybe<Scalars['Int']>;
  /** Updated At */
  updatedAt: Scalars['ISO8601DateTime'];
  /** Workflow */
  workflow: Maybe<Workflow>;
  /** workflow_logs */
  workflowLogs: Maybe<WorkflowLogConnection>;
};


export type WorkflowRankMembershipActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowRankMembershipLogsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowRankMembershipRankArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type WorkflowRankMembershipRoleArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type WorkflowRankMembershipWorkflowArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type WorkflowRankMembershipWorkflowLogsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};

/** The connection type for WorkflowRankMembership. */
export type WorkflowRankMembershipConnection = {
  __typename?: 'WorkflowRankMembershipConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<WorkflowRankMembershipEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<WorkflowRankMembership>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type WorkflowRankMembershipEdge = {
  __typename?: 'WorkflowRankMembershipEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<WorkflowRankMembership>;
};

export type WorkflowRole = {
  __typename?: 'WorkflowRole';
  /** activities */
  activities: Maybe<ActivityConnection>;
  /** Created At */
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  /** ID */
  id: Scalars['Int'];
  /** Is For Workflow */
  isForWorkflow: Maybe<Scalars['Boolean']>;
  /** Name */
  name: Maybe<Scalars['String']>;
  objectPermissions: ObjectPermission;
  /** Owner */
  owner: Maybe<Organisation>;
  /** Owner ID */
  ownerId: Maybe<Scalars['Int']>;
  /** Permissions */
  permissions: Maybe<PermissionConnection>;
  /** Persona Roles */
  personaRoles: Maybe<PersonaRoleConnection>;
  /** Persona Users */
  personaUsers: Maybe<PersonaUserConnection>;
  /** Personas */
  personas: Maybe<PersonaConnection>;
  /** Resource Types */
  resourceTypes: Maybe<ResourceTypeConnection>;
  /** Role Users */
  roleUsers: Maybe<RoleUserConnection>;
  /** Updated At */
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** Users */
  users: Maybe<UserConnection>;
  /** workflow_rank_memberships */
  workflowRankMemberships: Maybe<WorkflowRankMembershipConnection>;
  /** workflow_ranks */
  workflowRanks: Maybe<WorkflowRankConnection>;
  /** Workflows */
  workflows: Maybe<WorkflowConnection>;
};


export type WorkflowRoleActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowRoleOwnerArgs = {
  id: InputMaybe<Scalars['Int']>;
};


export type WorkflowRolePermissionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowRolePersonaRolesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowRolePersonaUsersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowRolePersonasArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowRoleResourceTypesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowRoleRoleUsersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowRoleUsersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowRoleWorkflowRankMembershipsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowRoleWorkflowRanksArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};


export type WorkflowRoleWorkflowsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<RansackFilterType>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Array<RansackSortType>>;
};

/** The connection type for WorkflowRole. */
export type WorkflowRoleConnection = {
  __typename?: 'WorkflowRoleConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<WorkflowRoleEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<WorkflowRole>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type WorkflowRoleEdge = {
  __typename?: 'WorkflowRoleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<WorkflowRole>;
};

export type SignOutMutationVariables = Exact<{
  input: SignOutMutationInput;
}>;


export type SignOutMutation = { __typename?: 'Mutation', signOut: { __typename?: 'SignOutMutationPayload', user: { __typename?: 'User', fullName: string, email: string, id: number } | null } | null };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: number, email: string, fullName: string, isAdmin: boolean | null } | null };


export const SignOutDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SignOut"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SignOutMutationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"signOut"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<SignOutMutation, SignOutMutationVariables>;
export const GetCurrentUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCurrentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"isAdmin"}}]}}]}}]} as unknown as DocumentNode<GetCurrentUserQuery, GetCurrentUserQueryVariables>;