import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Dispatch, Fragment, SetStateAction } from 'react'

import { Themes } from '@/modules/requisitions/types'

interface Props {
  title: string
  description: string
  mainButtonLabel: string
  secondaryButtonLabel?: string
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  onConfirm: () => void
  theme: Themes
  testId?: string
}

export default function ConfirmDialog({
  title,
  description,
  mainButtonLabel,
  secondaryButtonLabel,
  isOpen,
  setIsOpen,
  onConfirm,
  theme,
  testId,
}: Props) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900" data-testid={testId}>
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{description}</p>
                  </div>

                  <div className="mt-4 flex justify-end">
                    {secondaryButtonLabel && (
                      <button
                        type="button"
                        className="mr-2 inline-flex justify-center rounded-md border bg-white px-4 py-2 text-sm font-medium text-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-offset-2"
                        onClick={() => setIsOpen(false)}
                      >
                        {secondaryButtonLabel}
                      </button>
                    )}
                    <button
                      type="button"
                      className={classNames(
                        'inline-flex justify-center rounded-md border px-4 py-2 text-sm font-medium hover:opacity-95 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
                        {
                          'bg-primary text-white focus-visible:ring-primary': theme === Themes.success,
                          'bg-error text-white focus-visible:ring-error': theme === Themes.error,
                        }
                      )}
                      onClick={onConfirm}
                    >
                      {mainButtonLabel}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
