import { Combobox } from '@headlessui/react'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'

import { ComboboxBaseProps } from '@/modules/shared/components/combobox/types'
import { useDebounce } from '@/modules/shared/hooks'
import { CheckAltIcon, ChevronDownIcon } from '@/modules/shared/icons'
import { Loading } from '@/modules/shared/icons'

interface ComboboxServerProps<DataType> extends ComboboxBaseProps<DataType> {
  onDisplay: (e: DataType) => string
  searchLoading?: boolean
  fetchMoreLoading?: boolean
  onInputChange: (value: string) => void
  onFetchMore?: () => void
  hasMore?: boolean
}

function ComboboxServer<T>(props: ComboboxServerProps<T>) {
  const {
    onInputChange,
    keyExtractor,
    onSelected,
    onFetchMore,
    loading,
    searchLoading,
    fetchMoreLoading,
    disabled,
    className,
    placeholder,
    items = [],
    onDisplay,
    hasMore,
    hasError,
    errorMessage,
    testId,
    defaultValue,
  } = props
  const { t } = useTranslation()
  const { ref, inView } = useInView({ threshold: 1 })
  const [query, setQuery] = useState('')
  const [selectedItem, setSelectedItem] = useState<T>()
  const debouncedQuery = useDebounce(query, 500)

  useEffect(() => {
    onInputChange(debouncedQuery)
  }, [debouncedQuery])

  useEffect(() => {
    if (selectedItem) {
      onSelected(selectedItem)
    }
  }, [selectedItem])

  useEffect(() => {
    if (inView && hasMore) {
      onFetchMore && onFetchMore()
    }
  }, [inView, hasMore])

  useEffect(() => {
    if (defaultValue) {
      setSelectedItem(defaultValue)
    }
  }, [defaultValue])

  const getSelectedItem = (item: T) => {
    return item && onDisplay(item)
  }

  const renderItems = () => {
    if (items.length > 0) {
      return items.map((item) => (
        <Combobox.Option
          data-testid={`option${testId ? `-${testId}` : ''}-${keyExtractor(item)}`}
          key={keyExtractor(item)}
          value={item}
          className={({ active }) =>
            classNames('relative cursor-default select-none py-2 pl-3 pr-9', {
              'bg-primary text-white': active,
              'text-gray-900': !active,
            })
          }
        >
          {({ active, selected }) => (
            <>
              <span
                className={classNames('block truncate ', {
                  'font-semibold ': selected,
                })}
              >
                {getSelectedItem(item)}
              </span>

              {selected && (
                <span
                  className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', {
                    'text-white': active,
                    'text-primary': !active,
                  })}
                >
                  <CheckAltIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              )}
            </>
          )}
        </Combobox.Option>
      ))
    }
    return (
      <div
        data-testid={`nothing-found${testId ? `-${testId}` : ''}`}
        className="cursor-default select-none py-2 px-4 text-gray-700"
      >
        <h1>{t('combobox.nothingFound')}</h1>
      </div>
    )
  }

  return (
    <Combobox
      as="div"
      className={classNames(className, 'w-full')}
      disabled={disabled || loading}
      value={selectedItem}
      data-testid={testId}
      onChange={setSelectedItem}
    >
      <div className={classNames('relative', 'mt-1', { 'pb-1': hasError })}>
        <Combobox.Input
          autoComplete="off"
          data-testid={`combobox-input${testId ? `-${testId}` : ''}`}
          className={classNames(
            'w-full rounded-md border border-gray-300 bg-white py-3 pl-3 pr-10 shadow-sm transition focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm',
            {
              'bg-gray-200/50': disabled || loading,
              'text-gray-300': disabled || loading,
              'cursor-not-allowed': disabled,
              'border-error': hasError,
            }
          )}
          placeholder={placeholder}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item: T) => getSelectedItem(item)}
        />
        <Combobox.Button
          data-testid={`combobox-button${testId ? `-${testId}` : ''}`}
          className={classNames('absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none', {
            'cursor-not-allowed': disabled,
          })}
        >
          {loading ? (
            <Loading className="h-5 w-5 fill-white text-gray-300" />
          ) : (
            <ChevronDownIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
          )}
        </Combobox.Button>

        <Combobox.Options
          data-testid={`options-wrapper${testId ? `-${testId}` : ''}`}
          className=" absolute z-10 mt-1 max-h-72  w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-primary/5 focus:outline-none sm:text-sm"
        >
          {searchLoading ? (
            <div className="cursor-default select-none py-2 px-4 text-center text-gray-700">
              <Loading className="h-5 w-5 fill-white text-gray-300" />
            </div>
          ) : (
            renderItems()
          )}

          {fetchMoreLoading && hasMore && (
            <div className="py-2 text-center">
              <Loading className="h-5 w-5 fill-white text-gray-300" />
            </div>
          )}
          {!searchLoading && <div ref={ref} />}
        </Combobox.Options>
      </div>
      {hasError && <span className="text-sm text-error">{errorMessage}</span>}
    </Combobox>
  )
}

export default ComboboxServer
