import { useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useParams } from 'react-router-dom'

import { GetRequisitionDocument, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ApprovalItem } from '@/modules/requisitions/components'
import { useApproveRequisition } from '@/modules/requisitions/hooks'
import SuccessApproveRequisition from '@/modules/requisitions/pages/confirmation/success-approve-requisition'
import SuccessDeclineRequisition from '@/modules/requisitions/pages/confirmation/success-decline-requisition'
import SuccessSubmitRequisition from '@/modules/requisitions/pages/confirmation/success-submit-requisition'
import { REQUISITION_STATUS } from '@/modules/requisitions/types'
import { QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { ChevronLeftIcon } from '@/modules/shared/icons'

export default function RequisitionDetails() {
  const { t } = useTranslation()
  const { requisitionId } = useParams()
  const [approveRequisition, { loading: actionLoading }] = useApproveRequisition()

  const locationState = useLocation().state as { requisitionPageStatus: string }
  const requisitionPageStatus = locationState?.requisitionPageStatus

  const { data, loading, error } = useQuery(GetRequisitionDocument, {
    variables: {
      requisitionId: Number(requisitionId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const onApproveRequisition = (requisitionId: number) => {
    approveRequisition({
      variables: {
        input: {
          id: requisitionId,
        },
      },
    })
  }

  if (requisitionPageStatus === REQUISITION_STATUS.APPROVED) {
    return <SuccessApproveRequisition />
  }

  if (requisitionPageStatus === REQUISITION_STATUS.DECLINED) {
    return <SuccessDeclineRequisition />
  }

  if (requisitionPageStatus === REQUISITION_STATUS.AWAITING_APPROVAL) {
    return <SuccessSubmitRequisition />
  }

  return (
    <>
      <Helmet>
        <title>
          {t('requisition.requisitionDetailsPageTitle', {
            requisitionNumber: data?.currentPurchaser?.requisition?.requisitionNumber,
          })}
        </title>
      </Helmet>
      <div data-testid="approval-list" className="mx-auto w-full py-10 px-4 lg:w-[698px]">
        <QueryResult loading={loading} error={error}>
          <div>
            {requisitionId && data?.currentPurchaser && (
              <>
                <Link
                  data-testid="back-button"
                  to="/requisitions/awaiting-my-approval"
                  className="flex w-fit items-center gap-x-1 rounded-md bg-gray-200 p-2 pr-4 hover:opacity-75"
                >
                  <ChevronLeftIcon className="h-6 w-6" />
                  {t('access.back')}
                </Link>
                <div className="mt-8">
                  <ApprovalItem
                    requisition={data.currentPurchaser.requisition as Requisition}
                    showActions={data.currentPurchaser.requisition?.state === REQUISITION_STATUS.AWAITING_APPROVAL}
                    onApprove={onApproveRequisition}
                    actionLoading={actionLoading}
                  />
                </div>
              </>
            )}
          </div>
        </QueryResult>
      </div>
    </>
  )
}
