import { useTranslation } from 'react-i18next'

import AddItem from '../../AddItem'
import Stepper from '../../Stepper'

import NoImage from '@/assets/images/no-image.svg'
import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import { DEFAULT_CONCATENATED_SELL_UNIT } from '@/modules/requisitions/constants'
import { useMoney } from '@/modules/shared/hooks'
import { titleCase } from '@/modules/shared/utils'

interface ProductCardTileViewProps {
  product: RequisitionLine
  isAddedToCart: boolean
}

function ProductCardTileView(props: ProductCardTileViewProps) {
  const { product, isAddedToCart } = props
  const {
    id,
    productId,
    image,
    productBrand,
    productDescription,
    productItemSize,
    productItemMeasure,
    productItemPackName,
    productSellUnit,
    unitPrice,
    taxPercentage,
    supplier,
    quantity,
  } = product
  const { format } = useMoney()
  const { t } = useTranslation()
  return (
    <div data-testid="product-detail" className="rounded-md border bg-white shadow-sm">
      <div className="flex items-center border-b border-gray-200 p-3 sm:px-5 sm:py-4">
        <div className="mr-5 h-[68px] w-[68px] flex-none border border-gray-200 sm:h-24 sm:w-24">
          <img className="h-full w-full" src={image || NoImage} alt="product" />
        </div>
        <div className="overflow-hidden md:w-64">
          <p className="break-words text-xs leading-5 text-primary sm:text-sm">
            {productBrand} {productDescription}
          </p>
          <span className="text-xxs text-gray-500 sm:text-xs">
            {productItemSize === 1 &&
            productItemMeasure === 'each' &&
            productSellUnit === DEFAULT_CONCATENATED_SELL_UNIT ? (
              <p>{titleCase(productSellUnit || DEFAULT_CONCATENATED_SELL_UNIT)}</p>
            ) : (
              <p>
                {productItemSize}
                {productItemMeasure} {titleCase(productItemPackName || '')}{' '}
                {(productItemSize || productItemMeasure || productItemPackName) && '| '}
                {titleCase(productSellUnit || DEFAULT_CONCATENATED_SELL_UNIT)}
              </p>
            )}
          </span>
          <p className="text-xxs leading-5 sm:text-xs md:truncate">{supplier?.name}</p>
        </div>
      </div>
      <div className="p-3 md:px-5 md:py-4">
        <div className="flex items-center justify-between">
          <span>
            <p className="text-base font-bold leading-tight sm:text-lg">{format(unitPrice || 0)}</p>
            <p className="text-xxs text-gray-500 sm:text-xs">
              {taxPercentage
                ? t('shopPage.productList.productCard.taxPercentage', { percent: taxPercentage })
                : t('shopPage.productList.productCard.taxFree')}
            </p>
          </span>
          <div>
            {isAddedToCart ? (
              <Stepper productId={Number(productId)} lineId={Number(id)} quantity={Number(quantity)} />
            ) : (
              <AddItem productId={Number(productId)} supplierId={Number(supplier?.id)} unitPrice={Number(unitPrice)} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductCardTileView
