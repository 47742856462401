import { useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import ListView from './approval-list/list-view'
import TableView from './approval-list/table-view'

import { GetRequisitionsAwaitingApprovalDocument, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Header } from '@/modules/requisitions/layouts'
import { Breadcrumb, QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { ClockIcon, HomeIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

export default function AwaitingMyApproval() {
  const { t } = useTranslation()

  const { loading, error, data } = useQuery(GetRequisitionsAwaitingApprovalDocument, {
    fetchPolicy: 'cache-and-network',
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const approvalList = extractEdges<Requisition>(data?.currentPurchaser?.requisitionsAwaitingMyApproval)

  return (
    <>
      <Helmet>
        <title>{t('requisition.awaitingApprovalPageTitle')}</title>
      </Helmet>
      <Header />
      <main className="mx-auto max-w-[1800px] space-y-5 p-4">
        <Breadcrumb className="overflow-x-auto rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/"></Breadcrumb.Item>
          <Breadcrumb.Item href="#">{t('breadcrumbs.requisitions')}</Breadcrumb.Item>
          <Breadcrumb.Item>{t('breadcrumbs.awaitingMyApproval')}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="flex rounded-md bg-white p-4 shadow-sm " data-testid="awaiting-my-approval-header">
          <div className="flex items-center">
            <div className="flex h-14 w-14 items-center justify-center rounded-md bg-primary/10">
              <ClockIcon className="h-11 w-11 text-primary" />
            </div>
            <span className="ml-3">
              <p className="font-bold">{t('requisition.numberOfPRsAwaiting', { number: approvalList.length })}</p>
              <p className="text-sm text-gray-500">{t('requisition.awaitingMyApproval')}</p>
            </span>
          </div>
        </section>
        <QueryResult loading={loading} error={error}>
          <section>
            <ListView approvalList={approvalList} />
            <TableView approvalList={approvalList} />
          </section>
        </QueryResult>
      </main>
    </>
  )
}
