import { useEffect } from 'react'
import { Navigate, useParams, useSearchParams } from 'react-router-dom'

import { useApproveRequisition } from '@/modules/requisitions/hooks'

export default function ApproveRequisitionRedirect() {
  const { requisitionId } = useParams()
  const [searchParams] = useSearchParams()
  const [approveRequisition] = useApproveRequisition()

  const isApproveFromEmail = searchParams.get('email') === 'true'

  const onApproveRequisition = (requisitionId: number) => {
    approveRequisition({
      variables: {
        input: {
          id: requisitionId,
        },
      },
    })
  }

  useEffect(() => {
    if (isApproveFromEmail && requisitionId) {
      onApproveRequisition(Number(requisitionId))
    }
  }, [isApproveFromEmail, requisitionId])

  return <Navigate to={`/requisitions/${requisitionId}`} replace />
}
