import { memo, useState } from 'react'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ApprovalItem, EmptyState } from '@/modules/requisitions/components'
import { useApproveRequisition } from '@/modules/requisitions/hooks'

interface ListViewProps {
  approvalList: Requisition[]
}

function ListView(props: ListViewProps) {
  const { approvalList } = props
  const [actionLoadingById, setActionloadingById] = useState<number | null>(null)

  const [approveRequisition, { loading: actionLoading }] = useApproveRequisition()

  const onApprove = (requisitionId: number) => {
    setActionloadingById(requisitionId)
    approveRequisition({
      variables: {
        input: {
          id: requisitionId,
        },
      },
    })
  }

  if (approvalList.length === 0) {
    return <EmptyState />
  }

  return (
    <div className="block lg:hidden">
      {approvalList.map((item) => (
        <ApprovalItem
          key={item.id}
          requisition={item}
          onApprove={onApprove}
          showActions
          showLessDetail
          showRequisitionButton
          showRequisitionExternalButton={false}
          actionLoading={actionLoading && actionLoadingById === item.id}
        />
      ))}
    </div>
  )
}

export default memo(ListView)
