import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { GetRequisitionsAwaitingApprovalDocument, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { ChevronRightIcon, ClockIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

export default function AwaitingMyApproval() {
  const { t } = useTranslation()

  const { data } = useQuery(GetRequisitionsAwaitingApprovalDocument, {
    fetchPolicy: 'cache-and-network',
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const approvalList = extractEdges<Requisition>(data?.currentPurchaser?.requisitionsAwaitingMyApproval)

  return (
    <Link
      className="mt-5 flex cursor-pointer items-center justify-between rounded-md bg-white p-4 shadow"
      to="/requisitions/awaiting-my-approval"
      data-testid="dashboard-awaiting-my-approval"
    >
      <div className="flex items-center">
        <div className="flex h-14 w-14 items-center justify-center rounded-md bg-primary/10">
          <ClockIcon className="h-11 w-11 text-primary" />
        </div>
        <span className="ml-3">
          <p className="font-bold">{t('requisition.numberOfPRsAwaiting', { number: approvalList.length })}</p>
          <p className="text-sm text-gray-500">{t('requisition.awaitingMyApproval')}</p>
        </span>
      </div>
      <ChevronRightIcon className="h-6 text-gray-400" />
    </Link>
  )
}
