import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { NavLink, Outlet } from 'react-router-dom'

import ViewCartHeader from './ViewCartHeader'

import { ShopHeader } from '@/modules/requisitions/layouts'

type Action = {
  target: string
  label: string
}

function HeaderAndCart() {
  const { t } = useTranslation()
  const actionList: Action[] = [
    { target: 'summary', label: t('requisitionInformation.summary.title') },
    // { target: 'comment', label: t('requisitionInformation.comment.title') },
    { target: 'settings', label: t('requisitionInformation.setting.title') },
  ]

  return (
    <>
      <ShopHeader />
      <div className="mx-auto max-w-[1300px] py-12 px-4">
        <ViewCartHeader />
        <div className="my-4 flex flex-col gap-y-2 rounded-md bg-white py-5 px-4 shadow-sm md:flex-row md:gap-2">
          {actionList.map((action) => (
            <NavLink
              key={action.target}
              data-testid={`${action.target}-btn`}
              to={action.target}
              className={({ isActive }) => {
                return classNames('rounded-md bg-gray-100 px-4 py-3 text-sm transition-all hover:brightness-95', {
                  'bg-primary/10 text-primary hover:bg-primary/20': isActive,
                })
              }}
            >
              {action.label}
            </NavLink>
          ))}
        </div>
        <Outlet />
      </div>
    </>
  )
}

export default HeaderAndCart
