import { Trans, useTranslation } from 'react-i18next'

import { Header } from '@/modules/requisitions/layouts'
import { SUPPORT_LINK } from '@/modules/shared/constants'

export default function Home() {
  const { t } = useTranslation()
  return (
    <>
      <Header />
      <div className="mx-auto w-full py-16 px-4 sm:px-8 lg:w-[500px] lg:px-0">
        <div className="flex flex-col items-center">
          <h1 className="my-3 text-center text-xl font-bold">{t('access.signOutDetails.title')}</h1>
          <p className="text-center text-sm text-gray-500">
            <Trans
              t={t}
              i18nKey="access.signOutDetails.message"
              components={[
                <a
                  data-testid="view-support-link"
                  href={SUPPORT_LINK}
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary"
                >
                  {''}
                </a>,
              ]}
            />
          </p>
          <p className="mt-8 text-center text-sm text-gray-500">{t('access.signOutDetails.lookForExistingPPlus')}</p>
          <a
            className="mt-8 w-full cursor-pointer rounded-md bg-gray-200 p-3 text-center text-sm transition hover:brightness-95"
            data-testid="view-home-link"
            href={process.env.REACT_APP_NINJA_API_HOST}
          >
            {t('access.signOutDetails.takeToExistingPPlus')}
          </a>
        </div>
      </div>
    </>
  )
}
