import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

// import { generatePath, Link } from 'react-router-dom'
import Row from './Row'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Themes } from '@/modules/requisitions/types'
import { ConfirmDialog } from '@/modules/shared/components'

interface TableViewProps {
  draftList: Requisition[]
}

function TableView(props: TableViewProps) {
  const { t } = useTranslation()
  const { draftList } = props
  const [isOpen, setIsOpen] = useState(false)

  if (!draftList.length) {
    return (
      <div className="mt-5 rounded-md border py-8 text-center text-sm" data-testid="dashboard-no-drafts">
        {t('dashboard.draftRequisitions.noDrafts')}
      </div>
    )
  }

  return (
    <div className="mt-5 mb-16 rounded-md shadow-md" data-testid="dashboard-draft-requisitions-table">
      {draftList.map((item) => {
        return (
          <div
            key={item?.id}
            className="items-center border-b bg-white p-4 shadow first:rounded-t-md last:rounded-b-md last:border-b-0"
          >
            {/* TODO: Uncomment when drafts can be resumed */}
            {/* <Link
              to={generatePath(`/requisitions/:id/shop`, {
                id: item?.id.toString(),
              })}
              className="flex items-center lg:hidden"
            > */}
            <div className="flex cursor-pointer items-center lg:hidden" onClick={() => setIsOpen(true)}>
              <Row item={item} hide />
            </div>
            {/* </Link> */}
            <div className="hidden items-center lg:flex">
              <Row item={item} />
            </div>
          </div>
        )
      })}
      <ConfirmDialog
        title={t('dashboard.createRequisition.popup.title')}
        description={t('dashboard.createRequisition.popup.body')}
        mainButtonLabel={t('dashboard.createRequisition.popup.confirm')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onConfirm={() => setIsOpen(false)}
        theme={Themes.success}
        testId="create-requisition-coming-soon"
      />
    </div>
  )
}

export default memo(TableView)
