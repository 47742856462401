import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import DashboardImage from '@/assets/images/dashboard-image.svg'
import { useCurrentUser } from '@/modules/access/hooks'
import { Themes } from '@/modules/requisitions/types'
import { Button } from '@/modules/shared/components'
import { ConfirmDialog } from '@/modules/shared/components'
import { PlusIcon } from '@/modules/shared/icons'

export default function CreateRequisition() {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const { currentUser } = useCurrentUser()

  return (
    <>
      <div className="mt-5 flex flex-col justify-between rounded-md bg-white p-6 shadow sm:h-44 sm:flex-row">
        <div className="flex flex-col justify-between gap-y-3">
          <span className="flex flex-col gap-y-2 text-center sm:text-left">
            <p className="text-sm leading-none">
              {t('dashboard.createRequisition.welcome', { user: `${currentUser?.fullName}!` })}{' '}
            </p>
            <p className="font-semibold leading-none">{t('dashboard.createRequisition.startOrder')}</p>
          </span>
          <img className="sm:hidden" src={DashboardImage} alt="" />
          <Button
            data-testid="dashboard-create-new-requisition"
            type="submit"
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center justify-between gap-x-3 rounded-md bg-primary px-5 py-3 text-sm text-white sm:w-fit"
          >
            <span className="">{t('dashboard.createRequisition.actions.create')}</span>
            <PlusIcon className="h-4 w-4" />
          </Button>
          <ConfirmDialog
            title={t('dashboard.createRequisition.popup.title')}
            description={t('dashboard.createRequisition.popup.body')}
            mainButtonLabel={t('dashboard.createRequisition.popup.confirm')}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onConfirm={() => setIsOpen(false)}
            theme={Themes.success}
            testId="create-requisition-coming-soon"
          />
        </div>
        <div className="hidden items-center sm:flex">
          <img className="h-40" src={DashboardImage} alt="" />
        </div>
      </div>
    </>
  )
}
