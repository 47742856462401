import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { GetCurrentUserQuery } from '@/graphql/access/generated/access_graphql'
import { GetCurrentPurchaserQuery } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useSignOut } from '@/modules/access/hooks'
import { Button } from '@/modules/shared/components'
import { NewTabIcon, ShieldIcon, SignOutIcon } from '@/modules/shared/icons'

interface MenuDetailsProps extends PropsWithChildren {
  customStyles?: string
  currentUser: GetCurrentUserQuery['currentUser']
  currentPurchaser: GetCurrentPurchaserQuery['currentPurchaser']
}

export default function MenuDetails({ children, customStyles, currentUser, currentPurchaser }: MenuDetailsProps) {
  const { t } = useTranslation()

  const [signOut, { loading: actionLoading }] = useSignOut()

  return (
    <Menu as="div" className={classNames('relative z-10 inline-block text-left', customStyles || '')}>
      <Menu.Button className="flex h-11 items-center justify-between gap-6 rounded-md bg-gray-100 px-4 py-1 hover:bg-gray-200">
        {children}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-auto origin-top-right divide-y divide-gray-100 rounded-md border bg-white shadow-lg focus:outline-none">
          <div className="py-1" data-testid="menu-details">
            <Menu.Item>
              <div>
                <p className="block px-4 pt-2 text-xxs">{t('access.signedInAs')}</p>
                <p className="block px-4 text-sm">{currentUser?.fullName}</p>
                <p className="block px-4 text-sm">{currentPurchaser?.name}</p>
                <p className="block px-4 pb-2 text-sm font-semibold">{currentUser?.email}</p>
              </div>
            </Menu.Item>
          </div>
          <div className="py-1" data-testid="menu-details-actions">
            {currentUser?.isAdmin && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    className={classNames(
                      'w-full',
                      'px-4',
                      'py-2',
                      'text-left',
                      'text-sm',
                      'flex',
                      'items-center',
                      'text-gray-700',
                      'transition',
                      'cursor-pointer',
                      {
                        'bg-gray-100 text-gray-900': active,
                      }
                    )}
                    href="/admin"
                    rel="noreferrer"
                  >
                    <ShieldIcon className="mr-2 h-5 w-5" />
                    {t('access.adminCenter')}
                  </a>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <a
                  className={classNames(
                    'w-full',
                    'px-4',
                    'py-2',
                    'text-left',
                    'text-sm',
                    'flex',
                    'items-center',
                    'text-gray-700',
                    'transition',
                    {
                      'bg-gray-100 text-gray-900': active,
                    }
                  )}
                  href={process.env.REACT_APP_NINJA_API_HOST}
                  target="_blank"
                  rel="noreferrer"
                >
                  <NewTabIcon className="mr-2 h-5 w-5" />
                  {t('access.goToP+Enterprise')}
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Button
                  loading={actionLoading}
                  className={classNames(
                    'w-full',
                    'px-4',
                    'py-2',
                    'text-left',
                    'text-sm',
                    'flex',
                    'items-center',
                    'text-gray-700',
                    {
                      'bg-gray-100 text-gray-900': active,
                    }
                  )}
                  onClick={() => signOut()}
                >
                  <SignOutIcon className="mr-2 h-5 w-5" />
                  {t('access.signOut')}
                </Button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
