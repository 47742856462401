import { Outlet } from 'react-router-dom'

import { useCurrentPurchaser } from '@/modules/purchasing/hooks'
import { Header } from '@/modules/requisitions/layouts'

function PurchaserRoute() {
  const { currentPurchaser } = useCurrentPurchaser()

  if (currentPurchaser) {
    if (!currentPurchaser.isAPurchaser) return <Header />

    return <Outlet />
  }

  return <Header />
}

export default PurchaserRoute
