import { useApolloClient, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { SignOutDocument } from '@/graphql/access/generated/access_graphql'

export default function useSignOut() {
  const apolloClient = useApolloClient()
  const navigate = useNavigate()

  const clearCacheAndGoHome = async () => {
    await apolloClient.clearStore()
    navigate('/')
  }

  const mutation = useMutation(SignOutDocument, {
    variables: {
      input: {},
    },
    onCompleted() {
      clearCacheAndGoHome()
    },
    onError() {
      // If mutation fails, still clear cache and navigate to root.
      clearCacheAndGoHome()
    },
  })

  return mutation
}
