import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import ProductCardListView from './product-card-list-view'

import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'

interface ListViewProps {
  productList: RequisitionLine[]
  lines: RequisitionLine[]
}

function ListView(props: ListViewProps) {
  const { productList, lines } = props
  const { t } = useTranslation()
  return (
    <table className="w-full text-left text-sm">
      {productList.length > 0 && (
        <thead className="text-xs text-gray-500">
          <tr>
            <th scope="col" className="px-4 py-2 text-center">
              {t('shopPage.productList.productCard.imageTableHeader')}
            </th>
            <th scope="col" className="py-2 pr-4">
              {t('shopPage.productList.productCard.itemTableHeader')}
            </th>
            <th scope="col" className="px-4 py-2">
              {t('shopPage.productList.productCard.supplierTableHeader')}
            </th>
            <th scope="col" className="py-2 px-3 text-right">
              {t('shopPage.productList.productCard.unitPriceTableHeader')}
            </th>
            <th scope="col" className="py-2 pl-3 text-center">
              {t('shopPage.productList.productCard.addItemTableHeader')}
            </th>
            <th scope="col" className="py-2 pr-4 text-center">
              {t('shopPage.productList.productCard.moreTableHeader')}
            </th>
          </tr>
        </thead>
      )}
      <tbody className="border">
        {productList.map((product) => (
          <ProductCardListView
            key={product.productId}
            product={product}
            isAddedToCart={Boolean(lines.find((line) => line.productId === product.productId))}
          />
        ))}
      </tbody>
    </table>
  )
}

export default memo(ListView)
