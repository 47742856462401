import { useMutation } from '@apollo/client'
import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Dispatch, Fragment, SetStateAction } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Account,
  DeliveryAddress,
  Purchaser,
  Requisition,
  UpdateRequisitionDocument,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import {
  DeliveryDate,
  DeptAndAccount,
  SelectAddress,
  SendPurchaseOrder,
} from '@/modules/requisitions/pages/create/form-elements'
import { RequisitionFormInputs } from '@/modules/requisitions/types'
import { Button } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { CloseIcon } from '@/modules/shared/icons'

interface EditRequisitionModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  requisition: Requisition
}

function EditRequisitionModal(props: EditRequisitionModalProps) {
  const { showModal, setShowModal, requisition } = props
  const {
    requisitionNumber,
    reference,
    sendToSupplier,
    expectedDeliveryDate,
    department,
    account,
    deliveryAddress,
    id: requisitionId,
  } = requisition
  const { t } = useTranslation()

  const {
    control,
    reset,
    register,
    resetField,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<RequisitionFormInputs>({
    defaultValues: {
      reference: String(reference),
      sendToSupplier: Boolean(sendToSupplier),
      expectedDeliveryDate: String(expectedDeliveryDate),
      deliveryAddressId: deliveryAddress?.id,
      departmentId: department?.id,
      accountId: account?.id,
    },
  })

  const [updateRequistion, { loading }] = useMutation(UpdateRequisitionDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const onCloseModal = () => {
    reset()
    setShowModal(false)
  }

  const onSubmit: SubmitHandler<RequisitionFormInputs> = (data) => {
    updateRequistion({
      variables: {
        input: {
          id: requisitionId,
          ...data,
        },
      },
      onCompleted(data) {
        const requisition = data.updateRequisition.requisition
        reset({
          reference: String(requisition?.reference),
          sendToSupplier: Boolean(requisition?.sendToSupplier),
          expectedDeliveryDate: String(requisition?.expectedDeliveryDate),
          deliveryAddressId: requisition?.deliveryAddress?.id,
          departmentId: requisition?.department?.id,
          accountId: requisition?.account?.id,
        })
        setShowModal(false)
      },
    })
  }

  return (
    <Transition appear show={showModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className=" fixed inset-0 bg-gray-900/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                as="form"
                onSubmit={handleSubmit(onSubmit)}
                className="flex h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[600px]"
              >
                <header className="flex min-h-[64px] w-full items-center justify-between border-b px-5">
                  <span className="text-base text-gray-800">
                    {t('shopPage.cart.settings.requisitionSettings', { requisitionNumber })}
                  </span>
                  <Button
                    type="button"
                    className="rounded-md bg-gray-100 p-3 focus:outline-none"
                    onClick={onCloseModal}
                  >
                    <CloseIcon className="h-5 w-5" />
                  </Button>
                </header>
                <div className="flex-1 overflow-y-scroll bg-gray-100 p-5">
                  <section>
                    <h2 className="text-sm font-semibold">{t('createRequisition.reference.label')}</h2>
                    <p className="text-sm text-gray-500">{t('createRequisition.reference.explain')}</p>
                    <div className="mt-2">
                      <input
                        data-testid="reference-input"
                        className={classNames('w-full rounded-md border border-gray-300 p-3 shadow-sm sm:text-sm', {
                          'border-error focus:border-error focus:outline-none focus:ring-error': errors.reference,
                          'focus:outline-none focus:ring-primary': !errors.reference,
                        })}
                        type="text"
                        placeholder={t('createRequisition.reference.placeholder')}
                        {...register('reference', { required: true })}
                      />
                      {errors.reference && (
                        <span className="mt-2 block text-sm text-error">
                          {t('createRequisition.reference.required')}
                        </span>
                      )}
                    </div>
                  </section>
                  <SendPurchaseOrder control={control} defaultValue={Boolean(sendToSupplier)} />
                  <SelectAddress
                    control={control}
                    error={errors.deliveryAddressId}
                    defaultValue={deliveryAddress as DeliveryAddress}
                  />
                  <DeliveryDate control={control} defaultDate={expectedDeliveryDate} />
                  <DeptAndAccount
                    defaultAccountValue={account as Account}
                    defaultDepartmentValue={department as Purchaser}
                    control={control}
                    departmentError={errors.departmentId}
                    accountError={errors.accountId}
                    resetField={resetField}
                    getValues={getValues}
                  />
                  <div className="h-96" />
                </div>
                <footer className="flex min-h-[64px] w-full items-center justify-end border-t px-5">
                  <div className="space-x-2">
                    <Button type="button" className="rounded-md bg-gray-200 py-2.5 px-8 text-sm" onClick={onCloseModal}>
                      {t('shopPage.cart.settings.cancelModalBtn')}
                    </Button>
                    {isDirty && (
                      <Button
                        data-testid="save-changes-requisition-btn"
                        type="submit"
                        className="rounded-md bg-primary py-2.5 px-5 text-sm text-white"
                        loading={loading}
                      >
                        {t('shopPage.cart.settings.saveChangesModalBtn')}
                      </Button>
                    )}
                  </div>
                </footer>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default EditRequisitionModal
