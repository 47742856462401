import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { RequisitionFormInputs } from '@/modules/requisitions/types'
import { DatePicker } from '@/modules/shared/components'

interface Props {
  control: Control<RequisitionFormInputs, unknown>
  defaultDate?: string
}

export default function DeliveryDate({ control, defaultDate }: Props) {
  const { t } = useTranslation()
  return (
    <section className="mt-8">
      <h2 className="text-sm font-semibold">{t('createRequisition.deliveryDate.title')}</h2>
      <p className="text-sm text-gray-500">{t('createRequisition.deliveryDate.explain')}</p>
      <div className="mt-4">
        <Controller
          control={control}
          name="expectedDeliveryDate"
          render={({ field }) => (
            <DatePicker defaultDate={defaultDate} onSelected={(e) => field.onChange(e)} label="date-picker" />
          )}
        />
      </div>
    </section>
  )
}
