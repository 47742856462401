import { useTranslation } from 'react-i18next'

import AddItem from '../../AddItem'
import Stepper from '../../Stepper'

import NoImage from '@/assets/images/no-image.svg'
import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import { DEFAULT_CONCATENATED_SELL_UNIT } from '@/modules/requisitions/constants'
import { Button } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { OptionIcon } from '@/modules/shared/icons'
import { titleCase } from '@/modules/shared/utils'

interface ProductCardListViewProps {
  product: RequisitionLine
  isAddedToCart: boolean
}

function ProductCardListView(props: ProductCardListViewProps) {
  const { product, isAddedToCart } = props
  const { t } = useTranslation()
  const { format } = useMoney()
  const {
    id,
    productId,
    image,
    productBrand,
    productDescription,
    productItemSize,
    productItemMeasure,
    productItemPackName,
    productSellUnit,
    unitPrice,
    taxPercentage,
    supplier,
    quantity,
  } = product

  return (
    <tr className="border-b bg-white dark:border-gray-700">
      <td className="w-[68px] px-4 py-2">
        <div className="h-[60px] w-[60px] border border-gray-200">
          <img className="h-full w-full" src={image || NoImage} alt="product" />
        </div>
      </td>
      <td className="w-64 py-2 pr-4">
        <p className="break-words text-sm leading-5 text-primary">
          {productBrand} {productDescription}
        </p>
        <span className="text-xs">
          {productItemSize === 1 &&
          productItemMeasure === 'each' &&
          productSellUnit === DEFAULT_CONCATENATED_SELL_UNIT ? (
            <p>{titleCase(productSellUnit || DEFAULT_CONCATENATED_SELL_UNIT)}</p>
          ) : (
            <p>
              {productItemSize}
              {productItemMeasure} {titleCase(productItemPackName || '')}{' '}
              {(productItemSize || productItemMeasure || productItemPackName) && '| '}
              {titleCase(productSellUnit || DEFAULT_CONCATENATED_SELL_UNIT)}
            </p>
          )}
        </span>
      </td>
      <td className="px-4 py-2">
        <div className="max-w-[450px]">
          <p className="truncate text-sm leading-5 text-primary">{supplier?.name}</p>
        </div>
      </td>
      <td className="py-2 px-3 text-right">
        <span>
          <p className="text-base font-bold leading-tight">{format(unitPrice || 0)}</p>
          <p className="text-xs text-gray-500">
            {taxPercentage
              ? t('shopPage.productList.productCard.taxPercentage', { percent: taxPercentage })
              : t('shopPage.productList.productCard.taxFree')}
          </p>
        </span>
      </td>
      <td className="w-[168px] py-2 pl-1.5 text-center">
        {isAddedToCart ? (
          <Stepper productId={Number(productId)} lineId={Number(id)} quantity={Number(quantity)} />
        ) : (
          <AddItem productId={Number(productId)} supplierId={Number(supplier?.id)} unitPrice={Number(unitPrice)} />
        )}
      </td>
      <td className="w-[20px] py-2 pr-3 text-center">
        <Button>
          <OptionIcon className="h-10 w-10 text-gray-500" />
        </Button>
      </td>
    </tr>
  )
}

export default ProductCardListView
