export default function useMoney() {
  // TODO: use org currency
  const format = (amount: number) => {
    return new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
    }).format(amount)
  }
  return {
    format,
  }
}
