import { graphql } from 'msw'

import { UpdateRequisitionMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const UpdateRequisition = graphql.mutation<UpdateRequisitionMutation>('UpdateRequisition', (_, res, ctx) => {
  return res(
    ctx.data({
      updateRequisition: {
        requisition: {
          id: 5235941,
          reference: 'reference 123',
          sendToSupplier: true,
          approvalWorkflow: {
            id: 6326,
            title: 'Chris approval',
            __typename: 'RequisitionApprovalWorkflow',
          },
          nextApprovingAgentsNames: '',
          department: {
            id: 204632,
            name: 'AStore Shop Demo',
          },
          account: {
            id: 256446,
            accountName: '0060110 - Tickets for Attractions',
          },
          deliveryAddress: {
            id: 1226,
            locationName: 'Main Dock',
            line1: 'MB Head Office',
            line2: '343 Pacific Hwy',
            city: 'North Sydney',
            stateProvince: 'NSW',
            country: 'AU',
          },
          expectedDeliveryDate: '2022-07-12',
          __typename: 'Requisition',
        },
        __typename: 'UpdateRequisitionMutationPayload',
      },
    })
  )
})
