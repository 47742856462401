import { useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link, generatePath, useParams } from 'react-router-dom'

import { GetRequisitionDocument, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ApprovalItem } from '@/modules/requisitions/components'
import { useSubmitRequisition } from '@/modules/requisitions/hooks'
import { Button } from '@/modules/shared/components'
import QueryResult from '@/modules/shared/components/query-result'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { ChevronLeftIcon } from '@/modules/shared/icons'

export default function Submit() {
  const { t } = useTranslation()
  const { requisitionId } = useParams<{ requisitionId: string }>()

  const { data, loading, error } = useQuery(GetRequisitionDocument, {
    variables: {
      requisitionId: Number(requisitionId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })
  const [submitRequisition, { loading: submitLoading }] = useSubmitRequisition()
  const onSubmitRequisition = () => {
    submitRequisition({
      variables: {
        id: Number(requisitionId),
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>
          {t('confirmRequisition.confirmSubmitRequisitionPageTitle', {
            requisitionNumber: data?.currentPurchaser?.requisition?.requisitionNumber,
          })}
        </title>
      </Helmet>
      <header className="px-4 py-3">
        <Link
          data-testid="action-return"
          to={generatePath(`/requisitions/:requisitionId/shop`, {
            requisitionId: String(requisitionId),
          })}
          className="flex w-fit items-center justify-between gap-x-2 rounded-md bg-gray-200 px-4 py-3 transition hover:brightness-95"
        >
          <ChevronLeftIcon className="-ml-2 h-5 w-5" />
          <p className="text-sm">{t('confirmRequisition.back')}</p>
        </Link>
      </header>
      <div className="mx-auto w-full py-10 px-4 lg:w-[698px]">
        <QueryResult loading={loading} error={error}>
          <div>
            {requisitionId && data?.currentPurchaser && (
              <>
                <div className="flex flex-col">
                  <h1 className="text-center text-xl font-bold">
                    {t('confirmRequisition.confirmRequisitionNumber', {
                      requisitionNumber: data.currentPurchaser.requisition?.requisitionNumber,
                    })}
                  </h1>
                  <p className="mt-2 text-center text-sm text-gray-500">{t('confirmRequisition.pleaseConfirm')}</p>
                </div>
                <div className="mt-8">
                  <ApprovalItem requisition={data.currentPurchaser.requisition as Requisition} />
                </div>
                <Button
                  type="submit"
                  data-testid="action-submit"
                  className="mt-4 mb-2 block w-full rounded-md bg-success p-3 text-center text-sm text-white"
                  onClick={onSubmitRequisition}
                  loading={submitLoading}
                >
                  {t('confirmRequisition.submitButton')}
                </Button>
                <Link
                  data-testid="action-back"
                  className="my-2 block w-full rounded-md bg-gray-200 p-3 text-center text-sm transition hover:brightness-95"
                  to={generatePath(`/requisitions/:requisitionId/shop`, {
                    requisitionId: requisitionId,
                  })}
                >
                  {t('confirmRequisition.back')}
                </Link>
              </>
            )}
          </div>
        </QueryResult>
      </div>
    </>
  )
}
