import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { GetRequisitionDocument, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ApprovalItem } from '@/modules/requisitions/components'
import { QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

function Setting() {
  const { requisitionId } = useParams<{ requisitionId: string }>()

  const { data, loading, error } = useQuery(GetRequisitionDocument, {
    variables: {
      requisitionId: Number(requisitionId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })
  return (
    <QueryResult loading={loading} error={error}>
      {requisitionId && data?.currentPurchaser && (
        <>
          <ApprovalItem
            requisition={data.currentPurchaser.requisition as Requisition}
            showRequisitionExternalButton={false}
            showEditRequisitionButton
          />
        </>
      )}
    </QueryResult>
  )
}

export default Setting
