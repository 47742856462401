import { RecursivePartialAndNullable } from '@/tests/types'

type Edge<TNode> = {
  node: TNode
}

type Connection<TEdge> = {
  edges?: Edge<TEdge>[]
}

export default function extractEdges<DataType>(
  connection?: RecursivePartialAndNullable<Connection<DataType>> | null
): DataType[] {
  return (connection?.edges?.map((e) => e?.node) as DataType[]) || []
}
