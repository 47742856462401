import { Header } from '@/modules/requisitions/layouts'

export default function AdminCenter() {
  return (
    <>
      <Header />
      <div data-testid="admin-center">Admin Center</div>
    </>
  )
}
