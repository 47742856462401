import { graphql } from 'msw'

import { UpdateRequisitionLineMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const UpdateRequisitionLine = graphql.mutation<UpdateRequisitionLineMutation>(
  'UpdateRequisitionLine',
  (_, res, ctx) => {
    return res(
      ctx.data({
        updateRequisitionLine: {
          requisition: {
            id: 5270886,
            totalValue: 23.98,
            lines: [{ id: 26770369, productId: 5814401, quantity: 2.0, __typename: 'RequisitionLine' }],
            __typename: 'Requisition',
          },
          __typename: 'UpdateRequisitionLineMutationPayload',
        },
      })
    )
  }
)
