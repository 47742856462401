import { useQuery } from '@apollo/client'

import { GetCurrentPurchaserDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useCurrentPurchaser() {
  const { data, loading } = useQuery(GetCurrentPurchaserDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })
  return {
    currentPurchaser: data?.currentPurchaser,
    loading,
  }
}
