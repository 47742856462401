import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import TableView from './table-view'

import { GetRequisitionsDraftDocument, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useCurrentUser } from '@/modules/access/hooks'
import { QueryResult, Tooltip } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { CartIcon, QuestionMarkIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

export default function DraftRequisitions() {
  const { t } = useTranslation()

  const { currentUser } = useCurrentUser()

  const { data, loading, error } = useQuery(GetRequisitionsDraftDocument, {
    variables: {
      userId: currentUser?.id,
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const draftList = extractEdges<Requisition>(data?.currentPurchaser?.requisitions)

  return (
    <>
      <div className="mt-5 flex items-center justify-start">
        <CartIcon className="h-10 w-10" />
        <p className="ml-3 text-sm" data-testid="dashboard-draft-requisitions">
          {t('dashboard.draftRequisitions.myDrafts')}
          <Tooltip content={t('dashboard.draftRequisitions.tooltip')} showArrow>
            <span className="ml-1">
              <QuestionMarkIcon className="inline w-4" />
            </span>
          </Tooltip>
        </p>
      </div>
      <QueryResult loading={loading} error={error}>
        <TableView draftList={draftList} />
      </QueryResult>
    </>
  )
}
