export default function PlusIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22.1996 10.1996H13.8004V1.80044C13.8004 1.32293 13.6108 0.864984 13.2731 0.527337C12.9355 0.189689 12.4775 0 12 0C11.5225 0 11.0645 0.189689 10.7269 0.527337C10.3892 0.864984 10.1996 1.32293 10.1996 1.80044V10.1996H1.80044C1.32293 10.1996 0.864984 10.3892 0.527337 10.7269C0.189689 11.0645 0 11.5225 0 12V12C0 12.4775 0.189689 12.9355 0.527337 13.2731C0.864984 13.6108 1.32293 13.8004 1.80044 13.8004H10.1996V22.1996C10.1996 22.6771 10.3892 23.135 10.7269 23.4727C11.0645 23.8103 11.5225 24 12 24C12.4775 24 12.9355 23.8103 13.2731 23.4727C13.6108 23.135 13.8004 22.6771 13.8004 22.1996V13.8004H22.1996C22.436 13.8004 22.6701 13.7539 22.8886 13.6634C23.107 13.5729 23.3055 13.4403 23.4727 13.2731C23.6398 13.1059 23.7725 12.9074 23.863 12.689C23.9534 12.4706 24 12.2364 24 12V12C24 11.7636 23.9534 11.5294 23.863 11.311C23.7725 11.0926 23.6398 10.8941 23.4727 10.7269C23.3055 10.5597 23.107 10.4271 22.8886 10.3366C22.6701 10.2461 22.436 10.1996 22.1996 10.1996V10.1996Z"
        fill="white"
      />
    </svg>
  )
}
