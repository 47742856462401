import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
  ServerError,
  createHttpLink,
  from,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { relayStylePagination } from '@apollo/client/utilities'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Spinner } from '@/modules/requisitions/components'
import { useAlert } from '@/modules/shared/hooks'

interface ApolloProviderWrapperProps extends PropsWithChildren {}

function ApolloProviderWrapper(props: ApolloProviderWrapperProps) {
  const { children } = props

  const { t } = useTranslation()
  const { alertDialog } = useAlert()

  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>()

  useEffect(() => {
    async function init() {
      const httpLink = createHttpLink({
        uri: `${process.env.REACT_APP_NINJA_API_HOST}/access/api/graphql`,
        credentials: 'include',
      })

      const errorLink = onError((error) => {
        const networkError = error.networkError as ServerError

        // Any request met with a 401 response
        if (networkError?.statusCode === 401) {
          // These operations shouldn't raise an alert dialog
          if (
            error.operation.operationName === 'GetCurrentUser' ||
            error.operation.operationName === 'GetCurrentPurchaser' ||
            error.operation.operationName === 'SignOut'
          ) {
            return
          }
          return alertDialog({
            type: 'error',
            title: t('alert.unauthorized.title'),
            message: t('alert.unauthorized.message'),
            buttonText: t('access.signIn'),
            onButtonClick() {
              window.location.reload()
            },
          })
        }

        // All other errors
        if (!networkError) alertDialog({ type: 'error' })
      })

      setClient(
        new ApolloClient({
          link: from([errorLink, httpLink]),
          cache: new InMemoryCache({
            typePolicies: {
              Purchaser: {
                fields: {
                  deliveryAddresses: relayStylePagination(),
                  supplierRelationships: relayStylePagination(),
                },
              },
              Requisition: {
                fields: {
                  possibleRequisitionLines: relayStylePagination(),
                },
              },
              RequisitionLine: {
                keyFields: ['productId'],
              },
            },
          }),
          connectToDevTools: process.env.NODE_ENV === 'development',
        })
      )
    }

    init().catch(console.error)
  }, [])

  if (!client) return <Spinner className="mt-12 h-28 md:h-32" />
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ApolloProviderWrapper
