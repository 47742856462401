import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import OptionDetails from './OptionDetails'

import { useCurrentUser } from '@/modules/access/hooks'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks'
import { Themes } from '@/modules/requisitions/types'
import { ConfirmDialog } from '@/modules/shared/components'
import { ChevronDownIcon, SaveIcon } from '@/modules/shared/icons'

export default function ShopHeader() {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  const { currentPurchaser } = useCurrentPurchaser()
  const [isOpen, setIsOpen] = useState(false)

  const onConfirm = () => {
    setIsOpen(false)
    // TODO: save requisition, to be handled in different pull request
  }

  return (
    <header className="flex items-center justify-between border-b bg-white py-3 px-5">
      <button
        className="flex items-center gap-2 rounded-md bg-gray-100 px-4 py-2 text-sm hover:bg-gray-200"
        onClick={() => setIsOpen(true)}
      >
        <span>{t('access.shop.confirm.saveAndClose')}</span>
        <SaveIcon className="h-7 w-7" />
      </button>
      <nav className="flex">
        {currentUser && currentPurchaser && (
          <OptionDetails currentUser={currentUser} currentPurchaser={currentPurchaser}>
            <span className="text-sm">{t('access.shop.action.options')}</span>
            <ChevronDownIcon className="-mr-0.5 h-6 w-6" aria-hidden="true" />
          </OptionDetails>
        )}
      </nav>
      <ConfirmDialog
        title={t('access.shop.confirm.saveAndClose')}
        description={t('access.shop.confirm.saveDescription')}
        mainButtonLabel={t('access.shop.action.save')}
        secondaryButtonLabel={t('access.shop.action.continue')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onConfirm={onConfirm}
        theme={Themes.success}
      />
    </header>
  )
}
