import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './en.json'

export default function i18nextConfig() {
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: en,
      },
    },
    lng: 'en',
  })

  return i18n
}
