import SpinnerLoading from '@/assets/spinner.svg'

interface SpinnerProps {
  className?: string
}

function Spinner(props: SpinnerProps) {
  const { className } = props

  return (
    <div className="w-full">
      <img src={SpinnerLoading} className={`${className} mx-auto`} alt="loading spinner" />
    </div>
  )
}

export default Spinner
