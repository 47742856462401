import { graphql } from 'msw'

import { DeclineRequisitionMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const DeclineRequisition = graphql.mutation<DeclineRequisitionMutation>(
  'DeclineRequisition',
  (req, res, ctx) => {
    return res(
      ctx.data({
        declineRequisition: {
          requisition: {
            id: 5235941,
            purchaserOrganisationId: 23783,
            state: 'declined',
            __typename: 'Requisition',
          },
          __typename: 'DeclineRequisitionMutationPayload',
        },
      })
    )
  }
)
