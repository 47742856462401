import { NetworkStatus, useQuery } from '@apollo/client'
import { Control, Controller, FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  DeliveryAddress,
  GetDeliveryAddressesDocument,
  RansackDirection,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { RequisitionFormInputs } from '@/modules/requisitions/types'
import { ComboboxServer } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

interface Props {
  control: Control<RequisitionFormInputs, unknown>
  error?: FieldError
  defaultValue?: DeliveryAddress
}

export default function SelectAddress({ control, error, defaultValue }: Props) {
  const { t } = useTranslation()
  const {
    data: deliveryAddressesData,
    refetch,
    networkStatus,
    fetchMore,
  } = useQuery(GetDeliveryAddressesDocument, {
    variables: {
      after: null,
      filter: null,
      sort: [
        {
          property: 'locationName',
          direction: RansackDirection.Asc,
        },
      ],
      first: 25,
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
  })

  return (
    <section className="mt-8">
      <h2 className="text-sm font-semibold">{t('createRequisition.address.title')}</h2>
      <p className="text-sm text-gray-500">{t('createRequisition.address.explain')}</p>
      <div className="mt-2">
        <Controller
          control={control}
          name="deliveryAddressId"
          rules={{ required: true }}
          render={({ field }) => (
            <ComboboxServer
              placeholder={t('createRequisition.address.placeholder')}
              loading={networkStatus === NetworkStatus.loading}
              searchLoading={networkStatus === NetworkStatus.setVariables}
              fetchMoreLoading={networkStatus === NetworkStatus.fetchMore}
              onDisplay={(e) => `${e.locationName} ${e.line1} ${e.line2} ${e.city} ${e.stateProvince} ${e.country}`}
              hasError={Boolean(error)}
              errorMessage={t('createRequisition.address.required')}
              keyExtractor={(e) => `${e.id}`}
              items={
                deliveryAddressesData?.currentPurchaser?.deliveryAddresses?.edges?.map(
                  (e) => e?.node
                ) as DeliveryAddress[]
              }
              onInputChange={(e) =>
                refetch({
                  filter: {
                    q: [
                      {
                        property: 'search_text_cont',
                        value: e,
                      },
                    ],
                  },
                })
              }
              onSelected={(e) => field.onChange(e.id)}
              onFetchMore={() =>
                fetchMore({
                  variables: {
                    after: deliveryAddressesData?.currentPurchaser?.deliveryAddresses?.pageInfo.endCursor,
                  },
                })
              }
              hasMore={Boolean(deliveryAddressesData?.currentPurchaser?.deliveryAddresses?.pageInfo.hasNextPage)}
              defaultValue={
                defaultValue || (deliveryAddressesData?.currentPurchaser?.defaultDeliveryAddress as DeliveryAddress)
              }
              testId="select-address"
            />
          )}
        />
      </div>
    </section>
  )
}
