import { graphql } from 'msw'

import { GetRequisitionQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

export const GetRequisition = graphql.query<GetRequisitionQuery>('GetRequisition', (req, res, ctx) => {
  return res(
    ctx.data({
      currentPurchaser: {
        id: 23783,
        requisition: {
          id: 5235941,
          state: 'awaiting_approval',
          requisitionNumber: 'PR00040',
          reference: 'reference',
          originName: null,
          creator: {
            id: 19897,
            fullName: 'Johan W. Baaij',
          },
          createdAt: '2022-07-08T12:49:06+10:00',
          totalValueExcludingTax: 99.604,
          totalTaxValue: 4.896,
          totalValue: 104.5,
          nextApprovingAgentsNames: 'Kevin Milner',
          expectedDeliveryDate: '2022-07-12',
          sendToSupplier: true,
          deliveryAddress: {
            id: 1226,
            locationName: 'Main Dock',
            line1: 'MB Head Office',
            line2: '343 Pacific Hwy',
            city: 'North Sydney',
            stateProvince: 'NSW',
            country: 'AU',
          },
          approvalWorkflow: {
            id: 5972,
            title: 'Demo',
          },
          department: {
            id: 204632,
            name: 'AStore Shop Demo',
          },
          account: {
            id: 256446,
            accountName: '0060110 - Tickets for Attractions',
          },
        },
      },
    })
  )
})
