import classNames from 'classnames'
import { useRef } from 'react'
import { AriaButtonProps, mergeProps, useButton, useFocusRing } from 'react-aria'

function CalendarButton(props: AriaButtonProps<'button'>) {
  const ref = useRef(null)
  const { buttonProps } = useButton(props, ref)
  const { focusProps, isFocusVisible } = useFocusRing()

  return (
    <button
      {...mergeProps(buttonProps, focusProps)}
      ref={ref}
      className={classNames('rounded-full p-2 outline-none', {
        'text-gray-400': props.isDisabled,
        'hover:bg-gray-100 active:bg-gray-200': !props.isDisabled,
        'ring-2 ring-gray-600 ring-offset-2': isFocusVisible,
      })}
    >
      {props.children}
    </button>
  )
}

export default CalendarButton
