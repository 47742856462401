import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { DateValue, createCalendar } from '@internationalized/date'
import { useRef } from 'react'
import { CalendarProps, useCalendar, useLocale } from 'react-aria'
import { useCalendarState } from 'react-stately'

import CalendarButton from './CalendarButton'
import CalendarGrid from './CalendarGrid'

function Calendar(props: CalendarProps<DateValue>) {
  const { locale } = useLocale()
  const state = useCalendarState({
    ...props,
    locale,
    createCalendar,
  })

  const ref = useRef(null)
  const { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar(props, state)

  return (
    <div {...calendarProps} ref={ref} className="inline-block text-gray-800" data-testid="date-picker-content">
      <div className="flex items-center pb-4">
        <h2 className="ml-2 flex-1 text-xl font-bold">{title}</h2>
        <CalendarButton {...prevButtonProps}>
          <ChevronLeftIcon className="h-6 w-6" />
        </CalendarButton>
        <CalendarButton {...nextButtonProps}>
          <ChevronRightIcon className="h-6 w-6" />
        </CalendarButton>
      </div>
      <CalendarGrid state={state} />
    </div>
  )
}

export default Calendar
