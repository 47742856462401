import { Popover, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { SUPPORT_LINK } from '@/modules/shared/constants'
import { GetHelpIcon, InfoIcon, NewTabIcon } from '@/modules/shared/icons'

interface MoreOptionMenuProps extends PropsWithChildren {
  requisitionId: string
}

function MoreOptionMenu(props: MoreOptionMenuProps) {
  const { children, requisitionId } = props
  const { t } = useTranslation()

  const enterpriseRequisitionUrl = `${process.env.REACT_APP_NINJA_API_HOST}/purchasing/en/requisitions/${requisitionId}`

  return (
    <Popover className="relative">
      <Popover.Button data-testid="more-option-button" className="items-center justify-between hover:opacity-75">
        {children}
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel
          data-testid="more-option-menu"
          className="absolute -left-20 z-10 w-60 -translate-x-1/2 rounded-md border bg-white shadow-lg"
        >
          <div className="py-1">
            <Link
              className={classNames(
                'w-full',
                'px-4',
                'py-2',
                'text-left',
                'text-sm',
                'flex',
                'items-center',
                'text-gray-700',
                'transition',
                'hover:bg-gray-100 ',
                'hover:text-gray-900'
              )}
              data-testid="view-requisition"
              to={generatePath('/requisitions/:id', {
                id: requisitionId,
              })}
            >
              <InfoIcon className="mr-2 h-5 w-5" />
              {t('requisition.viewRequisition')}
            </Link>
            <a
              className={classNames(
                'w-full',
                'px-4',
                'py-2',
                'text-left',
                'text-sm',
                'flex',
                'items-center',
                'text-gray-700',
                'transition',
                'hover:bg-gray-100 ',
                'hover:text-gray-900'
              )}
              data-testid="view-in-enterprise"
              href={enterpriseRequisitionUrl}
              target="_blank"
              rel="noreferrer"
            >
              <NewTabIcon className="mr-2 h-5 w-5" />
              {t('requisition.openInP+Enterprise')}
            </a>
            <a
              className={classNames(
                'w-full',
                'px-4',
                'py-2',
                'text-left',
                'text-sm',
                'flex',
                'items-center',
                'text-gray-700',
                'transition',
                'hover:bg-gray-100 ',
                'hover:text-gray-900'
              )}
              href={SUPPORT_LINK}
              data-testid="view-support-link"
              target="_blank"
              rel="noreferrer"
            >
              <GetHelpIcon className="mr-2 h-5 w-5" />
              {t('requisition.getHelp')}
            </a>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

export default MoreOptionMenu
