import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import ApolloProviderWrapper from '@/apollo/ApolloProviderWrapper'
import i18nextConfig from '@/locales/i18nextConfig'
import { Dashboard } from '@/modules/purchasing/pages'
import {
  ApproveRedirect,
  AwaitingMyApproval,
  Create,
  Decline,
  Requisition,
  Shop,
  Submit,
} from '@/modules/requisitions/pages'
import { HeaderAndCart, Setting, Summary } from '@/modules/requisitions/pages/view-cart'
import AlertDialog from '@/modules/shared/components/alert-dialog'
import { AdminCenter, NotFound, Root } from '@/modules/shared/pages'
import { AlertProvider } from '@/modules/shared/providers/AlertProvider'
import { AdminRoute, ProtectedRoute, PurchaserRoute } from '@/routes'

i18nextConfig()

function App() {
  return (
    <AlertProvider>
      <ApolloProviderWrapper>
        <HelmetProvider>
          <BrowserRouter>
            <Routes>
              <Route element={<ProtectedRoute />}>
                <Route element={<PurchaserRoute />}>
                  <Route path="/" element={<Root />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/requisitions/awaiting-my-approval" element={<AwaitingMyApproval />} />
                  <Route path="/requisitions/:requisitionId" element={<Requisition />} />
                  <Route path="/requisitions/:requisitionId/submit" element={<Submit />} />
                  <Route path="/requisitions/:requisitionId/decline" element={<Decline />} />
                  <Route path="/requisitions/:requisitionId/approve" element={<ApproveRedirect />} />
                  <Route path="/requisitions/:requisitionId/shop" element={<Shop />} />
                  <Route path="/requisitions/:requisitionId/shop" element={<HeaderAndCart />}>
                    <Route path="summary" element={<Summary />} />
                    {/* <Route path="comment" element={<Comment />} /> */}
                    <Route path="settings" element={<Setting />} />
                  </Route>
                  <Route path="/requisitions/create" element={<Create />} />
                  <Route element={<AdminRoute />}>
                    <Route path="/admin" element={<AdminCenter />} />
                  </Route>
                </Route>
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </HelmetProvider>
        <AlertDialog />
      </ApolloProviderWrapper>
    </AlertProvider>
  )
}

export default App
