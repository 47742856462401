import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import EditRequisitionModal from '@/modules/requisitions/pages/view-cart/setting/EditSettingModal'
import { formatStateForLocale } from '@/modules/requisitions/utils'
import { Button, Tooltip } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { CheckAltIcon, CloseIcon, Loading, SettingsIcon } from '@/modules/shared/icons'

interface ApprovalItemProps {
  requisition: Requisition
  showActions?: boolean
  showRequisitionButton?: boolean
  showRequisitionExternalButton?: boolean
  showEditRequisitionButton?: boolean
  onApprove?: (prId: number) => void
  actionLoading?: boolean
  className?: string
  showLessDetail?: boolean
}

function ApprovalItem(props: ApprovalItemProps) {
  const {
    requisition,
    className,
    showActions = false,
    showRequisitionButton = false,
    showRequisitionExternalButton = true,
    showEditRequisitionButton = false,
    showLessDetail = false,
    actionLoading,
    onApprove,
  } = props
  const {
    id,
    state,
    originName,
    reference,
    requisitionNumber,
    totalValueExcludingTax,
    totalTaxValue,
    totalValue,
    expectedDeliveryDate,
    nextApprovingAgentsNames,
    sendToSupplier,
    creator,
    approvalWorkflow,
    department,
    account,
  } = requisition
  const { t } = useTranslation()
  const { format } = useMoney()
  const [showEditModal, setShowEditModal] = useState(false)

  return (
    <div className={classNames('mb-6', 'rounded-md', 'border', 'bg-white', className)}>
      <div data-testid="approval-item" className="flex flex-col justify-between p-4 md:flex-row">
        <div>
          <span className="break-all text-base font-bold">{reference || requisitionNumber}</span>
          <p className="text-sm text-gray-500">{requisitionNumber}</p>
        </div>
        <div>
          <div className="mt-4 text-left md:mt-0 md:text-right">
            <span className="text-base font-bold">{`${format(totalValue || 0)}`}</span>
            <p className="text-sm text-gray-500">
              {t('requisition.total')} ({t('requisition.includingTax')})
            </p>
          </div>
        </div>
      </div>
      <hr />
      <section data-testid="requisition-details" className="p-4 text-sm">
        {[
          {
            key: 'requisition.details.status',
            value: `${t(formatStateForLocale(state || ''))}`,
          },
          {
            key: 'requisition.details.requisitionType',
            value: originName,
            showLess: true,
          },
          {
            key: 'requisition.details.ref',
            value: reference,
          },
          {
            key: 'requisition.details.requestedBy',
            value: creator?.fullName,
            showLess: true,
          },
          {
            key: 'requisition.details.deliveryDate',
            value: expectedDeliveryDate ? dayjs(expectedDeliveryDate).format('DD MMM YYYY') : '--',
            showLess: true,
          },
          {
            key: 'requisition.details.approvalWorkflow',
            value: approvalWorkflow?.title,
          },
          {
            key: 'requisition.details.nextApprovers',
            value: nextApprovingAgentsNames,
          },
          {
            key: 'requisition.details.department',
            value: department?.name,
            showLess: true,
          },
          {
            key: 'requisition.details.accountCode',
            value: account?.accountName,
            showLess: true,
          },
          {
            key: 'requisition.details.sendPO',
            value: `${sendToSupplier ? t('requisition.details.sendPOYes') : t('requisition.details.sendPONo')}`,
            showLess: true,
          },
          {
            key: 'requisition.details.totalWithoutTax',
            value: `${format(totalValueExcludingTax || 0)}`,
          },
          {
            key: 'requisition.details.tax',
            value: `${format(totalTaxValue || 0)}`,
          },
          {
            key: 'requisition.details.totalWithTax',
            value: `${format(totalValue || 0)}`,
          },
        ]
          .filter((e) => (showLessDetail ? e.showLess : true))
          .map((e) => {
            return (
              <div key={e.key} className="mb-3 flex flex-col justify-between md:flex-row">
                <strong>{t(e.key)}</strong>
                <p className="pl-0 text-left md:pl-2">{e.value || '--'}</p>
              </div>
            )
          })}
      </section>

      <hr />
      <div
        className={classNames('flex w-full items-center p-4', {
          'justify-end': showEditRequisitionButton,
          'justify-between': !showEditRequisitionButton,
        })}
      >
        {showEditRequisitionButton && (
          <Button
            data-testid="edit-requisition-btn"
            className="flex h-12 w-full items-center justify-center gap-x-2 rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95 md:w-fit"
            onClick={() => setShowEditModal(true)}
          >
            {t('requisition.editRequisition')}
            <SettingsIcon className="h-6 w-6" />
          </Button>
        )}
        {showRequisitionButton && (
          <Link
            data-testid="view-requisition-link"
            to={generatePath('/requisitions/:id', {
              id: id.toString(),
            })}
            className="flex h-12 items-center justify-center rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95"
          >
            {t('requisition.viewRequisition')}
          </Link>
        )}
        {showRequisitionExternalButton && (
          <a
            data-testid="view-requisition-external-link"
            href={`${process.env.REACT_APP_NINJA_API_HOST}/purchasing/en/requisitions/${id}`}
            target="_blank"
            rel="noreferrer"
            className={classNames(
              'flex',
              'items-center',
              'justify-center',
              'rounded-md',
              'h-12',
              'p-4',
              'text-center',
              'text-sm',
              'transition',
              'border',
              'hover:brightness-95',
              {
                'bg-gray-200': showActions,
                'w-full': !showActions,
              }
            )}
          >
            {t('requisition.viewRequisitionExternal')}
            <ArrowTopRightOnSquareIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </a>
        )}
        {showActions && (
          <div className="flex items-center gap-2">
            <Tooltip content={t('requisition.details.tooltipActionDecline')} delay={100} showArrow>
              <Link
                data-testid="decline-button"
                to={generatePath('/requisitions/:id/decline', {
                  id: String(id),
                })}
                className="flex h-12 w-12 items-center justify-center rounded-full bg-error text-white transition hover:brightness-90"
              >
                <CloseIcon className="h-6 w-6" />
              </Link>
            </Tooltip>
            <Tooltip content={t('requisition.details.tooltipActionApprove')} delay={100} showArrow>
              <Button
                data-testid="approve-button"
                disabled={actionLoading}
                className="flex h-12 w-12 items-center justify-center rounded-full bg-success text-white"
                onClick={() => onApprove && onApprove(id)}
              >
                {actionLoading ? (
                  <Loading className="h-6 w-6 fill-white text-gray-300" />
                ) : (
                  <CheckAltIcon className="h-5 w-5" />
                )}
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <EditRequisitionModal requisition={requisition} showModal={showEditModal} setShowModal={setShowEditModal} />
    </div>
  )
}

export default ApprovalItem
