import { NetworkStatus, useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

import Cart from './cart'
import ProductList from './product-list'

import { GetPossibleRequisitionLinesDocument, RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ShopHeader } from '@/modules/requisitions/layouts'
import { QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { extractEdges } from '@/modules/shared/utils'

function Shop() {
  const { requisitionId } = useParams()
  const { data, error, networkStatus, fetchMore, refetch } = useQuery(GetPossibleRequisitionLinesDocument, {
    variables: {
      first: 20,
      after: null,
      term: '',
      requisitionId: Number(requisitionId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
  })

  const onSearchProduct = (searchValue: string) => {
    refetch({ term: searchValue })
  }

  const onFetchMoreProduct = () => {
    fetchMore({
      variables: {
        after: data?.currentPurchaser?.requisition?.possibleRequisitionLines?.pageInfo.endCursor,
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{data?.currentPurchaser?.requisition?.requisitionNumber}</title>
      </Helmet>
      <ShopHeader />
      <div className="mx-auto w-full p-4 sm:pt-12 lg:max-w-[1300px]">
        <QueryResult loading={networkStatus === NetworkStatus.loading} error={error}>
          {requisitionId && data?.currentPurchaser && (
            <div className="flex flex-col gap-5">
              <Cart totalValue={data?.currentPurchaser?.requisition?.totalValue} />
              <ProductList
                prNumber={data?.currentPurchaser?.requisition?.requisitionNumber}
                prReference={data?.currentPurchaser?.requisition?.reference || ''}
                productList={extractEdges<RequisitionLine>(
                  data?.currentPurchaser?.requisition?.possibleRequisitionLines
                )}
                onSearchProduct={onSearchProduct}
                onFetchMoreProduct={onFetchMoreProduct}
                fetchMoreProductLoading={networkStatus === NetworkStatus.fetchMore}
                searchProductLoading={networkStatus === NetworkStatus.setVariables}
                hasMoreProduct={Boolean(
                  data?.currentPurchaser?.requisition?.possibleRequisitionLines?.pageInfo.hasNextPage
                )}
                lines={data?.currentPurchaser?.requisition?.lines as RequisitionLine[]}
              />
            </div>
          )}
        </QueryResult>
      </div>
    </>
  )
}

export default Shop
