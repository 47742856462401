import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, generatePath, useNavigate } from 'react-router-dom'

import RequisitionTypes from './RequisitionTypes'
import SingleRequisition from './SingleRequisition'

import { CreateRequisitionDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useCurrentUser } from '@/modules/access/hooks'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks'
import { RequisitionFormInputs, RequisitionType } from '@/modules/requisitions/types'
import { Button } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API, SOURCE_APPLICATION, SUPPORT_LINK } from '@/modules/shared/constants'

export default function Create() {
  const { t } = useTranslation()
  const [selectedType, setSelectedType] = useState<RequisitionType>(null)
  const {
    control,
    register,
    handleSubmit,
    resetField,
    getValues,
    formState: { errors },
  } = useForm<RequisitionFormInputs>({
    defaultValues: {
      sendToSupplier: true,
      expectedDeliveryDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
    },
  })
  const navigate = useNavigate()
  const { currentUser } = useCurrentUser()
  const { currentPurchaser } = useCurrentPurchaser()
  const [createRequisition, { loading }] = useMutation(CreateRequisitionDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    onCompleted(data) {
      const requisitionId = data.createRequisition.requisition?.id
      navigate(
        generatePath('/requisitions/:requisitionId/shop', {
          requisitionId: String(requisitionId),
        })
      )
    },
  })
  const onSubmit: SubmitHandler<RequisitionFormInputs> = (data) => {
    const { reference, deliveryAddressId, sendToSupplier, expectedDeliveryDate, accountId, departmentId, originId } =
      data
    const purchaserOrganisationId = currentPurchaser?.id as number
    const requestorId = currentUser?.id
    const { originType } = selectedType || {}
    const sourceApplication = SOURCE_APPLICATION
    createRequisition({
      variables: {
        input: {
          reference,
          requestorId,
          originId,
          originType,
          purchaserOrganisationId,
          deliveryAddressId,
          sendToSupplier,
          expectedDeliveryDate,
          accountId,
          departmentId,
          sourceApplication,
        },
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{t('createRequisition.createRequisitionPageTitle')}</title>
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-80">
        <div className="mx-auto my-20 w-full p-4 md:w-[800px]">
          <h1 className="text-2xl font-semibold">{t('createRequisition.title')}</h1>

          <section className="mt-8">
            <h2 className="text-sm font-semibold">{t('createRequisition.reference.label')}</h2>
            <p className="text-sm text-gray-500">{t('createRequisition.reference.explain')}</p>
            <div className="mt-2">
              <input
                className={classNames('block w-full rounded-md border border-gray-300 p-3 shadow-sm sm:text-sm', {
                  'border-error focus:border-error focus:outline-none focus:ring-error': errors.reference,
                  'focus:outline-none focus:ring-primary': !errors.reference,
                })}
                type="text"
                placeholder={t('createRequisition.reference.placeholder')}
                {...register('reference', { required: true })}
              />
              {errors.reference && (
                <span className="mt-2 block text-sm text-error">{t('createRequisition.reference.required')}</span>
              )}
            </div>
          </section>

          <section className="mt-8">
            <h2 className="text-sm font-semibold">{t('createRequisition.type.label')}</h2>
            <p className="text-sm text-gray-500">{t('createRequisition.type.explain')}</p>
            <div className="mt-2">
              <RequisitionTypes setSelectedType={setSelectedType} />
            </div>
          </section>

          {selectedType?.id === 2 && (
            <SingleRequisition control={control} errors={errors} resetField={resetField} getValues={getValues} />
          )}

          <section className="mt-8 flex flex-col-reverse items-center justify-between gap-y-2 md:flex-row">
            <a className="text-sm text-primary" href={SUPPORT_LINK} target="_blank" rel="noreferrer">
              {t('createRequisition.actions.help')}
            </a>
            <div className="flex w-full flex-col-reverse gap-2 md:w-fit md:flex-row">
              <Link className="block rounded-md bg-gray-200 px-5 py-3 text-center text-sm" to="/">
                {t('createRequisition.actions.cancel')}
              </Link>
              <Button
                data-testid="create-requisition-button"
                type="submit"
                disabled={selectedType === null}
                loading={loading}
                className="rounded-md bg-primary px-5 py-3 text-sm text-white"
              >
                {t('createRequisition.actions.create')}
              </Button>
            </div>
          </section>
        </div>
      </form>
    </>
  )
}
