import { useTranslation } from 'react-i18next'

import ProductEmptyStateImg from '@/assets/images/product-empty-state.svg'

export default function ProductListEmptyState() {
  const { t } = useTranslation()
  return (
    <div className="mb-3 flex flex-col items-center justify-center">
      <img src={ProductEmptyStateImg} className="h-64 md:h-72" alt="" />
      <div className="text-center">
        <p className="text-xs text-gray-500 md:text-sm">{t('shopPage.productList.noProductFound')}</p>
      </div>
    </div>
  )
}
