import { Dialog, Transition } from '@headlessui/react'
import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { Fragment, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/modules/shared/components'
import { CheckAltIcon } from '@/modules/shared/icons'
import AlertContext from '@/modules/shared/providers/AlertProvider'

function AlertDialog() {
  const { t } = useTranslation()
  const alert = useContext(AlertContext)

  const renderIcon = () => {
    switch (alert?.alertType) {
      case 'success':
        return <CheckAltIcon className="h-5 text-success" />
      case 'error':
        return <ExclamationCircleIcon className="h-8 text-error" />
      default:
        return null
    }
  }

  return (
    <Fragment>
      {alert && (
        <Transition appear show={alert.show} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => alert.clear()}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className=" fixed inset-0 bg-gray-900/25" />
            </Transition.Child>
            <div className="fixed inset-0 overflow-y-auto" data-testid="alert-dialog">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="flex w-full max-w-md flex-col items-center overflow-hidden rounded-2xl bg-white p-6 shadow-xl transition-all">
                    <div
                      className={classNames(
                        'flex',
                        'h-12',
                        'w-12',
                        'items-center',
                        'justify-center',
                        'rounded-full',
                        'bg-opacity-[0.15]',
                        {
                          'bg-success': alert.alertType === 'success',
                          'bg-error': alert.alertType === 'error',
                        }
                      )}
                    >
                      {renderIcon()}
                    </div>
                    <Dialog.Title as="h3" className="mt-5 text-center text-lg font-medium leading-6 text-gray-900">
                      {alert.title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{alert.message}</p>
                    </div>
                    {alert.errorCode && (
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {t('alert.error.errorCode', {
                            code: alert.errorCode,
                          })}
                        </p>
                      </div>
                    )}
                    <div className="mt-4 w-full">
                      <Button
                        type="button"
                        data-testid="alert-close-button"
                        className={classNames(
                          'inline-flex',
                          'w-full',
                          'justify-center',
                          'rounded-md',
                          'border',
                          'border-transparent',
                          'px-4',
                          'py-2',
                          'text-sm',
                          'text-white',
                          {
                            'bg-primary': alert.alertType === 'success',
                            'bg-error': alert.alertType === 'error',
                          }
                        )}
                        onClick={() => {
                          if (alert.onButtonClick) {
                            alert.onButtonClick()
                          } else {
                            alert.clear()
                          }
                        }}
                      >
                        {alert.buttonText}
                      </Button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </Fragment>
  )
}

export default AlertDialog
