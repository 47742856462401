import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { GetCurrentUserQuery } from '@/graphql/access/generated/access_graphql'
import { GetCurrentPurchaserQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

interface UserDetailsProps extends PropsWithChildren {
  customStyles?: string
  currentUser: GetCurrentUserQuery['currentUser']
  currentPurchaser: GetCurrentPurchaserQuery['currentPurchaser']
}

export default function UserDetails({ children, customStyles, currentUser, currentPurchaser }: UserDetailsProps) {
  const { t } = useTranslation()
  return (
    <Menu as="div" className={classNames('relative inline-block text-left', customStyles || '')}>
      <Menu.Button className="flex h-11 items-center rounded-md bg-gray-100 px-2 py-1 hover:bg-gray-200">
        {children}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-auto origin-top-right divide-y divide-gray-100 rounded-md border bg-white shadow-lg focus:outline-none">
          <div className="py-1" data-testid="user-details">
            <Menu.Item>
              <div>
                <p className="block px-4 pt-2 text-xxs">{t('access.signedInAs')}</p>
                <p className="block px-4 text-sm">{currentUser?.fullName}</p>
                <p className="block px-4 text-sm">{currentPurchaser?.name}</p>
                <p className="block px-4 pb-2 text-sm font-semibold">{currentUser?.email}</p>
              </div>
            </Menu.Item>
          </div>
          {/* TODO: temporary hide signout button, will be added later with a ticket */}
          {/* <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  type="submit"
                  className={cn(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block w-full px-4 py-2 text-left text-sm'
                  )}
                >
                  Sign out
                </button>
              )}
            </Menu.Item>
          </div> */}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
