import { graphql } from 'msw'

import { GetCurrentUserQuery } from '@/graphql/access/generated/access_graphql'

export const GetCurrentUser = graphql.query<GetCurrentUserQuery>('GetCurrentUser', (req, res, ctx) => {
  return res(
    ctx.data({
      currentUser: {
        id: 23783,
        email: 'duong.nguyen@marketboomer.com',
        fullName: 'Duong Nguyen',
        isAdmin: true,
        __typename: 'User',
      },
    })
  )
})
