import { Helmet } from 'react-helmet-async'
import { Trans, useTranslation } from 'react-i18next'

import { useCurrentUser } from '@/modules/access/hooks'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks'
import { Header } from '@/modules/requisitions/layouts'
import { SUPPORT_LINK } from '@/modules/shared/constants'

function AccessDenied() {
  const { currentUser } = useCurrentUser()
  const { currentPurchaser } = useCurrentPurchaser()
  const { t } = useTranslation()
  return (
    <>
      <Helmet>
        <title>{t('access.accessNotPermitted')}</title>
      </Helmet>
      <Header />
      <div className="mx-auto w-full py-16 px-4 sm:px-8 lg:w-[500px]">
        <div className="flex flex-col items-center">
          <h1 className="my-3 text-center text-xl font-bold">{t('access.accessNotPermitted')}</h1>
          <p className="text-center text-sm text-gray-500">
            <Trans
              t={t}
              i18nKey="access.notPermittedMessage"
              components={[
                <a
                  data-testid="view-support-link"
                  href={SUPPORT_LINK}
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary"
                >
                  {''}
                </a>,
              ]}
            />
          </p>
          <div data-testid="user-details" className=" mt-8 flex flex-col items-center text-center">
            {[
              { key: 'access.currentUser', value: currentUser?.fullName },
              { key: 'access.emailAddress', value: currentUser?.email },
              { key: 'access.organisation', value: currentPurchaser?.name },
            ].map((e) => {
              return (
                <div key={e.key} className="flex">
                  <p className="text-sm text-gray-500">{t(e.key)}:</p>
                  <p className="ml-2 text-sm text-gray-500">{e.value}</p>
                </div>
              )
            })}
          </div>
          <a
            className="mt-8 w-full cursor-pointer rounded-md bg-gray-200 p-3 text-center text-sm transition hover:brightness-95"
            data-testid="view-pplusenterprise-link"
            href={process.env.REACT_APP_NINJA_API_HOST}
          >
            {t('access.goToPPlusEnterprise')}
          </a>
        </div>
      </div>
    </>
  )
}

export default AccessDenied
