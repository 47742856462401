import { ApolloError, ServerError } from '@apollo/client'
import { Fragment, PropsWithChildren } from 'react'

import { Spinner } from '@/modules/requisitions/components'

interface QueryResultProps extends PropsWithChildren {
  loading?: boolean
  error?: ApolloError | undefined
}

function QueryResult(props: QueryResultProps) {
  const { children, error, loading } = props

  const networkError = error?.networkError as ServerError

  if (networkError?.statusCode === 401) {
    return null
  }

  if (error) {
    return (
      <div className="mt-12">
        <div className="text-center">
          <h1>{error.message}</h1>
        </div>
      </div>
    )
  }

  if (loading) {
    return <Spinner className="mt-12 h-28 md:h-32" />
  }

  return <Fragment>{children}</Fragment>
}

export default QueryResult
