import { useMutation } from '@apollo/client'
import { Menu, Transition } from '@headlessui/react'
import { LifebuoyIcon, XCircleIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { Fragment, PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { GetCurrentUserQuery } from '@/graphql/access/generated/access_graphql'
import { CancelRequisitionDocument, GetCurrentPurchaserQuery } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Themes } from '@/modules/requisitions/types'
import { ConfirmDialog } from '@/modules/shared/components'
import { Button } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API, SUPPORT_LINK } from '@/modules/shared/constants'
import { NewTabIcon } from '@/modules/shared/icons'

interface Props extends PropsWithChildren {
  currentUser: GetCurrentUserQuery['currentUser']
  currentPurchaser: GetCurrentPurchaserQuery['currentPurchaser']
}

export default function OptionDetails({ children, currentUser, currentPurchaser }: Props) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  let { requisitionId } = useParams()
  const [cancelRequistion] = useMutation(CancelRequisitionDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const enterpriseRequisitionUrl = `${process.env.REACT_APP_NINJA_API_HOST}/purchasing/en/requisitions/${requisitionId}`

  const cancelRequisition = () => {
    cancelRequistion({
      variables: { input: { id: Number(requisitionId) } },
      context: {
        uri: PURCHASING_GRAPHQL_API,
      },
      onCompleted() {
        setIsOpen(false)
        window.location.assign(`${process.env.REACT_APP_NINJA_API_HOST}/purchasing/en/requisitions/${requisitionId}`)
      },
      onError() {
        setIsOpen(false)
      },
    })
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="flex h-11 items-center gap-3 rounded-md bg-gray-100 px-4 py-1 hover:bg-gray-200">
        {children}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-auto origin-top-right divide-y divide-gray-100 rounded-md border bg-white shadow-lg focus:outline-none">
          <div className="py-1" data-testid="menu-details">
            <Menu.Item>
              <div>
                <p className="block px-4 pt-2 text-xxs">{t('access.signedInAs')}</p>
                <p className="block px-4 text-sm">{currentUser?.fullName}</p>
                <p className="block px-4 text-sm">{currentPurchaser?.name}</p>
                <p className="block px-4 pb-2 text-sm font-semibold">{currentUser?.email}</p>
              </div>
            </Menu.Item>
          </div>
          <div className="py-1" data-testid="menu-details-actions">
            <Menu.Item>
              {({ active }) => (
                <a
                  className={classNames('flex w-full items-center px-4 py-2 text-left text-sm text-gray-700', {
                    'bg-gray-100 text-gray-900': active,
                  })}
                  target="_blank"
                  href={enterpriseRequisitionUrl}
                  rel="noreferrer"
                >
                  <NewTabIcon className="mr-2 h-5 w-5" />
                  {t('requisition.openInP+Enterprise')}
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Button
                  className={classNames('flex w-full items-center px-4 py-2 text-left text-sm text-gray-700', {
                    'bg-gray-100 text-gray-900': active,
                  })}
                  onClick={() => setIsOpen(true)}
                >
                  <XCircleIcon className="mr-2 h-5 w-5" />
                  {t('access.shop.action.cancel')}
                </Button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  className={classNames(
                    'flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition',
                    {
                      'bg-gray-100 text-gray-900': active,
                    }
                  )}
                  href={SUPPORT_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  <LifebuoyIcon className="mr-2 h-5 w-5" />
                  {t('access.shop.action.help')}
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
      <ConfirmDialog
        title={t('access.shop.confirm.cancelRequisition')}
        description={t('access.shop.confirm.cancelDescription')}
        mainButtonLabel={t('access.shop.action.cancel')}
        secondaryButtonLabel={t('access.shop.action.continue')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onConfirm={cancelRequisition}
        theme={Themes.error}
      />
    </Menu>
  )
}
