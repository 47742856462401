import { useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import DeclineForm from './DeclineForm'

import { GetRequisitionDocument, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ApprovalItem } from '@/modules/requisitions/components'
import QueryResult from '@/modules/shared/components/query-result'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { ChevronLeftIcon } from '@/modules/shared/icons'

export default function Decline() {
  const { t } = useTranslation()
  const { requisitionId } = useParams<{ requisitionId: string }>()

  const { data, loading, error } = useQuery(GetRequisitionDocument, {
    variables: {
      requisitionId: Number(requisitionId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  return (
    <>
      <Helmet>
        <title>
          {t('declineRequisition.confirmDeclineRequisitionPageTitle', {
            requisitionNumber: data?.currentPurchaser?.requisition?.requisitionNumber,
          })}
        </title>
      </Helmet>
      <header className="px-4 py-3">
        <Link
          data-testid="action-return"
          to="/requisitions/awaiting-my-approval"
          className="flex w-fit items-center justify-between gap-x-2 rounded-md bg-gray-200 px-4 py-3 transition hover:brightness-95"
        >
          <ChevronLeftIcon className="-ml-2 h-5 w-5" />
          <p className="text-sm">{t('declineRequisition.form.cancelAndReturn')}</p>
        </Link>
      </header>
      <div data-testid="approval-list" className="mx-auto w-full py-10 px-4 lg:w-[698px]">
        <QueryResult loading={loading} error={error}>
          <div>
            {requisitionId && data?.currentPurchaser && (
              <>
                <div className="flex flex-col">
                  <h1 className="text-center text-xl font-bold">
                    {t('declineRequisition.declineRequisitionNumber', {
                      requisitionNumber: data.currentPurchaser.requisition?.requisitionNumber,
                    })}
                    ?
                  </h1>
                  <p className="mt-2 text-center text-sm text-gray-500">{t('declineRequisition.areYouSure')}</p>
                </div>
                <div className="mt-8">
                  <ApprovalItem requisition={data.currentPurchaser.requisition as Requisition} />
                </div>
                <DeclineForm requisitionId={parseInt(requisitionId)} />
              </>
            )}
          </div>
        </QueryResult>
      </div>
    </>
  )
}
