import { NetworkStatus, useQuery } from '@apollo/client'
import { useState } from 'react'
import { Control, Controller, FieldError, UseFormGetValues, UseFormResetField } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Account,
  GetAccountsDocument,
  GetDepartmentsDocument,
  Purchaser,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { RequisitionFormInputs } from '@/modules/requisitions/types'
import { ComboboxClient } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

interface Props {
  control: Control<RequisitionFormInputs, unknown>
  departmentError?: FieldError
  accountError?: FieldError
  defaultDepartmentValue?: Purchaser
  defaultAccountValue?: Account
  resetField: UseFormResetField<RequisitionFormInputs>
  getValues: UseFormGetValues<RequisitionFormInputs>
}

export default function DeptAndAccount({
  control,
  departmentError,
  accountError,
  resetField,
  getValues,
  defaultAccountValue,
  defaultDepartmentValue,
}: Props) {
  const [resetAccount, setResetAccount] = useState(0)
  const { t } = useTranslation()

  const { data: departmentsData, loading: fetchingDepartments } = useQuery(GetDepartmentsDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const {
    data: accountsData,
    networkStatus,
    refetch: refetchingAccounts,
  } = useQuery(GetAccountsDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
  })

  return (
    <section className="mt-8">
      <h2 className="text-sm font-semibold">{t('createRequisition.deptAndAccount.title')}</h2>
      <p className="text-sm text-gray-500">{t('createRequisition.deptAndAccount.explain')}</p>
      <div className="mt-2">
        <Controller
          control={control}
          name="departmentId"
          rules={{ required: true }}
          render={({ field }) => (
            <ComboboxClient
              defaultValue={defaultDepartmentValue}
              loading={fetchingDepartments}
              placeholder={t('createRequisition.deptAndAccount.placeholder.dept')}
              keyFilter="name"
              keyExtractor={(e) => String(e.id)}
              items={departmentsData?.currentPurchaser?.departments as Purchaser[]}
              onSelected={(e) => {
                field.onChange(e.id)
                resetField('accountId')
                refetchingAccounts({ deparmentId: getValues('departmentId') })
                setResetAccount(e.id)
              }}
              hasError={Boolean(departmentError)}
            />
          )}
        />
        <div className="h-2" />
        <Controller
          control={control}
          name="accountId"
          rules={{ required: true }}
          render={({ field }) => (
            <ComboboxClient
              defaultValue={defaultAccountValue}
              loading={networkStatus === NetworkStatus.setVariables}
              disabled={!getValues('departmentId')}
              placeholder={t('createRequisition.deptAndAccount.placeholder.account')}
              keyFilter="accountName"
              keyExtractor={(e) => String(e.id)}
              items={accountsData?.purchaser?.accountsInTree as Account[]}
              onSelected={(e) => field.onChange(e.id)}
              hasError={Boolean(accountError)}
              errorMessage={t('createRequisition.deptAndAccount.required.account')}
              resetSelectItem={resetAccount}
            />
          )}
        />
      </div>
    </section>
  )
}
