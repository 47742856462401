import { graphql } from 'msw'

import { SubmitRequisitionMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const SubmitRequisition = graphql.mutation<SubmitRequisitionMutation>('SubmitRequisition', (_, res, ctx) => {
  return res(
    ctx.data({
      submitRequisition: {
        requisition: {
          id: 5235941,
          state: 'awaiting_approval',
          requisitionNumber: 'PR5235941',
        },
      },
    })
  )
})
