import { useMutation } from '@apollo/client'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'

import {
  DeleteRequisitionLineDocument,
  UpdateRequisitionLineDocument,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useDebounce } from '@/modules/shared/hooks'

interface StepperProps {
  lineId: number
  productId: number
  quantity: number
}

function Stepper({ lineId, productId, quantity }: StepperProps) {
  const [qtyInput, setQtyInput] = useState(quantity)
  const debouncedQtyInput = useDebounce(qtyInput, 1000)
  const [updateRequisitionLine, { loading: loadingUpdate }] = useMutation(UpdateRequisitionLineDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })
  const [deleteRequisitionLine, { loading: loadingDelete }] = useMutation(DeleteRequisitionLineDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const onUpdateRequisitionLine = (quantity: number) => {
    updateRequisitionLine({
      variables: {
        input: {
          id: lineId,
          productId,
          quantity,
        },
      },
    })
  }

  const onDeleteRequisitionLine = () => {
    deleteRequisitionLine({
      variables: {
        input: {
          id: lineId,
        },
      },
    })
  }

  useEffect(() => {
    if (debouncedQtyInput !== quantity) {
      if (debouncedQtyInput === 0) {
        onDeleteRequisitionLine()
      } else {
        onUpdateRequisitionLine(debouncedQtyInput)
      }
    }
  }, [debouncedQtyInput])

  return (
    <section className="flex items-center justify-between gap-2">
      <Button
        data-testid="stepper-action-decrease"
        className="flex h-10 w-10 items-center justify-center rounded-full bg-primary text-white"
        onClick={() => {
          if (quantity === 1) {
            onDeleteRequisitionLine()
          } else {
            onUpdateRequisitionLine(quantity - 1)
            setQtyInput(quantity - 1)
          }
        }}
        disabled={loadingDelete || loadingUpdate}
      >
        <MinusIcon className="h-6 w-6" />
      </Button>
      <input
        data-testid="stepper-action-input"
        className="h-10 w-14 border-gray-300 text-center focus:border-primary focus:ring-primary"
        type="text"
        pattern="\d*"
        value={qtyInput}
        onChange={(e) => {
          const newQuantity = Number(e.target.value)
          if (newQuantity < 1000) {
            setQtyInput(newQuantity)
          }
        }}
      />
      <Button
        data-testid="stepper-action-increase"
        className="flex h-10 w-10 items-center justify-center rounded-full bg-primary text-white"
        onClick={() => {
          onUpdateRequisitionLine(quantity + 1)
          setQtyInput(quantity + 1)
        }}
        disabled={loadingDelete || loadingUpdate}
      >
        <PlusIcon className="h-6 w-6" />
      </Button>
    </section>
  )
}

export default Stepper
