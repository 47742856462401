import { Combobox } from '@headlessui/react'
import classNames from 'classnames'
import { useEffect, useState } from 'react'

import { ComboboxBaseProps } from '@/modules/shared/components/combobox/types'
import { CheckAltIcon, ChevronDownIcon, Loading } from '@/modules/shared/icons'

interface ComboboxClientProps<DataType> extends ComboboxBaseProps<DataType> {
  keyFilter: keyof DataType
  resetSelectItem?: number
}

function ComboboxClient<T>(props: ComboboxClientProps<T>) {
  const {
    placeholder,
    keyFilter,
    keyExtractor,
    loading,
    disabled,
    className,
    onSelected,
    defaultValue,
    hasError,
    errorMessage,
    items = [],
    testId,
    resetSelectItem,
  } = props

  const [query, setQuery] = useState('')
  const [inputFocus, setInputFocus] = useState(false)
  const [selectedItem, setSelectedItem] = useState<T | null>()

  const filteredItems = () => {
    if (!query) return items

    return items.filter((item) => {
      const val = String(item[keyFilter])
      return val.toLowerCase().includes(query.toLowerCase())
    })
  }

  const getSelectedItem = (item: T) => {
    return item && String(item[keyFilter])
  }

  useEffect(() => {
    if (selectedItem && inputFocus) {
      onSelected(selectedItem)
    }
  }, [selectedItem])

  useEffect(() => {
    if (defaultValue) {
      setSelectedItem(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    if (resetSelectItem) {
      setSelectedItem(null)
    }
  }, [resetSelectItem])

  return (
    <Combobox
      as="div"
      className={classNames(className, 'w-full')}
      disabled={disabled || loading}
      value={selectedItem}
      onChange={setSelectedItem}
    >
      <div className={classNames('relative', 'mt-1', { 'pb-1': hasError })}>
        <Combobox.Input
          autoComplete="off"
          data-testid={`combobox-input${testId ? `-${testId}` : ''}`}
          className={classNames(
            'w-full rounded-md border border-gray-300 bg-white py-3 pl-3 pr-10 shadow-sm transition focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm',
            {
              'bg-gray-200/50': disabled || loading,
              'text-gray-300': disabled || loading,
              'cursor-not-allowed': disabled,
              'border-error': hasError,
            }
          )}
          onChange={(event) => setQuery(event.target.value)}
          onFocus={() => setInputFocus(true)}
          placeholder={placeholder}
          displayValue={(item: T) => getSelectedItem(item)}
        />
        <Combobox.Button
          data-testid={`combobox-button${testId ? `-${testId}` : ''}`}
          className={classNames('absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none', {
            'cursor-not-allowed': disabled,
          })}
        >
          {loading ? (
            <Loading className="h-5 w-5 fill-white text-gray-300" />
          ) : (
            <ChevronDownIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
          )}
        </Combobox.Button>

        {filteredItems().length > 0 && (
          <Combobox.Options
            data-testid={`options-wrapper${testId ? `-${testId}` : ''}`}
            className="absolute z-10 mt-1 max-h-72 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-primary/5 focus:outline-none sm:text-sm"
          >
            {filteredItems().map((item) => (
              <Combobox.Option
                data-testid={`option${testId ? `-${testId}` : ''}-${keyExtractor(item)}`}
                key={keyExtractor(item)}
                value={item}
                className={({ active }) =>
                  classNames('relative cursor-default select-none py-2 pl-3 pr-9', {
                    'bg-primary text-white': active,
                    'text-gray-900': !active,
                  })
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames('block truncate ', {
                        'font-semibold ': selected,
                      })}
                    >
                      {getSelectedItem(item)}
                    </span>

                    {selected && (
                      <span
                        className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', {
                          'text-white': active,
                          'text-primary': !active,
                        })}
                      >
                        <CheckAltIcon className="h-4 w-4" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
      {hasError && <span className="text-sm text-error">{errorMessage}</span>}
    </Combobox>
  )
}

export default ComboboxClient
