import { useTranslation } from 'react-i18next'

import { REQUISITION_STATUS } from '@/modules/requisitions/types'

interface NextApproverMessageProps {
  state: REQUISITION_STATUS
}

function NextApproverMessage(props: NextApproverMessageProps) {
  const { state } = props
  const { t } = useTranslation()

  const hasNextApprovers = state === REQUISITION_STATUS.AWAITING_APPROVAL
  const noNextApprovers = state === REQUISITION_STATUS.APPROVED

  if (hasNextApprovers) {
    return (
      <>
        <h1 className="my-3 text-center text-2xl font-bold">
          {t('confirmation.titleSuccessApproveWithNextApprovers')}
        </h1>
        <p className=" mb-8 w-full px-4 text-center text-sm text-gray-500  lg:px-0">
          {t('confirmation.messageSuccessApproveWithNextApprovers')}
        </p>
      </>
    )
  }

  if (noNextApprovers) {
    return (
      <>
        <h1 className="my-3 text-center text-2xl font-bold">
          {t('confirmation.titleSuccessApproveWithNoNextApprovers')}
        </h1>
        <p className=" mb-8 w-full px-4 text-center text-sm text-gray-500 ">
          {t('confirmation.messageSuccessApproveWithNoNextApprovers')}
        </p>
      </>
    )
  }

  return <></>
}

export default NextApproverMessage
