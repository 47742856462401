import { GetCurrentUser } from './access/GetCurrentUser'
import { ApproveRequisition } from './purchasing/ApproveRequisition'
import { CreateRequisitionLine } from './purchasing/CreateRequisitionLine'
import { DeclineRequisition } from './purchasing/DeclineRequisition'
import { DeleteRequisitionLine } from './purchasing/DeleteRequisitionLine'
import { GetRequisition } from './purchasing/GetRequisition'
import { GetRequisitionsAwaitingApproval } from './purchasing/GetRequisitionsAwaitingApproval'
import { SubmitRequisition } from './purchasing/SubmitRequisition'
import { UpdateRequisition } from './purchasing/UpdateRequisition'
import { UpdateRequisitionLine } from './purchasing/UpdateRequisitionLine'
import { GetCurrentPurchaser } from './purchasing/getCurrentPurchaser'
import { getPossibleRequisitionLines } from './purchasing/getPossibleRequisitionLines'
import { GetRequisitionsDraft } from './purchasing/getRequisitionsDraft'

export const handlers = [
  GetCurrentUser,
  GetRequisitionsAwaitingApproval,
  GetRequisition,
  DeclineRequisition,
  ApproveRequisition,
  GetRequisitionsDraft,
  getPossibleRequisitionLines,
  GetCurrentPurchaser,
  SubmitRequisition,
  UpdateRequisition,
  CreateRequisitionLine,
  DeleteRequisitionLine,
  UpdateRequisitionLine,
]
