import classNames from 'classnames'
import { PropsWithChildren } from 'react'

interface BadgeProps extends PropsWithChildren {
  className?: string
  theme?: 'primary' | 'success' | 'error' | 'warning'
}

function Badge(props: BadgeProps) {
  const { children, className, theme } = props

  return (
    <span
      className={classNames(className, ' mr-2 rounded-full px-3 py-1 text-xs', {
        'bg-primary/20 text-primary': theme === 'primary',
        'bg-success/20 text-success': theme === 'success',
        'bg-error/20 text-error': theme === 'error',
        'bg-warning/20 text-warning': theme === 'warning',
      })}
    >
      {children}
    </span>
  )
}

export default Badge
