export default function OptionIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      fill="currentColor"
      viewBox="0 0 500 500"
      {...props}
    >
      <circle cx="250" cy="115.42" r="44.86" transform="translate(-17.32 49.36) rotate(-10.9)" />
      <circle cx="250" cy="250" r="44.86" transform="translate(-76.64 385.3) rotate(-67.5)" />
      <circle cx="250" cy="384.58" r="44.86" transform="translate(-208.58 395.06) rotate(-58.28)" />
    </svg>
  )
}
