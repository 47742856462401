import { Control, FieldErrorsImpl, UseFormGetValues, UseFormResetField } from 'react-hook-form'

import {
  DeliveryDate,
  DeptAndAccount,
  SelectAddress,
  SelectSupplier,
  SendPurchaseOrder,
} from '@/modules/requisitions/pages/create/form-elements'
import { RequisitionFormInputs } from '@/modules/requisitions/types'

interface Props {
  control: Control<RequisitionFormInputs, unknown>
  errors: FieldErrorsImpl<RequisitionFormInputs>
  resetField: UseFormResetField<RequisitionFormInputs>
  getValues: UseFormGetValues<RequisitionFormInputs>
}

export default function SingleRequisition({ control, errors, getValues, resetField }: Props) {
  return (
    <>
      <SelectSupplier control={control} error={errors.originId} />
      <SendPurchaseOrder control={control} />
      <SelectAddress control={control} error={errors.deliveryAddressId} />
      <DeliveryDate control={control} />
      <DeptAndAccount
        control={control}
        departmentError={errors.departmentId}
        accountError={errors.accountId}
        resetField={resetField}
        getValues={getValues}
      />
    </>
  )
}
