import { useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import { GetRequisitionDocument, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ApprovalItem, NextApproverMessage } from '@/modules/requisitions/components'
import { REQUISITION_STATUS } from '@/modules/requisitions/types'
import { QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { CheckIcon, ChevronLeftIcon } from '@/modules/shared/icons'

interface ConfirmationProps {
  variant: 'approve' | 'decline' | 'submit'
}

export default function Confirmation(props: ConfirmationProps) {
  const { variant } = props
  const { t } = useTranslation()

  const { requisitionId } = useParams<{ requisitionId: string }>()

  const { data, loading, error } = useQuery(GetRequisitionDocument, {
    variables: {
      requisitionId: Number(requisitionId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const pageVariant = () => {
    let key = ''
    if (variant === 'approve') key = 'Approved'
    if (variant === 'decline') key = 'Declined'
    if (variant === 'submit') key = 'Submitted'
    return key
  }

  return (
    <>
      <Helmet>
        <title>
          {t(`confirmation.requisition${pageVariant()}PageTitle`, {
            requisitionNumber: data?.currentPurchaser?.requisition?.requisitionNumber,
          })}
        </title>
      </Helmet>
      <header className="px-4 py-3">
        <Link
          data-testid="action-return"
          to={variant === 'submit' ? '/' : '/requisitions/awaiting-my-approval'}
          className="flex w-fit items-center justify-between gap-x-2 rounded-md bg-gray-200 px-4 py-3 transition hover:brightness-95"
        >
          <ChevronLeftIcon className="-ml-2 h-5 w-5" />
          {variant === 'submit' && <p className="text-sm">{t('confirmation.returnHome')}</p>}
          {(variant === 'approve' || variant === 'decline') && (
            <p className="text-sm">{t('confirmation.returnToList')}</p>
          )}
        </Link>
      </header>
      <div className="mx-auto w-full py-9 px-4 lg:w-[698px]">
        <div className="flex flex-col items-center justify-center">
          {variant === 'decline' && (
            <>
              <h1 className="my-3 text-center text-2xl font-bold">
                {t('confirmation.titleDeclineSuccessfully', {
                  requisitionNumber: data?.currentPurchaser?.requisition?.requisitionNumber,
                })}
              </h1>
              <p className="mb-8 w-full px-4 text-center text-sm text-gray-500 lg:w-[590px]">
                {t('confirmation.messageDeclineSuccessfully')}
              </p>
            </>
          )}
          {variant === 'approve' && (
            <>
              <CheckIcon className="h-16 w-16 text-success" />
              <NextApproverMessage state={data?.currentPurchaser?.requisition?.state as REQUISITION_STATUS} />
            </>
          )}
          {variant === 'submit' && (
            <>
              <div className="mb-5 flex flex-col items-center">
                <CheckIcon className="mb-5 h-16 w-16 text-success" />
                <h1 className="text-center text-2xl font-bold">
                  {t('confirmRequisition.success', {
                    requisitionNumber: data?.currentPurchaser?.requisition?.requisitionNumber,
                  })}
                </h1>
                <p className="mt-2 text-center text-sm text-gray-500">
                  {t('confirmRequisition.successText', {
                    requisitionNumber: data?.currentPurchaser?.requisition?.requisitionNumber,
                  })}
                </p>
              </div>
            </>
          )}
          <QueryResult loading={loading} error={error}>
            {data && (
              <>
                <ApprovalItem className="w-full" requisition={data.currentPurchaser?.requisition as Requisition} />
              </>
            )}
          </QueryResult>
        </div>
      </div>
    </>
  )
}
