import { Outlet } from 'react-router-dom'

import { useCurrentUser } from '@/modules/access/hooks'
import { Header } from '@/modules/requisitions/layouts'
import { AccessDenied } from '@/modules/shared/pages'
import { Home } from '@/modules/shared/pages'

function AdminRoute() {
  const { currentUser, loading } = useCurrentUser()
  if (currentUser) {
    if (!currentUser.isAdmin) return <AccessDenied />
    return <Outlet />
  }

  return loading ? <Header /> : <Home />
}

export default AdminRoute
