export enum REQUISITION_STATUS {
  OPEN = 'open',
  AWAITING_APPROVAL = 'awaiting_approval',
  APPROVED = 'approved',
  DECLINED = 'declined',
  CLOSED = 'closed',
  CANCELLED = 'cancelled',
}

export enum PRODUCT_VIEW {
  TILE_VIEW,
  LIST_VIEW,
}

export type RequisitionFormInputs = {
  originId: number
  reference: string
  deliveryAddressId: number
  sendToSupplier: boolean
  departmentId: number
  accountId: number
  expectedDeliveryDate: string
}

export type RequisitionType = {
  id: number
  name: string
  desc: string
  icon: JSX.Element
  isReleased: boolean
  originType: string
} | null

export enum Themes {
  success,
  error,
}

export type RequisitionLine = {
  id: number
  productId: number
}
