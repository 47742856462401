import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Link, generatePath, useParams } from 'react-router-dom'

import { GetRequisitionDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useMoney } from '@/modules/shared/hooks'
import { CartIcon, ChevronRightIcon } from '@/modules/shared/icons'

function ViewCartHeader() {
  const { format } = useMoney()
  const { t } = useTranslation()

  const { requisitionId } = useParams<{ requisitionId: string }>()
  const { data } = useQuery(GetRequisitionDocument, {
    variables: {
      requisitionId: Number(requisitionId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  return (
    <section className="flex flex-col justify-between gap-y-3 rounded-md border bg-white p-4 shadow-sm md:flex-row">
      <div className="flex gap-4">
        <CartIcon className="h-12 w-12" />
        <div className="border-l pl-4">
          <p className="text-xl font-bold" data-testid="cart-value">
            {format(data?.currentPurchaser?.requisition?.totalValue || 0)}
          </p>
          <p className="text-sm">{t('requisitionInformation.cart.totalWithTax')}</p>
        </div>
      </div>
      <div className="flex flex-col gap-y-2 md:flex-row md:gap-2">
        <Link
          data-testid="continute-shopping-btn"
          to=""
          className="flex items-center justify-between gap-2 rounded-md bg-gray-200 px-4 py-2.5 text-sm hover:brightness-95"
        >
          <span>{t('requisitionInformation.cart.continueShopping')}</span>
          <CartIcon className="h-6 w-6" />
        </Link>
        <Link
          data-testid="submit-requisition-btn"
          to={generatePath(`/requisitions/:id/submit`, {
            id: String(requisitionId),
          })}
          className="flex items-center justify-between gap-1 rounded-md bg-primary px-4 py-2.5 text-sm text-white hover:brightness-95"
        >
          <span>{t('requisitionInformation.cart.submitRequisition')}</span>
          <ChevronRightIcon className="h-6 w-6" />
        </Link>
      </div>
    </section>
  )
}

export default ViewCartHeader
