import { CalendarDate } from '@internationalized/date'
import classNames from 'classnames'
import { useContext, useRef } from 'react'
import { mergeProps, useCalendarCell, useFocusRing } from 'react-aria'
import { CalendarState } from 'react-stately'

import { CalendarContext } from './index'

interface CalendarCellProps {
  state: CalendarState
  date: CalendarDate
}

function CalendarCell({ state, date }: CalendarCellProps) {
  const ref = useRef(null)
  const { cellProps, buttonProps, isSelected, isOutsideVisibleRange, isDisabled, formattedDate, isInvalid } =
    useCalendarCell({ date }, state, ref)
  const { focusProps, isFocusVisible } = useFocusRing()
  const onCloseCalendar = useContext(CalendarContext)

  return (
    <td {...cellProps} className={`relative py-0.5 ${isFocusVisible ? 'z-10' : 'z-0'}`}>
      <div
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        hidden={isOutsideVisibleRange}
        className={classNames('group h-10 w-10 rounded-full outline-none hover:bg-gray-200', {
          'bg-primary text-white': isSelected,
        })}
      >
        <div
          className={classNames('flex h-full w-full items-center justify-center rounded-full', {
            'text-gray-300': isDisabled && !isInvalid,
          })}
          onClick={() => onCloseCalendar()}
        >
          {formattedDate}
        </div>
      </div>
    </td>
  )
}

export default CalendarCell
