import { useTranslation } from 'react-i18next'

import EmptyStateImg from '@/assets/images/empty-requisitions-stars.svg'

export default function EmptyState() {
  const { t } = useTranslation()
  return (
    <div className="mb-3 flex flex-col items-center justify-center ">
      <img src={EmptyStateImg} className="h-64 md:h-72" alt={t('requisition.allDone')} />
      <div className="text-center">
        <span className="text-xs font-bold md:text-sm">{t('requisition.allDone')}</span>
        <p className="text-xs text-gray-500 md:text-sm">{t('requisition.noPRsRemaining')}</p>
      </div>
    </div>
  )
}
