import * as RadixTooltip from '@radix-ui/react-tooltip'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'

interface TooltipProps extends PropsWithChildren<RadixTooltip.TooltipContentProps> {
  content: string
  delay?: number
  showArrow?: boolean
}

function Tooltip(props: TooltipProps) {
  const { sideOffset = 4, delay = 500, content, children, className, showArrow = false, ...restProps } = props

  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root delayDuration={delay}>
        <RadixTooltip.TooltipTrigger asChild>{children}</RadixTooltip.TooltipTrigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            {...restProps}
            sideOffset={sideOffset}
            className={classNames(
              className,
              'radix-side-top:animate-slide-down-fade',
              'radix-side-right:animate-slide-left-fade',
              'radix-side-bottom:animate-slide-up-fade',
              'radix-side-left:animate-slide-right-fade',
              'inline-flex items-center rounded-md p-2',
              'max-w-[256px] break-words bg-gray-700 text-center'
            )}
          >
            {showArrow && <RadixTooltip.Arrow className="fill-gray-700" />}
            <span className="block text-xxs text-gray-100">{content}</span>
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  )
}

export default Tooltip
