import { graphql } from 'msw'

import { GetRequisitionsDraftQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

export const GetRequisitionsDraft = graphql.query<GetRequisitionsDraftQuery>('GetRequisitionsDraft', (_, res, ctx) => {
  return res(
    ctx.data({
      currentPurchaser: {
        id: 23783,
        requisitions: {
          edges: [
            {
              node: {
                id: 5235941,
                totalValue: 104.5,
                requisitionNumber: 'PR04207',
                state: 'open',
                originName: 'Beverage - Bar',
                creator: {
                  id: 1234,
                  fullName: 'Johan W. Baaij',
                },
                createdAt: '2022-07-08T12:49:06+10:00',
                totalValueExcludingTax: 48.96,
                totalTaxValue: 4.896,
                reference: 'reference',
                sendToSupplier: true,
                approvalWorkflow: {
                  title: 'Demo',
                },
                department: {
                  name: 'AStore Shop Demo',
                },
                account: {
                  id: 452123,
                  accountName: '0060110 - Tickets for Attractions',
                  name: 'Tickets for Attractions',
                },
                nextApprovalUsers: [],
                supplierReference: null,
              },
            },
            {
              node: {
                id: 31233,
                totalValue: 122.5,
                requisitionNumber: 'PR04241',
                state: 'open',
                originName: 'Basement',
                creator: {
                  id: 1234,
                  fullName: 'Johan W. Baaij',
                },
                createdAt: '2022-04-02T12:49:06+10:00',
                totalValueExcludingTax: 48.96,
                totalTaxValue: 4.896,
                reference: 'reference',
                sendToSupplier: true,
                approvalWorkflow: {
                  title: 'Demo',
                },
                department: {
                  name: 'Basement',
                },
                account: {
                  id: 1248214,
                  accountName: '0060110 - Tickets for Attractions',
                  name: 'Tickets for Attractions',
                },
                nextApprovalUsers: [],
                supplierReference: null,
              },
            },
            {
              node: {
                id: 123456,
                totalValue: 6633,
                requisitionNumber: 'PR01203',
                state: 'open',
                originName: 'Beverage - Bar',
                creator: {
                  id: 1234,
                  fullName: 'Johan W. Baaij',
                },
                createdAt: '2022-07-08T12:49:06+10:00',
                totalValueExcludingTax: 48.96,
                totalTaxValue: 4.896,
                reference: 'reference',
                sendToSupplier: true,
                approvalWorkflow: {
                  title: 'Demo',
                },
                department: {
                  name: 'New Department',
                },
                account: {
                  id: 12384,
                  accountName: '0060110 - Tickets for Attractions',
                  name: 'Tickets for Attractions',
                },
                nextApprovalUsers: [],
                supplierReference: null,
              },
            },
          ],
          pageInfo: {
            endCursor: 'MTQ',
            hasNextPage: false,
            startCursor: 'MQ',
          },
        },
      },
    })
  )
})
