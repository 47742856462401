import { Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'

import ListView from './list-view'
import TileView from './tile-view'

import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Spinner } from '@/modules/requisitions/components'
import ProductListEmptyState from '@/modules/requisitions/components/product-list-empty-state'
import { PRODUCT_VIEW } from '@/modules/requisitions/types'
import { Button } from '@/modules/shared/components'
import { useDebounce, useWindowSize } from '@/modules/shared/hooks'
import { ControlBarIcon } from '@/modules/shared/icons'
import { BREAKPOINT } from '@/modules/shared/types'

interface ProductListProps {
  prNumber?: string
  prReference?: string
  productList: RequisitionLine[]
  lines: RequisitionLine[]
  onFetchMoreProduct: () => void
  onSearchProduct: (searchValue: string) => void
  searchProductLoading?: boolean
  fetchMoreProductLoading?: boolean
  hasMoreProduct: boolean
}

function ProductList(props: ProductListProps) {
  const {
    productList,
    lines,
    onFetchMoreProduct,
    onSearchProduct,
    fetchMoreProductLoading,
    searchProductLoading,
    hasMoreProduct,
    prNumber,
    prReference,
  } = props
  const { t } = useTranslation()
  const { ref, inView } = useInView({ threshold: 1 })
  const { width } = useWindowSize()
  const [searchValue, setSearchValue] = useState('')
  const [inputFocus, setInputFocus] = useState(false)
  const debouncedSearchValue = useDebounce(searchValue, 500)

  const [productView, setProductView] = useState<PRODUCT_VIEW>(PRODUCT_VIEW.TILE_VIEW)

  useEffect(() => {
    if (inView && hasMoreProduct) {
      onFetchMoreProduct()
    }
  }, [inView, hasMoreProduct])

  useEffect(() => {
    if (width <= BREAKPOINT.LG && productView === PRODUCT_VIEW.LIST_VIEW) {
      setProductView(PRODUCT_VIEW.TILE_VIEW)
    }
  }, [width])

  useEffect(() => {
    if (inputFocus) {
      // inputFocus will stop this onSearchProduct being called at the first render
      onSearchProduct(debouncedSearchValue)
    }
  }, [debouncedSearchValue])

  return (
    <>
      <div data-testid="requisition-info" className="flex items-center justify-between">
        <div className="hidden items-center sm:flex">
          <ControlBarIcon className=" h-11 w-11" />
          <p className="ml-3 text-sm">
            {prNumber} {prReference}
          </p>
        </div>
        <div data-testid="product-view-tabs" className="hidden gap-1 rounded-lg bg-gray-200 py-1 px-1.5 lg:flex">
          <Button
            className={classNames('px-5 py-2 text-sm ', {
              'pointer-events-none bg-white': productView === PRODUCT_VIEW.TILE_VIEW,
              'text-gray-500 hover:bg-gray-300': productView === PRODUCT_VIEW.LIST_VIEW,
            })}
            onClick={() => setProductView(PRODUCT_VIEW.TILE_VIEW)}
          >
            {t('shopPage.productList.tileViewTab')}
          </Button>
          <Button
            className={classNames('px-5 py-2.5 text-sm', {
              'pointer-events-none bg-white': productView === PRODUCT_VIEW.LIST_VIEW,
              'text-gray-500 hover:bg-gray-300': productView === PRODUCT_VIEW.TILE_VIEW,
            })}
            onClick={() => setProductView(PRODUCT_VIEW.LIST_VIEW)}
          >
            {t('shopPage.productList.listViewTab')}
          </Button>
        </div>
      </div>
      <div className="relative">
        <input
          data-testid="product-search-input"
          className="hidden w-full rounded-md border border-gray-300 px-3 py-2.5 text-sm focus:border-primary focus:outline-none  sm:block"
          placeholder={t('shopPage.productList.searchPlaceholder1')}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onFocus={() => setInputFocus(true)}
        />
        <input
          data-testid="product-search-input"
          className="w-full rounded-md border border-gray-300 px-3 py-2.5 text-sm focus:border-primary focus:outline-none sm:hidden"
          placeholder={t('shopPage.productList.searchPlaceholder2')}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onFocus={() => setInputFocus(true)}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-500 " />
        </div>
      </div>
      <>
        {!searchProductLoading && productList.length === 0 && <ProductListEmptyState />}
        {searchProductLoading ? (
          <Spinner className="mt-5 h-14 md:w-16" />
        ) : (
          <>
            <Transition
              show={productView === PRODUCT_VIEW.TILE_VIEW}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <TileView productList={productList} lines={lines} />
            </Transition>
            <Transition
              show={productView === PRODUCT_VIEW.LIST_VIEW}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListView productList={productList} lines={lines} />
            </Transition>
          </>
        )}

        {fetchMoreProductLoading && hasMoreProduct && <Spinner className="mt-5 h-14 md:w-16" />}
        {!searchProductLoading && <div ref={ref}></div>}
      </>
    </>
  )
}

export default ProductList
