import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'

import { GetCurrentUserDocument } from '@/graphql/access/generated/access_graphql'

export default function useCurrentUser() {
  const { data, loading } = useQuery(GetCurrentUserDocument)
  if (data) {
    Sentry.setUser({
      ...data.currentUser,
      id: data.currentUser?.id.toString(),
    })
  }

  return {
    currentUser: data?.currentUser,
    loading,
  }
}
