import { graphql } from 'msw'

import { CreateRequisitionLineMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const CreateRequisitionLine = graphql.mutation<CreateRequisitionLineMutation>(
  'CreateRequisitionLine',
  (_, res, ctx) => {
    return res(
      ctx.data({
        createRequisitionLine: {
          requisition: {
            id: 5270886,
            totalValue: 11.99,
            lines: [{ id: 26770369, productId: 5814401, quantity: 1.0, __typename: 'RequisitionLine' }],
            __typename: 'Requisition',
          },
          __typename: 'CreateRequisitionLineMutationPayload',
        },
      })
    )
  }
)
