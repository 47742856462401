import { graphql } from 'msw'

import { GetCurrentPurchaserQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

export const GetCurrentPurchaser = graphql.query<GetCurrentPurchaserQuery>('GetCurrentPurchaser', (_, res, ctx) => {
  return res(
    ctx.data({
      currentPurchaser: {
        id: 1234,
        name: 'Test Account',
        isAPurchaser: true,
        ppnewAccess: true,
        __typename: 'Purchaser',
      },
    })
  )
})
