import { useTranslation } from 'react-i18next'

import MenuDetails from './MenuDetails'
import UserDetails from './UserDetails'

import Logo from '@/assets/logo.svg'
import { useCurrentUser } from '@/modules/access/hooks'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks'
import { ChevronDownIcon, MenuIcon, UserIcon } from '@/modules/shared/icons'

export default function Header() {
  const { t } = useTranslation()
  const { currentUser, loading } = useCurrentUser()
  const { currentPurchaser } = useCurrentPurchaser()
  const signinUrl = `${process.env.REACT_APP_NINJA_API_HOST}/access/users/sign_in?redirect_url=${window.location}`

  return (
    <header className="flex items-center justify-between border-b bg-white py-3 px-5">
      <a href="/">
        <img src={Logo} alt="Purchase Plus" className="h-11" />
      </a>
      <nav className="flex">
        {!currentUser && !loading && (
          <a
            className="rounded-md bg-primary py-3 px-6 text-sm text-white hover:opacity-95"
            data-testid="signin-link"
            href={signinUrl}
          >
            {t('access.signIn')}
          </a>
        )}
        {currentUser && currentPurchaser && (
          <>
            <UserDetails
              currentUser={currentUser}
              currentPurchaser={currentPurchaser}
              customStyles="hidden lg:block mr-2"
            >
              <UserIcon className="h-7 w-7" />
              <div className="ml-2 border-l border-gray-300 pl-2 pr-6 text-left">
                <p className="text-xxs font-semibold">{currentUser.fullName}</p>
                <p className="text-xs text-primary">{currentPurchaser.name}</p>
              </div>
              <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
            </UserDetails>
            <MenuDetails currentUser={currentUser} currentPurchaser={currentPurchaser}>
              <span data-testid="menu-label" className="text-sm">
                {t('access.menu')}
              </span>
              <MenuIcon className="h-7 w-7" />
            </MenuDetails>
          </>
        )}
      </nav>
    </header>
  )
}
