import { Popover } from '@headlessui/react'
import { CalendarIcon } from '@heroicons/react/24/solid'
import { parseDate } from '@internationalized/date'
import dayjs from 'dayjs'
import { MutableRefObject, createContext, useEffect, useRef, useState } from 'react'
import { useDatePicker } from 'react-aria'
import { useDatePickerState } from 'react-stately'

import Calendar from './Calendar'

type CloseCalendarFunc = (focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null> | undefined) => void
export const CalendarContext = createContext<CloseCalendarFunc>(() => {})

interface DatePickerProps {
  label: string
  onSelected?: (e: string) => void
  defaultDate?: string
}

function DatePicker(props: DatePickerProps) {
  const { onSelected, label, defaultDate } = props
  const [selectedDate, setSelectedDate] = useState<string>(dayjs().add(1, 'day').format('YYYY-MM-DD'))
  let state = useDatePickerState(props)
  let ref = useRef(null)
  let { fieldProps, calendarProps } = useDatePicker(props, state, ref)
  const formatDate = (year: number, month: number, day: number) =>
    dayjs(new Date(year, month - 1, day)).format('YYYY-MM-DD')

  useEffect(() => {
    if (fieldProps.value) {
      const { day, month, year } = fieldProps.value
      setSelectedDate(formatDate(year, month, day))
      onSelected && onSelected(formatDate(year, month, day))
    }
  }, [fieldProps])

  useEffect(() => {
    if (defaultDate) {
      setSelectedDate(defaultDate)
    }
  }, [defaultDate])

  return (
    <>
      <Popover className="relative">
        <Popover.Button className="w-full focus:outline-none">
          <div className="relative rounded-md shadow-sm">
            <input
              type="text"
              data-testid="date-picker-input"
              aria-label={label}
              className="w-full cursor-pointer rounded-md border border-gray-300 p-3 text-sm shadow-sm focus:outline-none focus:ring-primary"
              value={dayjs(selectedDate).format('DD MMM YYYY')}
              readOnly
            />
            <div className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3">
              <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          </div>
        </Popover.Button>
        <Popover.Panel className="absolute right-0 z-10 mt-3 w-auto rounded-md bg-white p-4 shadow-md">
          {({ close }) => (
            <CalendarContext.Provider value={close}>
              <Calendar {...calendarProps} value={parseDate(selectedDate)} />
            </CalendarContext.Provider>
          )}
        </Popover.Panel>
      </Popover>
    </>
  )
}

export default DatePicker
