import { memo } from 'react'

import ProductCardTileView from './product-card-tile-view'

import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'

interface TileViewProps {
  productList: RequisitionLine[]
  lines: RequisitionLine[]
}

function TileView(props: TileViewProps) {
  const { productList, lines } = props
  return (
    <div className="grid gap-2 md:grid-cols-2 lg:grid-cols-3">
      {productList.map((product) => (
        <ProductCardTileView
          key={product.productId}
          product={product}
          isAddedToCart={Boolean(lines.find((line) => line.productId === product.productId))}
        />
      ))}
    </div>
  )
}

export default memo(TileView)
