import { graphql } from 'msw'

import { GetPossibleRequisitionLinesQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

export const getPossibleRequisitionLines = graphql.query<GetPossibleRequisitionLinesQuery>(
  'GetPossibleRequisitionLines',
  (_, res, ctx) => {
    return res(
      ctx.data({
        currentPurchaser: {
          id: 1234,
          requisition: {
            possibleRequisitionLines: {
              edges: [
                {
                  node: {
                    id: null,
                    productId: 5814401,
                    quantity: null,
                    image: null,
                    productBrand: 'Alperstein',
                    productDescription: 'Apron Shield 1 each',
                    productItemSize: 1,
                    productItemMeasure: 'bunch',
                    productItemPackName: null,
                    productSellUnit: 'each of 1',
                    unitPrice: 11.99,
                    taxPercentage: 10,
                    supplier: {
                      id: 67087,
                      name: 'AAPC Limited',
                    },
                    __typename: 'RequisitionLine',
                  },
                },
              ],
              pageInfo: {
                endCursor: 'MQ',
                hasNextPage: false,
              },
            },
            id: 5270886,
            requisitionNumber: 'PR04777',
            reference: 'TEST001',
            totalValue: 0,
            lines: [],
            __typename: 'Requisition',
          },
          __typename: 'Purchaser',
        },
      })
    )
  }
)
