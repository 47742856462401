import { graphql } from 'msw'

import { ApproveRequisitionMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const ApproveRequisition = graphql.mutation<ApproveRequisitionMutation>(
  'ApproveRequisition',
  (req, res, ctx) => {
    return res(
      ctx.data({
        approveRequisition: {
          errors: [],
          requisition: {
            id: 5235941,
            purchaserOrganisationId: 23783,
            state: 'approved',
            __typename: 'Requisition',
          },
          __typename: 'ApproveRequisitionMutationPayload',
        },
      })
    )
  }
)
