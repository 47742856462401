import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { RequisitionFormInputs } from '@/modules/requisitions/types'
import { Checkbox } from '@/modules/shared/components'

interface Props {
  control: Control<RequisitionFormInputs, unknown>
  defaultValue?: boolean
}

export default function SendPurchaseOrder({ control, defaultValue = true }: Props) {
  const { t } = useTranslation()
  return (
    <section className="mt-8">
      <h2 className="text-sm font-semibold">{t('createRequisition.sendPO.title')}</h2>
      <p className="text-sm text-gray-500">{t('createRequisition.sendPO.explain')}</p>
      <div className="mt-4">
        <label className="flex items-center text-sm">
          <Controller
            control={control}
            name="sendToSupplier"
            render={({ field }) => (
              <Checkbox
                data-testid="sendpo-checkbox"
                onChange={(e) => field.onChange(e)}
                defaultSelected={defaultValue}
              />
            )}
          />
          <span className="ml-2">{t('createRequisition.sendPO.label')}</span>
        </label>
      </div>
    </section>
  )
}
