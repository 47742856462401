import classNames from 'classnames'
import { ComponentProps, forwardRef } from 'react'

interface ButtonProps extends ComponentProps<'button'> {
  loading?: boolean
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props: ButtonProps, ref) => {
  const { className, children, loading, disabled, ...restProps } = props
  return (
    <button
      ref={ref}
      {...restProps}
      disabled={disabled || loading}
      className={classNames(className, `transition `, {
        'opacity-50': disabled || loading,
        'cursor-progress': loading,
        'cursor-not-allowed': disabled,
        'hover:brightness-95': loading ? false : disabled ? false : true,
      })}
    >
      {children}
    </button>
  )
})

export default Button
