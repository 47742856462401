import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { CreateRequisitionLineDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

interface AddItemProps {
  productId: number
  supplierId: number
  unitPrice: number
}

function AddItem({ productId, supplierId, unitPrice }: AddItemProps) {
  const { t } = useTranslation()
  const { requisitionId } = useParams<{ requisitionId: string }>()
  const [createRequisitionLine, { loading }] = useMutation(CreateRequisitionLineDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const onCreateRequisitionLine = () => {
    createRequisitionLine({
      variables: {
        input: {
          productId,
          supplierId,
          unitPrice,
          requisitionId: Number(requisitionId),
          quantity: 1,
        },
      },
    })
  }

  return (
    <Button
      data-testid="add-product-btn"
      loading={loading}
      className="rounded-full bg-gray-200 px-11 py-2.5 text-sm"
      onClick={onCreateRequisitionLine}
    >
      {t('shopPage.productList.productCard.addItemButton')}
    </Button>
  )
}

export default AddItem
