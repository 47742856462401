import { Navigate } from 'react-router-dom'

import { useCurrentUser } from '@/modules/access/hooks'
import { Home } from '@/modules/shared/pages'

export default function Root() {
  const { currentUser } = useCurrentUser()

  if (currentUser) {
    return <Navigate to="/dashboard" replace />
  }

  return <Home />
}
