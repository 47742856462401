import { NetworkStatus, useQuery } from '@apollo/client'
import { Control, Controller, FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  GetSupplierRelationshipsDocument,
  SupplierRelationship,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { RequisitionFormInputs } from '@/modules/requisitions/types'
import { ComboboxServer } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { extractEdges } from '@/modules/shared/utils'

interface Props {
  control: Control<RequisitionFormInputs, unknown>
  error?: FieldError
}

export default function SelectSupplier({ control, error }: Props) {
  const { t } = useTranslation()
  const {
    data: suppliersData,
    networkStatus,
    refetch,
    fetchMore,
  } = useQuery(GetSupplierRelationshipsDocument, {
    variables: {
      filter: {},
      first: 25,
      after: null,
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
  })

  const onSearchSupplierRelationship = (text: string) => {
    refetch({
      filter: {
        q: [
          {
            property: 'supplierName_cont',
            value: text,
          },
        ],
      },
    })
  }

  return (
    <section className="mt-8">
      <h2 className="text-sm font-semibold">{t('createRequisition.supplier.title')}</h2>
      <p className="text-sm text-gray-500">{t('createRequisition.supplier.explain')}</p>
      <div className="mt-2">
        <Controller
          control={control}
          name="originId"
          rules={{ required: true }}
          render={({ field }) => (
            <ComboboxServer
              loading={networkStatus === NetworkStatus.loading}
              searchLoading={networkStatus === NetworkStatus.setVariables}
              placeholder={t('createRequisition.supplier.placeholder')}
              keyExtractor={(e) => String(e.id)}
              onDisplay={(e) => String(e.supplierName)}
              items={extractEdges<SupplierRelationship>(suppliersData?.currentPurchaser?.supplierRelationships)}
              onInputChange={onSearchSupplierRelationship}
              onSelected={(e) => field.onChange(e.id)}
              hasError={Boolean(error)}
              errorMessage={t('createRequisition.supplier.required')}
              onFetchMore={() =>
                fetchMore({
                  variables: {
                    after: suppliersData?.currentPurchaser?.supplierRelationships?.pageInfo.endCursor,
                  },
                })
              }
              fetchMoreLoading={networkStatus === NetworkStatus.fetchMore}
              hasMore={Boolean(suppliersData?.currentPurchaser?.supplierRelationships?.pageInfo.hasNextPage)}
            />
          )}
        />
      </div>
    </section>
  )
}
