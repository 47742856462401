import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import MoreOptionMenu from './MoreOptionMenu'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, Tooltip } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { CheckAltIcon, CloseIcon, Loading, OptionIcon } from '@/modules/shared/icons'

interface ApprovalTableItemProps {
  requisition: Requisition
  onApprove?: (prId: number) => void
  actionLoading?: boolean
}

function ApprovalTableItem(props: ApprovalTableItemProps) {
  const { requisition, onApprove, actionLoading } = props
  const {
    reference,
    creator,
    id,
    requisitionNumber,
    account,
    totalValue,
    sendToSupplier,
    expectedDeliveryDate,
    originName,
    department,
  } = requisition

  const { format } = useMoney()
  const { t } = useTranslation()

  return (
    <tr className="border-b bg-white dark:border-gray-700">
      <td className="w-[190px] p-4">
        <Link
          data-testid="view-requisition-link"
          to={generatePath('/requisitions/:id', {
            id: String(id),
          })}
          className="text-primary"
        >
          {requisitionNumber}
        </Link>
        <p className="break-all text-xs">{reference}</p>
      </td>
      <td className="w-[190px] p-4">
        <p className="break-words">{creator?.fullName}</p>
      </td>
      <td className="w-[240px] p-4">
        <p className="break-words">{originName || '--'}</p>
      </td>
      <td className="p-4">
        <span className="hidden break-words 2xl:block">
          {department || account ? (
            <>
              <p>{department?.name}</p>
              <p className="text-xs">{account?.accountName}</p>
            </>
          ) : (
            '--'
          )}
        </span>
      </td>
      <td className="w-44 py-4 text-center">
        <p>{expectedDeliveryDate ? dayjs(expectedDeliveryDate).format('DD MMM YYYY') : '--'}</p>
      </td>
      <td className=" w-24 py-4 text-center">
        <p>{`${sendToSupplier ? t('requisition.details.sendPOYes') : t('requisition.details.sendPONo')}`}</p>
      </td>
      <td className="w-32 p-4 text-right">
        <p className="text-base font-bold">{format(totalValue || 0)}</p>
        <p className="text-xs text-gray-600">{t('requisition.details.taxInclusive')}</p>
      </td>
      <td className="w-28 p-4 ">
        <div className="flex items-center justify-center gap-x-2">
          <Tooltip content={t('requisition.details.tooltipActionDecline')} delay={100} showArrow>
            <Link
              data-testid="decline-button"
              to={generatePath('/requisitions/:id/decline', {
                id: String(id),
              })}
              className="flex h-8 w-8 items-center justify-center rounded-full bg-error text-white transition hover:brightness-90"
            >
              <CloseIcon className="h-5 w-5" />
            </Link>
          </Tooltip>
          <Tooltip content={t('requisition.details.tooltipActionApprove')} delay={100} showArrow>
            <Button
              data-testid="approve-button"
              disabled={actionLoading}
              className="flex h-8 w-8 items-center justify-center rounded-full bg-success text-white"
              onClick={() => onApprove && onApprove(id)}
            >
              {actionLoading ? (
                <Loading className="h-4 w-4 fill-white text-gray-300" />
              ) : (
                <CheckAltIcon className="h-4 w-4" />
              )}
            </Button>
          </Tooltip>
        </div>
      </td>
      <td className="w-16 py-4 text-center">
        <MoreOptionMenu requisitionId={String(id)}>
          <OptionIcon className="h-10 w-10 text-gray-500" />
        </MoreOptionMenu>
      </td>
    </tr>
  )
}

export default ApprovalTableItem
