import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApprovalTableItem from './approval-table-item'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useApproveRequisition } from '@/modules/requisitions/hooks'

interface TableViewProps {
  approvalList: Requisition[]
}

function TableView(props: TableViewProps) {
  const { approvalList } = props
  const { t } = useTranslation()
  const [actionLoadingById, setActionloadingById] = useState<number | null>(null)

  const [approveRequisition, { loading: actionLoading }] = useApproveRequisition()

  const onApprove = (requisitionId: number) => {
    setActionloadingById(requisitionId)
    approveRequisition({
      variables: {
        input: {
          id: requisitionId,
        },
      },
    })
  }

  return (
    <div className="hidden lg:block">
      <table className="w-full text-left text-sm" data-testid="requisition-table-view">
        {approvalList.length > 0 && (
          <thead className="text-xs text-gray-500">
            <tr>
              <th scope="col" className="px-4 py-2">
                {t('requisition.details.requisitionTableHeader')}
              </th>
              <th scope="col" className="px-4 py-2">
                {t('requisition.details.requestedBy')}
              </th>
              <th scope="col" className="px-4 py-2">
                {t('requisition.details.requisitionType')}
              </th>
              <th scope="col" className="px-4 py-2">
                <span className="hidden 2xl:block">{t('requisition.details.accountTableHeader')}</span>
              </th>
              <th scope="col" className="px-4 py-2 text-center">
                {t('requisition.details.deliveryDate')}
              </th>
              <th scope="col" className="px-4 py-2 text-center">
                {t('requisition.details.sendPOTableHeader')}
              </th>
              <th scope="col" className="px-4 py-2 text-right">
                {t('requisition.details.totalValue')}
              </th>
              <th scope="col" className="px-4 py-2 text-center">
                {t('requisition.details.actionsTableHeader')}
              </th>
              <th scope="col" className="px-4 py-2 text-center">
                {t('requisition.details.moreTableHeader')}
              </th>
            </tr>
          </thead>
        )}
        <tbody className="border">
          {approvalList.map((item) => (
            <ApprovalTableItem
              key={item.id}
              requisition={item}
              onApprove={onApprove}
              actionLoading={actionLoading && actionLoadingById === item.id}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default memo(TableView)
