import classNames from 'classnames'
import { useRef } from 'react'
import { AriaCheckboxProps, VisuallyHidden, useCheckbox } from 'react-aria'
import { useToggleState } from 'react-stately'

function Checkbox(props: AriaCheckboxProps) {
  const state = useToggleState(props)
  const ref = useRef(null)
  const { inputProps } = useCheckbox(props, state, ref)

  return (
    <label className="flex items-center gap-2 text-sm">
      <VisuallyHidden>
        <input {...inputProps} ref={ref} />
      </VisuallyHidden>

      <svg
        viewBox="0 0 24 24"
        fill="none"
        className={classNames('h-8 w-8 cursor-pointer transition duration-100 ease-in-out', {
          'fill-success': state.isSelected,
          'fill-white': !state.isSelected,
        })}
      >
        <circle cx="12" cy="12" r="11"></circle>
        <path d="M7 13l3 3 7-7" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
      </svg>

      {props.children}
    </label>
  )
}

export default Checkbox
