import { RadioGroup } from '@headlessui/react'
import { DocumentTextIcon, ShoppingCartIcon, TruckIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { RequisitionType } from '@/modules/requisitions/types'

interface Props {
  setSelectedType: Dispatch<SetStateAction<RequisitionType>>
}

const types: RequisitionType[] = [
  {
    id: 1,
    name: 'createRequisition.types.all',
    desc: 'createRequisition.types.allExplain',
    icon: <ShoppingCartIcon className="h-10 w-10" />,
    isReleased: false,
    originType: '',
  },
  {
    id: 2,
    name: 'createRequisition.types.single',
    desc: 'createRequisition.types.singleExplain',
    icon: <TruckIcon className="h-10 w-10" />,
    isReleased: true,
    originType: 'Purchasing::SupplierRelationship',
  },
  {
    id: 3,
    name: 'createRequisition.types.buyList',
    desc: 'createRequisition.types.buyListExplain',
    icon: <DocumentTextIcon className="h-10 w-10" />,
    isReleased: true,
    originType: '',
  },
]

export default function RequisitionTypes({ setSelectedType }: Props) {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(null)

  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <RadioGroup.Label className="sr-only">{t('createRequisition.title')}</RadioGroup.Label>
      <div className="space-y-3">
        {types.map(
          (type) =>
            type !== null && (
              <RadioGroup.Option
                data-testid={`requisition-type-${type.id}`}
                key={type.name}
                value={type}
                disabled={!type.isReleased}
                onClick={() => setSelectedType(type)}
                className={({ checked, active }) =>
                  classNames(
                    'relative flex cursor-pointer justify-between rounded-md border bg-white px-6 py-4 shadow-sm focus:outline-none',
                    {
                      'border-transparent': checked,
                      'border-gray-300': !checked,
                      'border-success': checked,
                      'cursor-not-allowed opacity-50': !type.isReleased,
                    }
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    <span className="flex items-center">
                      <span className="hidden md:block">{type.icon}</span>
                      <span className="ml-0 flex flex-col text-sm md:ml-4">
                        <RadioGroup.Label as="span" className="text-base font-semibold text-gray-900">
                          {t(type.name)}
                        </RadioGroup.Label>
                        <RadioGroup.Description as="span" className="hidden text-gray-500 md:block">
                          <span className="block sm:inline">{t(type.desc)}</span>
                        </RadioGroup.Description>
                      </span>
                    </span>
                    <RadioGroup.Description
                      as="span"
                      className="flex items-center text-sm sm:mt-0 sm:ml-4 sm:text-right"
                    >
                      {!type.isReleased && (
                        <div className="mr-2">
                          <span className="rounded-md bg-gray-100 px-3 py-2 text-xs text-gray-500 md:text-sm">
                            {t('createRequisition.comingSoon')}
                          </span>
                        </div>
                      )}

                      <div className="shrink-0">
                        {checked && (
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="15" cy="15" r="14.9" fill="white" stroke="#ACACAC" stroke-width="0.2" />
                            <circle cx="15" cy="15" r="8" fill="#4CB71A" />
                          </svg>
                        )}
                      </div>
                    </RadioGroup.Description>
                    <span
                      className={classNames('pointer-events-none absolute -inset-px rounded-md', {
                        border: active,
                        'border-1': !active,
                        'border-success': checked,
                        'border-transparent': !checked,
                      })}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            )
        )}
      </div>
    </RadioGroup>
  )
}
