import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import AwaitingMyApproval from './AwaitingMyApproval'
import CreateRequisition from './CreateRequisition'
import DraftRequisitions from './draft-requisitions'

import { Header } from '@/modules/requisitions/layouts'
import { Breadcrumb } from '@/modules/shared/components'
import { HomeIcon } from '@/modules/shared/icons'

export default function Dashboard() {
  const { t } = useTranslation()
  return (
    <>
      <Helmet>
        <title>{t('dashboard.pageTitle')}</title>
      </Helmet>
      <Header />
      <div className="mx-auto max-w-[1300px] p-5" data-testid="dashboard">
        <Breadcrumb className="rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/"></Breadcrumb.Item>
          <Breadcrumb.Item href="#">{t('dashboard.dashboard')}</Breadcrumb.Item>
        </Breadcrumb>
        <CreateRequisition />
        <AwaitingMyApproval />
        <DraftRequisitions />
      </div>
    </>
  )
}
