import classNames from 'classnames'
import { ComponentProps, FC, PropsWithChildren } from 'react'

import { ChevronRightIcon } from '@/modules/shared/icons'

interface BreadcrumbItemProps extends PropsWithChildren<Omit<ComponentProps<'li'>, 'className'>> {
  href?: string
  icon?: FC<ComponentProps<'svg'>>
  separator?: FC<ComponentProps<'svg'>>
}

function BreadcrumbItem(props: BreadcrumbItemProps) {
  const { href, children, icon: Icon, separator: Separator, ...restProps } = props
  const isLink = Boolean(href)

  //TODO: Will use Link component instead of a tag for performance
  const Component = isLink ? 'a' : 'span'
  const SeparatorComponent = Separator || ChevronRightIcon

  return (
    <li className="group flex items-center" {...restProps}>
      <SeparatorComponent className="mx-1 h-5 w-5 text-gray-400 group-first:hidden md:mx-2" aria-hidden="true" />
      <Component
        className={classNames({
          'flex items-center text-sm font-medium text-gray-500 dark:text-gray-400': isLink,
          'flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white':
            !isLink,
        })}
        href={href}
      >
        {Icon && <Icon className="mr-2 h-7 w-7 text-gray-400 group-first:mr-0" aria-hidden="true" />}
        {children}
      </Component>
    </li>
  )
}

export default BreadcrumbItem
