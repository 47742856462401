import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import DraftPROptionDetails from './DraftPROptionDetails'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Themes } from '@/modules/requisitions/types'
import { Button, ConfirmDialog } from '@/modules/shared/components'
import { CartIcon, ChevronDownIcon, ChevronRightIcon } from '@/modules/shared/icons'

interface RowProps {
  item: Requisition
  hide?: boolean
}

function Row(props: RowProps) {
  const { t } = useTranslation()
  const { item, hide } = props
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div className="flex w-3/4 lg:w-1/2">
        <div className="flex h-12 w-12 items-center justify-center rounded-md bg-primary/10 sm:h-14 sm:w-12">
          <CartIcon className="h-9 w-9 text-primary" />
        </div>
        <div className="ml-4 flex flex-col justify-center">
          <p className="h-5 text-sm font-semibold sm:text-base">${item?.totalValue}</p>
          <p className="text-xs text-gray-500 sm:text-sm">{`${item?.requisitionNumber} ${item?.reference || ''}`}</p>
        </div>
      </div>
      <div className="hidden w-1/2 items-center justify-end text-sm lg:flex">
        <DraftPROptionDetails requisitionId={item?.id}>
          {t('dashboard.draftRequisitions.actions.options')}
          <ChevronDownIcon className="inline w-6" />
        </DraftPROptionDetails>
        {!hide && (
          // TODO: Uncomment when drafts can be resumed
          // <Link
          //   to={generatePath(`/requisitions/:id/shop`, {
          //     id: item?.id.toString(),
          //   })}
          // >
          <>
            <Button
              data-testid="dashboard-my-drafts-resume"
              type="submit"
              onClick={() => setIsOpen(true)}
              className="ml-2.5 mt-5 h-11 w-full rounded-md bg-primary px-5 py-3 text-sm text-white sm:mt-0 sm:w-fit"
            >
              {t('dashboard.draftRequisitions.actions.resume')}
            </Button>
            <ConfirmDialog
              title={t('dashboard.createRequisition.popup.title')}
              description={t('dashboard.createRequisition.popup.body')}
              mainButtonLabel={t('dashboard.createRequisition.popup.confirm')}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              onConfirm={() => setIsOpen(false)}
              theme={Themes.success}
              testId="create-requisition-coming-soon"
            />
          </>
          // </Link>
        )}
      </div>
      <ChevronRightIcon className="ml-auto inline-block h-6 text-gray-400 lg:hidden" />
    </>
  )
}

export default Row
