function Summary() {
  return (
    <>
      {/* TODO: Summary Page */}
      <pre>summary page</pre>
    </>
  )
}

export default Summary
