import { Outlet } from 'react-router-dom'

import { useCurrentUser } from '@/modules/access/hooks'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks'
import { Header } from '@/modules/requisitions/layouts'
import { AccessDenied } from '@/modules/shared/pages'
import { Home } from '@/modules/shared/pages'

function ProtectedRoute() {
  const { currentPurchaser, loading: currentPurchaserLoading } = useCurrentPurchaser()
  const { loading: currentUserLoading } = useCurrentUser()
  if (currentUserLoading || currentPurchaserLoading) {
    return <Header />
  }
  if (currentPurchaser) {
    if (!currentPurchaser.ppnewAccess) return <AccessDenied />
    return <Outlet />
  } else return <Home />
}

export default ProtectedRoute
