import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useMoney } from '@/modules/shared/hooks'
import { CartIcon, ChevronRightIcon } from '@/modules/shared/icons'

interface CartProps {
  totalValue: number | null | undefined
}

function Cart({ totalValue }: CartProps) {
  const { format } = useMoney()
  const { t } = useTranslation()

  return (
    <section className="flex flex-col justify-between gap-y-3 rounded-md border bg-white p-4 shadow-sm md:flex-row">
      <div className="flex">
        <CartIcon className="h-12 w-12" />
        <div className="ml-4 border-l pl-4">
          <p className="text-xl font-bold" data-testid="cart-value">
            {format(totalValue || 0)}
          </p>
          <p className="text-sm">{t('requisitionInformation.cart.totalWithTax')}</p>
        </div>
      </div>
      <div className="flex flex-col gap-y-2 md:flex-row md:gap-2">
        <Link
          to="summary"
          className="flex items-center justify-between gap-1 rounded-md bg-primary px-4 py-2.5 text-sm text-white hover:brightness-95"
        >
          <span>{t('shopPage.cart.viewCart')}</span>
          <ChevronRightIcon className="h-6 w-6" />
        </Link>
      </div>
    </section>
  )
}

export default Cart
